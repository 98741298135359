import { EventEmitter, Injectable, Output } from '@angular/core';
import { MESSAGE_CONFIG } from '../config/message.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { ProjectModel } from '../project/project.model';
import { KeyValueModel, SaUserPreference } from './models.helper';
import { ServiceHelper } from './service.helper';
import { ProjectStoreLkp } from '../project/project-hierarchy.model';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DomainHelperService {
  @Output() showFilter: EventEmitter<any> = new EventEmitter();
  public invalidGeography: Subject<boolean> = new Subject();
  public invalidGeographyData: Subject<boolean> = new Subject();
  public invalidStores: Subject<any> = new Subject();
  constructor(private serviceHelper: ServiceHelper,private httpClient: HttpClient) { }
  compareData: any;
  public iconChangeEvent: Subject<any> = new Subject();
  

  getRegions() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.region;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: KeyValueModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getOpsRegions(opsType : string) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.opsRgn + opsType;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: KeyValueModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
      
  getAllRegions() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.allregion;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: KeyValueModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getOpsAreaByOpsRegions(opsType : string,opsRegions: string[]) {
    return new Promise((resolve, reject) => {
      try {
        if (opsRegions && opsRegions.length > 0) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.OpsArea + opsType;
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, opsRegions).subscribe((data: KeyValueModel[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Ops Regions");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getMarketsByRegions(regions: string[], isName = false) {
    return new Promise((resolve, reject) => {
      try {
        if (regions && regions.length > 0) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.market;
          if (isName) {
            url = url + "?isName=true";
          }
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, regions).subscribe((data: KeyValueModel[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Regions");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getMinimarketsByMarkets(markets: string[], isName = false) {
    return new Promise((resolve, reject) => {
      try {
        if (markets && markets.length > 0) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.miniMarket;
          if (isName) {
            url = url + "?isName=true";
          }
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, markets).subscribe((data: KeyValueModel[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Markets");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
    getStoresByMiniMarkets(_postData: ProjectStoreLkp) {
        return new Promise((resolve, reject) => {
            try {
                if (_postData.geoType == "SD" && _postData.miniMktIds && _postData.miniMktIds.length == 0)
                    reject(MESSAGE_CONFIG.errorMessages.required + ": MiniMarkets");

                let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.dashboardStoreList;

                let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, _postData).subscribe((data: KeyValueModel[]) => {
                    resolve(data);
                    temp.unsubscribe();
                }, (error) => {
                    reject(error);
                    temp.unsubscribe();
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    getStores(_postData: ProjectStoreLkp) {
          try {
              let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.dashboardStoreList;
                return this.httpClient.post(url, _postData).pipe(take(1));
                }catch (err) {
          }
  }

  getProjects() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });
  }

getStoresByScenarioId(scenarioId: string) {
    return new Promise((resolve, reject) => {
      try {
        if (scenarioId) {
          let urls = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.allStoresByScenario + scenarioId;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(urls).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Projects");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getBaslineStoresByProjectId(projectId: string) {

    return new Promise((resolve, reject) => {
      try {
        if (projectId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.allBaslineStoresByProject + projectId;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Projects");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getBaslineStoreDataByProjectId(projectId: string, atlasId: string) {

    return new Promise((resolve, reject) => {
      try {
        if (projectId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.allBaslineStoresByProject + projectId + "/" + atlasId;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Projects");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getStoreBaselineDetailsByStoreId(storeId: number) {

    return new Promise((resolve, reject) => {
      try {
        if (storeId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store + storeId;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Store Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getStoreScenarioDetails(storeId: number, scenarioId: number) {

    return new Promise((resolve, reject) => {
      try {
        if (storeId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store + storeId + "/" + scenarioId;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Store Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  dataFilter() {

    this.showFilter && this.showFilter.emit(true);
  }

  getDataFilter() {
    return this.showFilter;
  }

  setCompareData(compareData) {
    this.compareData = compareData;
  }

  getCompareData() {
    return this.compareData;
  }

  getUserPreferencesOptions() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.preferenceAll;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: SaUserPreference[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getUsersRegionByProjectId(projectId: string) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.userShareService + projectId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: KeyValueModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getSharedScenarios() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenariosShared;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getSimilarGeoProjects(projectId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.projectsByGeo + projectId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getEnvironment() {
    let envUrl: any = window.location.href;
    /* let envUrl: any = 'https://geo-analytics-cert.starbucks.com/AtlasNext/#'; */
    let urlSplit: any = envUrl.split('.')[0];
    let domainSplit: any = urlSplit.split('-');
    return domainSplit.at(-1);
  }

  isEnvironmentBanner(env: any) {
    if ( env === 'cert' || env === 'test' || env === 'dev' ) {
      return true;
    } else {
      return false;
    }
  }
}
