import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from './config/app.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from './config/service.config';
import { RouteStateService } from './helpers/route-state.service';
import { ServiceHelper } from './helpers/service.helper';
import { Logger } from './Logger/logger.module';
import { MESSAGE_CONFIG } from './config/message.config';
import { UserService } from './helpers/user.service';
import { timer } from 'rxjs';
import { SessionErrorServiceService } from './interceptor/session-error-service.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy, OnInit {
  @ViewChild('header', { read: ElementRef }) header: ElementRef;
  //@ViewChild('footer', { read: ElementRef }) footer: ElementRef;
  @ViewChild('pnlLeft', { read: ElementRef }) pnlLeft: ElementRef;

  title = 'app';
  showLeftPanel = true;
  username = '';
  isAdmin = false;
  isUserAuthenticated = false;
  isSSODone = false;
  rightPanelHeight;
  errMsg = '';
  private paramsroute: any;
  private subGetserv: any;
  private sessionSubscribe: any;
  resp: any;
  outageStartPST: string;
  outageEndPST: string;

  ngOnInit() {
    try {
      this.onResize(null);
    } catch (error) {
      this.logger.error("Error @ AppComponent: ngOnInit() ->"+ error);
    }   
  }

  ngOnDestroy() {
    try {
      if (this.paramsroute) {
        this.paramsroute.unsubscribe();
        this.paramsroute = null;
      }
      if (this.subGetserv) {
        this.subGetserv.unsubscribe();
        this.subGetserv = null;
      }
      this.onResize = null;
    } catch (error) {
      this.logger.error("Error @ AppComponent: ngOnDestroy() ->"+ error);
    }   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    try {
      if (window.innerWidth <= 1024) {
        this.showLeftPanel = false;
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ AppComponent: onResize() ->"+ err);
    }
  }


  constructor(private routingState: RouteStateService, private activatedRoute: ActivatedRoute, private serviceHelper: ServiceHelper, private userService: UserService, private cd: ChangeDetectorRef, private logger: Logger, private sessionError: SessionErrorServiceService) {
    try {
      this.routingState.loadRouting();
      let queryParamsTimeout = null;

      try {
        if (!APP_CONFIG.token) {
          if (queryParamsTimeout) {
            clearTimeout(queryParamsTimeout);
            queryParamsTimeout = null;
          }
         
            

           // APP_CONFIG.token = this.getParamValueQueryString('token');
            this.serviceHelper.GetServiceJsonDataObs(SERVICE_CONFIG.securityUrl + ENDPOINTS_CONFIG.idValid).subscribe(res => {
           //    if (APP_CONFIG.token) {
                if (res.body.hasOwnProperty('valid') && res.body['valid']) {
                     APP_CONFIG.token = res.headers.get('sat');
                     APP_CONFIG.googleKey = res.body['googleKey'];
                     this.loadScript('https://maps.googleapis.com/maps/api/js?key=' + APP_CONFIG.googleKey).then(() => {});
                    //  this.serviceHelper.GetServiceJsonDataWithPortalToken("https://geo-analytics-dev.starbucks.com/usermanagement/outage?applicationName=ASB").subscribe((resp:any)=>{
                      this.serviceHelper.GetServiceJsonDataWithPortalToken(ENDPOINTS_CONFIG.genericServiceUrl+ "usermanagement/outage?applicationName=ASB").subscribe((resp:any)=>{
                      console.log("resp",resp)
                      this.resp =resp;
                      let temp = new Date(this.resp.startDateUTC)
                      this.outageStartPST = temp.toLocaleString('en-US', { timeZone: 'PST' })
                      let temp2 = new Date(this.resp.endDateUTC)
                      this.outageEndPST = temp2.toLocaleString('en-US', { timeZone: 'PST' }) 
                      if(resp.outagePermission >= resp.outageLevel){
              this.subGetserv = this.serviceHelper.GetServiceJsonDataWithPortalToken(SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.userinfo).subscribe((data: any) => {
                try {
                  if (data) {
                    this.isUserAuthenticated = (data.isSatUser && data.hasAccess);
                    this.isSSODone = true;
                    if (this.isUserAuthenticated) {
                      APP_CONFIG.username = data.name;
                      APP_CONFIG.hideScenarioPopUp=data.isHidePopUp;
                      APP_CONFIG.tokenExpiryDate = new Date(data.exp);//new Date(new Date().getTime() + 1 * 60 * 1000);//
                      //verify
                     this.sessionSubscribe = timer(30 * 60 * 1000, 5 * 60 * 1000).subscribe(val => {
                        this.displaySessionWarning();
                      })
                      this.username = data.name;
                      this.isAdmin = data.isAdmin; this.serviceHelper.setUserAdmin(data.isAdmin);
                      this.initLookups();
                      this.getProjectsValidate();
                    } else {
                      if (!data.isSatUser) {
                        this.errMsg = MESSAGE_CONFIG.errConfig.notSatUser;
                      } else if (!data.hasAccess) {
                        this.errMsg = MESSAGE_CONFIG.errConfig.noRegionAccess;
                      }
                    }
                    this.cd.detectChanges();
                  }
                } catch (err) {
                  console.log(err);
                }
                this.cd.detectChanges();
              },
                (err) => {
                  this.isUserAuthenticated = false;
                  this.isSSODone = true;
                  this.cd.detectChanges();
                });
              }
              this.cd.detectChanges();
            });
            } else {
              const scope = this;
              queryParamsTimeout = setTimeout(() => {
                scope.isUserAuthenticated = false;
                window.location.assign(SERVICE_CONFIG.securityUrl + ENDPOINTS_CONFIG.security + "?appUrl=" + SERVICE_CONFIG.appUrl);
                clearTimeout(queryParamsTimeout);
              }, 1000);
            }
          }, (_err) => {
             console.log(_err);
           });
        }

      } catch (err) {
        console.log(err);
        this.cd.detectChanges();
      }
    } catch (err) {
      console.log(err);
      this.cd.detectChanges();
    }

  }

  private displaySessionWarning() {
    try {
      const bufferTime = 150 * 60 * 1000;//verify
    if (APP_CONFIG.tokenExpiryDate && (APP_CONFIG.tokenExpiryDate.getTime() - new Date().getTime()) <= bufferTime) {
        const data = {
          isSessionTimeOut: false,
          messages: MESSAGE_CONFIG.messages.sessionWarning,
          headers: MESSAGE_CONFIG.headers.sessionExpWarning,
          bufferTime: 5 * 60 * 1000
        };
        this.sessionError.openDialog(data);
        this.sessionSubscribe.unsubscribe();
     }
    } catch (error) {
      this.logger.error("Error @ AppComponent: displaySessionWarning() ->"+ error);
    }   
  }

  getParamValueQueryString(paramName) {
    try {
      const url = window.location.href;
      let paramValue;
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
    } catch (error) {
      this.logger.error("Error @ AppComponent: getParamValueQueryString() ->"+ error);
    }   
  }

  getCookie(cname) {
    try {
      let name = cname + '=';
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } catch (error) {
      this.logger.error("Error @ AppComponent: getCookie() ->"+ error);
    }   
  }

  initLookups() {
    try {
      let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.initial;
      const temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any[]) => {
        try {
          APP_CONFIG.storeTypes = data.find((val) => {
            return val.type === 'sTypes';
          }).lkp;
          APP_CONFIG.scenarioDecisions.open = data.find((val) => {
            return val.type === 'openStoreDescn';
          }).lkp;
          APP_CONFIG.scenarioDecisions.pipeline = data.find((val) => {
            return val.type === 'pipelineStoreDescn';
          }).lkp;
          APP_CONFIG.scenarioDecisions.projectType = data.find((val) => {
            return val.type === 'renos';
          }).lkp;
          APP_CONFIG.OwnershipTypes = data.find((val) => {
            return val.type === 'ownTypes';
          }).lkp;
          temp.unsubscribe();
        } catch (err) {
          this.logger.error(err);
        }
      }, (err) => {
        this.logger.error(err);
        temp.unsubscribe();
      });

      url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.fyList;
      const temp1 = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any[]) => {
        try {
          APP_CONFIG.fyList = data || [];
          temp1.unsubscribe();
        } catch (err) {
          this.logger.error(err);
        }
      }, (err) => {
        this.logger.error(err);
        temp1.unsubscribe();
      });

        url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.wk53FyList;
        const temp2 = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any[]) => {
            try {
                APP_CONFIG.wk53FyList = data || [];
                temp2.unsubscribe();
            } catch (err) {
                this.logger.error(err);
            }
        }, (err) => {
            this.logger.error(err);
            temp2.unsubscribe();
        });
    } catch (err) {
      this.logger.error("Error @ AppComponent: initLookups() ->"+ err);
    }
  }
  getProjectsValidate() {
    try {
      const url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getProjectsValidate;
      const temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any[]) => {
        temp.unsubscribe();
      }, (err) => {
        this.logger.error(err);
        temp.unsubscribe();
      });
    } catch (err) {
      this.logger.error("Error @ AppComponent: getProjectsValidate() ->"+ err);
    }
  }
  acceptError() {
    try {
      this.userService.logoutUser();
    } catch (err) {
      this.logger.error("Error @ AppComponent: acceptError() ->"+ err);
    }
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = name;
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log('Script Loaded');
    });
  }
}
