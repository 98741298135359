export class DialogParams {
  headerText: string = "Header";
  messageText: string = "Message";
  isOkButton: boolean = true;
  okButtonText: string = "OK";
  isCancelButton: boolean = true;
  cancelButtonText: string = "Cancel";
  error: boolean = false;
  initiatorId: string = "tempId";
  operationId: string = "test";
  share: boolean = false;
  region: string = null;
  shareToUsers: any[] = [];
  isMultiple: boolean = false;
  scenarioClone: boolean = false;
  projects: any[] = [];
  panelClass: string = "";
  scenarioName = "";
  isInvalidProject = false;
  isDummyPipeline = false;
  processErrorStores = false;
  invalidStores = [];
  errorStores = [];
  store = null;
  export:boolean = false;
  scenarios:any[];
  dashboardFilterNoResult = false;
  type:string;
}

export class DialogResultParams {
  initiator: string = "tempId";
  operation: string = "test";
  action: string = "";
  results: any[] = [];
  resultProj: string = "";
  resultScnName: string = "";
  resultScn:any;
}
