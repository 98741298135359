import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Logger } from '../../Logger/logger.module';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  projectDialog: boolean = false;

  constructor(private logger: Logger, private cd: ChangeDetectorRef) { }

  @Output() selectedColor = new EventEmitter();

  @Input() colorArray: any[];
  @Input() selectedPriority;
  @Input() defaultPriority = null;
  @Input() isMenu = true;
  @Input() label = '';
  @Input() sortBy = '';
  @Input() type= '';

  ngOnInit() {
    try {
      if(this.type === 'projectAdd'){
        this.projectDialog = true;
      }
      if (this.selectedPriority == null || this.selectedPriority === undefined) {
        this.selectedPriority = this.defaultPriority.priority;
        this.selectedColor.emit(this.defaultPriority);
      }

      if (this.sortBy) {
        this.colorArray.sort((a, b) => {
          if (a[this.sortBy] < b[this.sortBy]) {
            return -1
          } else if (a[this.sortBy] === b[this.sortBy]) {
            return 0;
          } else {
            return 1;
          }
        });
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ ColorPickerComponent: ngOnInit() ->"+ err); 
    }
  }

  ngOnDestroy() {
    try {
      this.selectedColor && this.selectedColor.unsubscribe();
      this.emitSelectedColor = null;
    } catch (error) {
      this.logger.error("Error @ ColorPickerComponent: ngOnDestroy() ->"+ error);
    }   
  }

  emitSelectedColor(color) {
    try {
      if (this.selectedPriority !== color.priority) {
        this.selectedPriority = color.priority;
        this.selectedColor.emit(color);
        this.cd.detectChanges();
      }
    } catch (err) {
      this.logger.error("Error @ ColorPickerComponent: emitSelectedColor() ->"+ err);
    }
  }


}