import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../config/app.config';
import { MESSAGE_CONFIG } from '../config/message.config';
import { DomainHelperService } from '../helpers/domain-helper.service';
import { KeyValueModel } from '../helpers/models.helper';
import { Logger } from '../Logger/logger.module';
import { ScenarioService } from '../scenario/scenario.service';
import { DialogParams } from './sat-dialog.model';

@Component({
  selector: 'sat-dialog-content',
  templateUrl: './sat-dialog-content.component.html',
  styleUrls: ['./sat-dialog-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SatDialogContent implements OnInit, OnDestroy {
  params: DialogParams;
  color = APP_CONFIG.color;
  shareControl = new UntypedFormControl();
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = false;
  scenarioShareUsers: Observable<any[]>;
  oriScenarioShareUsers: KeyValueModel[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedUser: any = [];
  selectedProj = "";
  scnName = "";
  italicText ="";
  @ViewChild('matInput') matInput;
  @ViewChild('matInput', { read: MatAutocompleteTrigger }) autoTrgrInput: MatAutocompleteTrigger;
  scnErrMsg = "";
  scenarios: any = [];
  selectedObj = null;
  scenariosSec: any = [];
  selectedObjSec = null;
  sharedTooltip:boolean = false;
  sharedWith = APP_CONFIG.tooltipShared.sharedTo;
  popup = APP_CONFIG.geography_change_popup;

  constructor(public dialogRef: MatDialogRef<SatDialogContent>, @Inject(MAT_DIALOG_DATA) public data: DialogParams, private domainHelperService: DomainHelperService, private logger: Logger, private scenarioService: ScenarioService, private cd: ChangeDetectorRef) {
    try {
      this.params = data;
      if (this.params.share) {
        this.selectedUser = this.params.isMultiple ? [] : this.params.shareToUsers;
      }
      if(this.params.type==="sharedTooltipExpand"){
        this.sharedTooltip = true;
      }
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: constructor() ->"+ err);
    }
  }
  ngOnInit() {
    try {
      if (this.params.share) {
        this.domainHelperService.getUsersRegionByProjectId(this.params.projects[0].Id).then((data: KeyValueModel[]) => {
          try {
            this.oriScenarioShareUsers = data.filter((val) => {
              return val.value != APP_CONFIG.username;
            });
            if (this.params.isMultiple) {
              let alreadyShared = this.params.shareToUsers.map((val) => {
                return val.id;
              });
              this.oriScenarioShareUsers = this.oriScenarioShareUsers.filter((val) => {
                return alreadyShared.indexOf(val.id) < 0;
              });
            }

            this.scenarioShareUsers = this.shareControl.valueChanges.pipe(
              map((user: string | null) => { return user ? this._filteredUsers(user) : this.oriScenarioShareUsers.slice() })
            );
            this.cd.detectChanges();
          } catch (err) {
            this.logger.error("Error @ SatDialogContent: domainHelperService.getUsersRegionByProjectId() ->"+ err);
          }
        });

      }
      else if (this.params.scenarioClone) {
        this.scnName = this.params.scenarioName;
      }
      else if (this.params.export) {
        this.scenarios = this.params.scenarios.filter((val) => {
          return val.scenario.Name != "Baseline";
        }).map(val => val.scenario);
        this.selectedObj = this.scenarios[0];


        this.scenariosSec = this.getSecScenarios(this.scenarios.filter((val) => {
          return val != this.selectedObj;
        }));
        this.selectedObjSec = this.scenariosSec[0];
      }
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: ngOnInit() ->"+ err);
    }
  }
  onScenariosPriChange($event) {
    try {
      this.selectedObj = $event.value;
      this.scenariosSec = this.getSecScenarios(this.scenarios.filter((val) => {
        return val != this.selectedObj;
      }));
      if (this.selectedObj == this.selectedObjSec) {
        this.selectedObjSec = this.scenariosSec[0];
      }
      else {
        this.selectedObjSec = this.scenariosSec.find((val) => {
          return val.Id == this.selectedObjSec.Id;
        });
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: onScenariosPriChange() ->"+ err);
    }
  }
  private getSecScenarios(scenarios = []) {
    try {
      let baseline = {
        Name: "Baseline",
        Id: "0"
      };
      return [baseline].concat(scenarios);
    } catch (error) {
      this.logger.error("Error @ SatDialogContent: getSecScenarios() ->"+ error);
    }    
  }
  onInputFocus() {

    let _clearTimeout = setTimeout(() => {
      try {
        this.autoTrgrInput.openPanel();
        this.shareControl.setValue(this.matInput.nativeElement.value);
        this.matInput.nativeElement.focus();
        clearTimeout(_clearTimeout);
      } catch (err) {
        this.logger.error("Error @ SatDialogContent: onInputFocus() ->"+ err);
      }
    });
  }
  ngOnDestroy() {
    try {
      this.scenarioShareUsers = null;
      this.oriScenarioShareUsers = null;
      this.close = null;
      this.onInputFocus = null;
      this.remove = null;
      this.selected = null;
      this.isSelectedUser = null;
      this.projectsChange = null;
      this.onScenariosPriChange = null; 
    } catch (error) {
      this.logger.error("Error @ SatDialogContent: ngOnDestroy() ->"+ error);
    }    
  }
  private _filteredUsers(user) {
    try {
      let filteredData = this.oriScenarioShareUsers;
      if (user) {
        let tempArr = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].value.toString().toLowerCase().includes(user.toString().toLowerCase())) {
            tempArr.push(filteredData[i]);
          }
        }
        return tempArr;
      } else {
        return filteredData;
      }
    }
    catch (err) {
      this.logger.error("Error @ SatDialogContent: _filteredUsers() ->"+ err);
    }

  }

  close(reason) {
    try {
      if (reason == "Ok" && (this.params.scenarioClone && !this.selectedProj)) {
        return;
      }
      else if (reason == "Ok" && (this.params.scenarioClone && this.scnErrMsg)) {
        this.scenarioService.isUniqueName(this.scnName, this.selectedProj).then((isUnique) => {
          try {
            if (isUnique) {
              this.dialogRef.close(reason);
            }
            this.cd.detectChanges();
          } catch (err) {
            this.logger.error("Error @ SatDialogContent: scenarioService.isUniqueName() ->"+ err);
          }
        });
      }
      else {
        this.dialogRef.close(reason);
      }
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: close() ->"+ err);
    }
  }
  closeSharedWithDialog(){
    try {
      this.dialogRef.close();
    }
    catch (err) {
      this.logger.error("Error @ SatDialogContent: closeSharedWithDialog() ->"+ err);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    try {
      let index = this.selectedUser.find((val) => {
        return val.id == event.option.value.id;
      });
      if (!index) {
        this.selectedUser.push(event.option.value);
      }
      else {
        this.selectedUser.splice(this.selectedUser.indexOf(index), 1);
      }
      this.shareControl.reset("");

      let _clearTimeout = setTimeout(() => {
        try {
          this.autoTrgrInput.openPanel();

          this.shareControl.setValue(this.matInput.nativeElement.value);
          this.matInput.nativeElement.focus();
          clearTimeout(_clearTimeout);
        } catch (err) {
          this.logger.error("Error @ SatDialogContent: _clearTimeout() ->"+ err);
        }
      }, 0);
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: selected() ->"+ err);
    }
  }
  isSelectedUser(user) {
    try {
      return this.selectedUser && this.selectedUser.findIndex(x => x.id === user.id) >= 0;
    } catch (error) {
      this.logger.error("Error @ SatDialogContent: isSelectedUser() ->"+ error);
    }    
  }
  remove(users: KeyValueModel): void {
    try {
      const index = this.selectedUser.findIndex(x => x.id === users.id);

      if (index >= 0) {
        this.selectedUser.splice(index, 1);
      }
      this.shareControl.reset("");

      let _clearTimeout = setTimeout(() => {
        this.autoTrgrInput.openPanel();

        this.shareControl.setValue(this.matInput.nativeElement.value);
        this.matInput.nativeElement.focus();
        clearTimeout(_clearTimeout);
      }, 0);
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: remove() ->"+ err);
    }
  }
  projectsChange(event) {
    try {
      this.selectedProj = event.value;
      this.scenarioService.isUniqueName(this.scnName, this.selectedProj).then((isUnique) => {
        if (!isUnique) {
          this.scnErrMsg = MESSAGE_CONFIG.errConfig.duplicateScenarioInProj;
        }
        else {
          this.scnErrMsg = null;
        }
        this.cd.detectChanges();
      }, (err) => {
        this.logger.error("Error @ SatDialogContent: scenarioService.isUniqueName() ->"+ err);
    });
    } catch (err) {
      this.logger.error("Error @ SatDialogContent: remove() ->"+ err);
    }
  }
  getGeoChangeText(geoata){
    if(geoata.indexOf(this.popup.italicText.movedTo)>-1){
      this.italicText=this.popup.italicText.movedTo;
    }else if(geoata.indexOf(this.popup.italicText.movedfrom)>-1){
      this.italicText=this.popup.italicText.movedfrom;
    }else if(geoata.indexOf(this.popup.italicText.deleted)>-1){
      this.italicText=this.popup.italicText.deleted;
    }

    return geoata;
  }
}
  
