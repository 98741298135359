import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MESSAGE_CONFIG } from '../config/message.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { KeyValueModel } from '../helpers/models.helper';
import { ServiceHelper } from '../helpers/service.helper';
import { StoreBaselineDetail } from '../models/store-baseline.model';
import { StoreModel } from '../models/store.model';
import { ProjectModel } from '../project/project.model';
import { ScenarioModel, ScenarioShare } from './scenario.model';
import { Logger } from '../Logger/logger.module';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {

  projectId: string = null;
  project: ProjectModel;
  scenario: ScenarioModel;
  baselineScenario: ScenarioModel;
  map: google.maps.Map;
  private storeList: StoreModel[];
  invalidPrj:boolean;

  clusterStores = new Subject<boolean>();
  clusterStores$ = this.clusterStores.asObservable();

  scenariosUpdated = new Subject<any>();
  scenariosUpdated$ = this.scenariosUpdated.asObservable();

  private projectSource = new Subject<ProjectModel>();
  projectSource$ = this.projectSource.asObservable();

  shareDataSubject = new Subject<any>();
  shareDataSubject$ = this.shareDataSubject.asObservable();

  invalidProject = new Subject<any>();
  invalidProject$ = this.invalidProject.asObservable();


  public compareData: any;
  scenarioList: any;

  constructor(private serviceHelper: ServiceHelper, private logger: Logger) { }

  getAllScenarios() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ScenarioModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });

      } catch (err) {
        reject(err);
      }
    });
  }
  getScenarioById(id) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + id;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ScenarioModel) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getScenarioByProjectId(id) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenarioByProject + id;

          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ScenarioModel[]) => {
            resolve(data);
            temp.unsubscribe();

          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Project Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getSimilarProjScenarios(id) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.prjScnByGeo + id;

          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ScenarioModel[]) => {
            resolve(data);
            temp.unsubscribe();

          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Project Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  updateScenarioById(id, scenario: ScenarioModel) {
    return new Promise((resolve, reject) => {
      try {
        if (id && scenario) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + id;
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, scenario).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          let tmpMsg = "";
          if (!id) {
            tmpMsg = "Scenario Id";
          }
          if (!scenario) {
            if (tmpMsg.length > 0) {
              tmpMsg = tmpMsg + ", ";
            }
            tmpMsg = tmpMsg + "Scenario Data";
          }
          reject(MESSAGE_CONFIG.errorMessages.required + ": " + tmpMsg);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  updateScenarioBookmarkById(id, isBookmark) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let bookmarkData = {
            "isBookmark": isBookmark
          }
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + id;
          let temp = this.serviceHelper.PatchServiceJsonDataWithPortalToken(url, bookmarkData).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          let tmpMsg = "";
          tmpMsg = "Scenario Id"
          // if (!id) {
          //   tmpMsg = "Scenario Id";
          // }
          reject(MESSAGE_CONFIG.errorMessages.required + ": " + tmpMsg);
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  createScenario(scenario: ScenarioModel, cloneScenarioId: number) {
    return new Promise((resolve, reject) => {
      try {
        if (scenario) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + cloneScenarioId;
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, scenario).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Data");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  updateScenarioDetails(scenario: ScenarioModel) {
    return new Promise((resolve, reject) => {
      try {
        if (scenario) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + scenario.Id;
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, scenario).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Data");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  updateStoreScenarioDecisiosn(scenarioId, unquieStoreId, revisedDecsionId) {
    return new Promise((resolve, reject) => {
      try {
        if (scenarioId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store + scenarioId + "/" + unquieStoreId + "/" + revisedDecsionId;
          let data = {};
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, data).subscribe((_data) => {
            resolve(_data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteScenarioById(id) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scenario + id;
          let temp = this.serviceHelper.DeleteServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  updateStoreScenarioDetails(storeId: number, scenarioId: number, storestoreScenarioDetail: StoreBaselineDetail) {
    return new Promise((resolve, reject) => {
      try {
        if (storeId && scenarioId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store + storeId + "/" + scenarioId;
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, storestoreScenarioDetail).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  replaceEmptyWithNull(data){
    let string = JSON.stringify(data);
    let result = string.replace(/\s+(?: \s+)/g,'');
    result = result.replace(/""/g,'null')
    return JSON.parse(result);
  }

  updateStoreScenarioDetailsMultiple(putData: any[], scenarioId) {
    return new Promise((resolve, reject) => {
      try {
        if (putData && putData.length > 0) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store + scenarioId;
          putData = this.replaceEmptyWithNull(putData);
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, putData).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Scenario Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  updateAtlasStorePortfolioDecision(scenarioId, atlasSiteId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.atlasUpdate + scenarioId + '/' + atlasSiteId;
        let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, null).subscribe((data: any) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });
  }

  // Service message commands
  announceScenarioSelected(scenario: ScenarioModel) {
    this.scenario = scenario;
    let setProject = this.project.Id != scenario.pId;
    this.setSelectedProjId(scenario.pId, setProject);
  }

  getSelectedProjId() {
    return this.projectId;
  }
  setSelectedProjId(projId, setProject = true) {
    this.projectId = projId;
    if (setProject && this.project.Id != this.projectId) {
      let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project + projId;
      let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectModel) => {
        this.project = data;
        temp.unsubscribe();
      }, (error) => {
        this.logger.error(error);
        temp.unsubscribe();
      });
    }
  }
  getSelectedProject(): ProjectModel {
    return this.project;
  }

  setSelectedProject(_project: ProjectModel) {
    this.project = _project;
    this.projectId = _project.Id;
    this.projectSource.next(_project);
  }

  setScenarios(scenarios: any[]) {
    this.shareDataSubject.next(scenarios);
  }

  getSelectedScenario(): ScenarioModel {
    return this.scenario;
  }

  setInvalidProject(invalid:boolean) {
    this.invalidPrj = invalid? true:false;
    this.invalidProject.next(invalid);
  }
  getInvalidProject() {
    return  this.invalidPrj;
  }



  initiateStoreScenarioAnalysis(storeIds: number[], scenarioId: number) {
    try {
      if (storeIds != null && storeIds != undefined && scenarioId != null && scenarioId != undefined) {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.storeAnalyze + scenarioId;
        let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, storeIds).subscribe(() => { temp && temp.unsubscribe(); }, (err) => {
          this.logger.error(err);
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  setCompareData(compareData) {
    this.compareData = compareData;
  }

  getCompareData() {
    return this.compareData;
  }

  createNewStore(store) {
    return new Promise((resolve, reject) => {
      try {
        if (store) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.store;
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, store).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();

          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Store Data");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  shareScenarioByUser(scenarioShare: ScenarioShare) {

    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.shareScenario;
        let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, scenarioShare).subscribe((data: KeyValueModel[]) => {

          resolve(data);
          temp.unsubscribe();
        }, (error) => {

          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });

  }

  setMapReference(map: google.maps.Map) {
    this.map = map;
  }

  getMapRefernece(): google.maps.Map {
    return this.map;
  }

  setStoreList(storeList: StoreModel[]) {
    this.storeList = storeList;
  }

  getStoreList(): StoreModel[] {
    return this.storeList;
  }



  isUniqueName(name, proj) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.scnUnique + proj + "/" + name;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: boolean) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });
  }
  getStoreDetails(scenarioId, storeIds) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getStores + scenarioId;
        let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, storeIds).subscribe((data: any[]) => {
          resolve(data);
          temp.unsubscribe();

        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getStoresCannAccListByScenario(scenarioId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.cannAccStores + scenarioId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: KeyValueModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getStoreImageAttachement(atlasId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getStoreAttachmentUrl + atlasId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });

      } catch (err) {
        reject(err);
      }
    });
  }

  getStoreExtraDetails(atlasId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getStoreExtraInfo + atlasId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });

      } catch (err) {
        reject(err);
      }
    });
  }

  addPipelineStores(pipeline, scenarioId, categoryId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.pipelineStores + scenarioId + "/" + categoryId;
        let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, pipeline).subscribe((data: any[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });

      } catch (err) {
        reject(err);
      }
    });
  }
  exportStoreDifferences(scenarioId1, scenarioId2, baseline) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.exportDifferences + scenarioId1 + "/" + scenarioId2 + "?projId=" + baseline;

        let temp = this.serviceHelper.GetServiceFileDataWithPortalToken(url).subscribe((data) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();

        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getInvlidStoresSincePrjInception(projectId) {
    return new Promise((resolve, reject) => {
      let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getInvalidStores + projectId + ENDPOINTS_CONFIG.getInvalidStoresAllQryParam)
        .subscribe((stores: any[]) => {
          resolve(stores);
          temp.unsubscribe();
        }, err => {
          reject(err);
          temp.unsubscribe();
        });
    });
  }
  getScenarioList() {
    return this.scenarioList;
  }

  setScenarioList(list) {
    this.scenarioList = list;
  }
  
}