import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from './truncate.pipe';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip'
import { BreadcrumModule } from './breadcrum/breadcrum.module';
import { DecimalMask } from './decimal-Mask.directive';
import { CannfilterPipe } from './cannfilter.pipe';
import { TwoDigitDecimaNumberDirective } from './m-decimal.directive';
@NgModule({
  imports: [CommonModule, FormsModule, MatMenuModule, MatIconModule, MatButtonModule, MatTooltipModule, BreadcrumModule],
  declarations: [FilterPipe, TruncatePipe, ColorPickerComponent, DecimalMask, CannfilterPipe,TwoDigitDecimaNumberDirective],
  exports: [FilterPipe, TruncatePipe, ColorPickerComponent, BreadcrumModule, DecimalMask, CannfilterPipe,TwoDigitDecimaNumberDirective]

})
export class SatCommonModule { }
