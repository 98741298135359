<div class="session-window">
  <div class="image-div ">
    <img class="logo" src="assets/images/asb-new-logo.svg">
  </div>
  <div class="message-div">
    <div class="session-error-msg-title">Session Expiration Warning</div><br>
    <div class="session-error-msg">
      Your session is about to timeout in the next few minutes. Any unsaved changes will be lost.
      <br /> <br />
      If time is remaining below, click 'Continue Session' to save any last minute changes in Atlas Scenario Builder. Otherwise, click 'Logout', close your browser and reopen Atlas Scenario Builder.
      <p class="txtRemTime">Time Remaining <span class="txtTime">{{remainingTime}}</span></p>
    </div>
    <div class="btnDiv">
      <button type="button" class="btnSD btn-ok" (click)="close('Ok')"><span class="ico-ok" aria-hidden="true"></span> Continue Session </button>
      <button type="button" class="btnSD btn-cancel" (click)="logout()"><span class="ico-cancel" aria-hidden="true"></span> Logout </button>
    </div>
  </div>
</div>
<div class="overlay"></div>
