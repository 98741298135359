import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../config/app.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
@Injectable({
  providedIn: 'root'
})
export class TraceLoggerService {

  constructor(private http: HttpClient) { }

  trace(message?: any, ...optionalParams: any[]) {
    // this._level = Level.INFO;
    console.info.apply(console, arguments);
    if (APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
  }

  postLogToServer(logArgs) {
    try {
      logArgs[logArgs.length] = APP_CONFIG.username;
      let data = { level: 'TRACE', arguments: logArgs };
      //TODO: add service url for posting data to server
      let serviceUrl = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.logger;

      return this.http.post(serviceUrl, data).pipe(map((r: Response) => r ? r.json() : {})).subscribe();
    }
    catch (err) {
      console.log("Logger Service Error");
      console.log(err);
    }
  }
}
