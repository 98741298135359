import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../helpers/user.service';
import { Logger } from '../../Logger/logger.module';
import { TraceLoggerService } from '../../Logger/trace-logger.service';

@Component({
  selector: 'app-session-error-dialog',
  templateUrl: './session-error-dialog.component.html',
  styleUrls: ['./session-error-dialog.component.css']
})
export class SessionErrorDialogComponent implements OnDestroy {

  labelSessionDialogMessage: string;
  labelSessionDialogHeader: string;
  isSessionTimeOut: boolean = false;
  bufferTime: number = null;
  timeout: any = null;

  get isTimeoutWarning() {
    return this.bufferTime != null || this.bufferTime != undefined;
  }
  get remainingTime() {
    return (this.to2Digit(Math.floor(this.bufferTime / 60))) + ':' + (this.to2Digit(this.bufferTime % 60));
  }
  private to2Digit(val) {
    return ('00' + val).slice(-2);
  }

  constructor(public dialogRef: MatDialogRef<SessionErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private logger : Logger,private userService: UserService, private traceLogger: TraceLoggerService) {
   try {
    this.isSessionTimeOut = data.isSessionTimeOut == true ? true : false;
    this.labelSessionDialogMessage = data.messages;
    this.labelSessionDialogHeader = data.headers;

    if (data.bufferTime) {
      this.bufferTime = data.bufferTime / 1000;

      this.timeout = setInterval(() => {
        this.bufferTime = this.bufferTime - 1;
        if(this.bufferTime == 0) {
          this.isSessionTimeOut = true;
          this.close();
        }
      }, 1000);
    }
   } catch (error) {
    this.logger.error("Error @ SessionErrorDialogComponent: constructor() ->"+ error); 
   } 

  }

  close(data = null) {
    try {
      if(data === 'Ok'){
        this.traceLogger.trace("Continue Session");
      }
      this.dialogRef.close(this.isSessionTimeOut);
    } catch (err) {
      this.logger.error("Error @ SessionErrorDialogComponent: close() ->"+ err); 
    }
  }
  logout() {
    try {
      this.traceLogger.trace("Logout Button");
      this.userService.logoutUser();
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: logout() ->" + err);
    }
    }
  ngOnDestroy() {
    try {
    this.labelSessionDialogHeader = null;
    this.labelSessionDialogMessage = null;
    this.dialogRef = null;
    if(this.timeout) {
      clearInterval(this.timeout);
    }
    } catch (error) {
      this.logger.error("Error @ SessionErrorDialogComponent: ngOnDestroy() ->"+ error); 
    }    
  }

}
