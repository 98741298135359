export const SERVICE_CONFIG = {
    securityUrl: document.location.hostname.endsWith('starbucks.com') ? document.location.origin + "/sso/api/": "",
    serviceUrl: document.location.hostname.endsWith('starbucks.com') ? document.location.origin + "/ASBApi/api/": "",
    appUrl: document.location.hostname.endsWith('starbucks.com') ? document.location.origin + "/Asb" : "",
};

export const ENDPOINTS_CONFIG = {
    logout: "sec/logout/",
    security: "sec/saml/",
    idValid: 'sec/valid',
    WorldGeoCode: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
    AtlasNextUrl : document.location.origin + '/AtlasNext/',
    userinfo: "userprofiles/getUserInfo/",
    project: "projects/",
    projectGeo: "projects/geoHierarchy/",
    scenario: "scenarios/",
    scenarioByProject: "scenarios/bypid/",
    scenariosShared: "scenarios/shared/",
    openStoreByScenario: "scenarios/openStore/",
    pipelineStoreByScenario: "scenarios/pipeline/",
    allStoresByScenario: "stores/all/",
    allBaslineStoresByProject: "stores/baseline/",
    storeAnalyze: "stores/analyzePy/",
    atlasUpdate:"stores/atlasUpdate/",
    store: "stores/",
    getStoreExtraInfo : "stores/getStoreExtraInfo/",
    getStoreAttachmentUrl : "stores/getAttachment/",
    getStores: "stores/getStores/",
    dashboard: "dashboard/",
    invalidProjects: "projects/invalid",
    userProfile: "userprofiles/",
    lookups: {
        region: "lkp/rgn/",
        allregion: "lkp/allrgn/",
        opsRgn: "lkp/OpsRgn/",
        OpsArea: "lkp/OpsArea/" ,
        market: "lkp/mkt/",
        miniMarket: "lkp/minimkt/",
        projectScenario: "lkp/prjscn/",
        projectScenarioDetail: "lkp/projectScenarioDetail/",
        boundary: 'lkp/sbuxBnd?searchTerm=',
        preferenceAll: 'lkp/userPreferencesAll/',
        userShareService:'Lkp/usersByRegion/',
        projectsByGeo: 'lkp/projectsGeo/',
        prjScnByGeo: 'lkp/prjscngeo/',
        initial: 'lkp/storeIntDomains/',
        fyList: 'lkp/fylist/',
        wk53FyList: 'lkp/wk53fylist/',
        dashboardStoreList: 'lkp/stList/'
    },
    shareScenario:'Scenarios/share/',
    copyScnToExisting: 'projects/copyscn/',
    scnUnique: 'scenarios/uniquename/',
    pipelineCategories: 'stores/pipelinecategory/',
    cannAccStores : 'stores/canStores/',
    pipelineStores: "stores/pipeline/",
    logger: "logger/",
    getInvalidStores: 'stores/invalidStores/',
    getInvalidStoresAllQryParam: '?getHistoryAll=true',
    getProjectsValidate: 'projects/validate',
    acceptInvalidProjects: 'projects/acceptInvalid/',
    exportDifferences: 'scenarios/exportdiff/',
    satUsers: 'userprofiles/satUsers/',
    genericServiceUrl:"https://geo-analytics.starbucks.com/"
}
