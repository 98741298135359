<div class="flex-container">
    <div class="main-header">
        <span><span class="main-header-text">SEARCH RESULTS</span> -"{{txtSearch}}"</span>
    </div>
    <div class="filter-result"><span class="filter-result-text">REFINE RESULTS</span>
        <button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="filterMenu" class="btn-filter-trigger">
            <i class='fa fa-filter fa-2x filterFontIcon'></i>
        </button>
        <mat-icon class="refresh-header-icon" (click)="refreshData()"></mat-icon>
        <button class="close-icon" (click)="dialog.closeAll()"></button>
        <mat-menu #filterMenu="matMenu" class="filter-box" overlayPanelClass="filter-box" yPosition="below" xPosition="before">
            <mat-form-field class="refine-table" (keyup.enter)="setFilter()" (click)="$event.stopPropagation()" style="width: 20rem; height: 9.7rem;margin: -8px 0px 0px 0px !important;" floatLabel="never">
                <span class="refine-text">REFINE RESULTS</span>
                <div class="refine-items"> <label class="custom-size">ITEMS CREATED IN THE LAST
                        <input type="text" (click)="$event.stopPropagation()" (keyup)="onCreatedDateChange()" #createdDate matInput
                            style="width:33px;border: 1px solid; height: 19px;" class="ms-1">
                    </label>
                    <label class="custom-size ms-1">DAYS
                        <!-- <mat-checkbox (change)="onChangeCreatedDate($event)" [checked]="selectedCreatedDate === 1" class="ms-1">
                        </mat-checkbox> -->
                    </label>
                </div>
                <div class="refine-items"> <label class="custom-size">ITEMS UPDATED IN THE LAST
                        <input type="text" (click)="$event.stopPropagation()" (keyup)="onUpdatedDateChange()" #updatedDate matInput
                            style="width:33px;border: 1px solid; height: 19px;" class="ms-1">
                    </label>
                    <label class="custom-size ms-1">DAYS
                        <!-- <mat-checkbox (click)="$event.stopPropagation()" (change)="onChangeUpdateddDate($event)"
                            [checked]="selectedUpdatedDate === 2" class="ms-1"></mat-checkbox> -->
                    </label>
                </div>

                <div class="refine-items" > <label class="custom-size">SEARCH ONLY MY ITEMS

                        <mat-checkbox (click)="$event.stopPropagation()" (change)="onChangeMyItems($event)"
                            [checked]="selectedItem === 3" class="ms-1 searchFilter"></mat-checkbox>
                    </label>
                </div>
                <div class="refine-items" > <label class="custom-size">SEARCH ONLY ITEMS SHARED WITH ME

                        <mat-checkbox (click)="$event.stopPropagation()" (change)="onChangeSharedItems($event)"
                            [checked]="selectedItem === 4" class="ms-1 searchFilter"></mat-checkbox>
                    </label>
                </div>
                <!-- Archieve -->
                <!-- <div class="refine-items"> <label class="custom-size">SEARCH THE ARCHIEVE

                      <mat-checkbox (click)="$event.stopPropagation()" class="ms-1"></mat-checkbox>
                  </label>
              </div> -->


                <div class="btn-style">
                    <mat-icon class="check-btn" (click)="setFilter()">check_circle</mat-icon>
                    <mat-icon class="cancel-btn" (click)="close()">cancel</mat-icon>

                </div>
            </mat-form-field>



        </mat-menu>

    </div>


</div>

<h5 style="margin-bottom: 0px !important;">PROJECTS - {{filteredProjectData?.length}} RESULTS</h5>
<div id="CompletePage" #CompletePage>
    <div class="">
        <table class="custom table-sm table-striped">
            <thead class="">
                <tr>
                    <th class="th-name">NAME</th>
                    <th class="th-description">DESCRIPTION</th>
                    <th class="th-tag" >TAGS</th>
                    <th class="th-updateDate" [appSort]="filteredProjectData" data-order="desc" #dateSort data-name="updatedDate" scope="col" (click)="onDateSort()">UPDATED DATE
                        <a class="sort-by-sc {{prjAsc?'sort-by-sc-blue':''}}"></a>
                        <a class="sort-by-down-sc {{prjDesc?'sort-by-down-sc-blue':''}}"></a>
                    </th>
                    <th class="th-count">SCENARIOS</th>
                </tr>
            </thead>

            <tbody style="border: 1px solid darkgray;">

                <tr *ngFor="let data of filteredProjectData" style="height: 70px !important;">
                    <td class="name">
                        <div class="scnName hoverName" [innerHTML]="data.name | highlightSearch: txtSearch" (click)="onClick(data.id)" (mouseover)="tooltipOverText($event)" matTooltip="{{data.name}}" [matTooltipDisabled]="!isElipses" ellipsis>{{data.name}}</div>
                    </td>
                    <td class="description" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'"><span class="hover" (mouseover)="tooltipOverText($event)" matTooltip="{{data.description}}" [matTooltipDisabled]="!isElipses" ellipsis>{{data.description}}</span></td>
                    <td class="tag" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">
                        <div class="parent-tag"> <span *ngFor="let e of data.tags" class="tags" [innerHTML]="e | highlightSearch: txtSearch">
                                {{e}}</span></div>
                    </td>
                    <td class="date" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">{{data.updatedDate | date: 'MM/dd/yyyy'}}
                    </td>
                    <td class="count" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">{{data.scenariosCount}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <h5 class="mt-3" style="margin-bottom: 0px !important;">SCENARIOS - {{filteredScenarioData?.length}} RESULTS</h5>
    <div class="">
        <table class="custom table-sm  table-striped">
            <thead class="">
                <tr>
                    <th class="th-name" >NAME</th>
                    <th class="th-description" >DESCRIPTION</th>
                    <th class="th-tag">TAGS</th>
                    <th class="th-updateDate" [appSort]="filteredScenarioData" data-order="desc" #dateSortScenario data-name="updatedDate" scope="col" (click)="onDateSortScenario()">UPDATED DATE
                        <a class="sort-by-sc {{scnAsc?'sort-by-sc-blue':''}}"></a>
                        <a class="sort-by-down-sc {{scnDesc?'sort-by-down-sc-blue':''}}"></a>
                    </th>
                    <th class="th-count">STORES</th>
                </tr>
            </thead>
            <tbody style="border: 1px solid darkgray;">

                <tr *ngFor="let data of filteredScenarioData">
                    <td class="name" >
                        <div class="scnName hoverName" [innerHTML]="data.name | highlightSearch: txtSearch" (click)="onScenarioClick(data.scenarioId)" (mouseover)="tooltipOverText($event)" matTooltip="{{data.name}}" [matTooltipDisabled]="!isElipses" ellipsis>{{data.name}}</div> <br>
                        <div class="text-overflow"><b style="color: black;">PROJECT</b>: <a title={{data.projectName}} (click)="onClick(data.projectId)" style="color:blue;cursor: pointer;">{{data.projectName}}</a></div>
                    </td>
                    <td class="description" [ngClass]="filteredScenarioData?.length>3 ? '' : 'padding'"><span class="hover" (mouseover)="tooltipOverText($event)" matTooltip="{{data.description}}" [matTooltipDisabled]="!isElipses" ellipsis>{{data.description}}</span></td>
                    <td class="tag" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">
                        <div class="parent-tag"> <span *ngFor="let e of data.tags" class="tags" [innerHTML]="e | highlightSearch: txtSearch">
                                {{e}}</span></div>
                    </td>
                    <td class="date" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">{{data.updatedDate | date: 'MM/dd/yyyy'}}
                    </td>
                    <td class="count" [ngClass]="filteredProjectData?.length>3 ? '' : 'padding'">{{data.storesCount}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>