import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'project' },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
  { path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule), data: { breadcrumb: 'Projects' } },
  { path: 'scenario', loadChildren: () => import('./scenario/scenario.module').then(m => m.ScenarioModule), data: { breadcrumb: 'Scenarios' } },
  { path: 'compare', loadChildren: () => import('./compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
  { path: 'pipeline', loadChildren: () => import('./pipeline/pipeline.main.module').then(m => m.PipeLineMainModule) },
  //{ path: 'help', loadChildren: './help/help.module#HelpModule' },
  { path: '**', pathMatch: 'full', redirectTo: 'project' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    // Tell the router to use the HashLocationStrategy.
    useHash: true,
    enableTracing: false,
    // This will tell Angular to preload the lazy-loaded routes after the
    // application has been bootstrapped. This will extend to both top-level
    // and nested lazy-loaded modules.
    preloadingStrategy: PreloadAllModules,
    // relativeLinkResolution: 'legacy'
    
})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
