import { EventEmitter } from "@angular/core";
import { utc } from 'moment';

export class CommonHelper {
  static refreshProjects = new EventEmitter();

  static divideAndRound(numerator, denominator, roundTo = 2) {
    try {
      if (denominator && denominator != 0) {
        return Math.round(numerator / denominator * Math.pow(10, roundTo)) / Math.pow(10, roundTo);
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
  static percentAndRound(numerator, denominator, roundTo = 2) {
    try {
      if (denominator && denominator != 0) {
        return Math.round(numerator / denominator * 100 * Math.pow(10, roundTo)) / Math.pow(10, roundTo);
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
  static downloadCsv(csvContentIE, filename) {
    try {
      let blob = new Blob(["\ufeff", csvContentIE], {
        type: 'application/vnd.ms-excel'
      });
      let ie11andabove = (navigator.userAgent.indexOf('Trident') !== -1 && navigator.userAgent.indexOf('MSIE') === -1);
      let ie10andbelow = (navigator.userAgent.indexOf('MSIE') !== -1);
      if (ie11andabove || ie10andbelow) {
        (window.navigator as any).msSaveBlob(blob, filename);
      } else {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
  static formatDateMMDDYYYY(value, separator = "/") {
    let result = null;
    let to2digit = (n: number) => {
      return ('00' + n).slice(-2);
    };
    if (value) {
      let type = typeof (value);
      if (type != "number") {
        if (type == "string") {
          let regexMDY = /^(([0-9]{1,2}[-|/|.]{1}){2}[0-9]{2,4})/g;
          let regexYDM = /^([0-9]{2,4}([0-9]{1,2}[-|/|.]{1}){2})/g;
          if (!regexMDY.test(value) && !regexYDM.test(value)) {
            return null;
          }
        }
        let temp = new Date(value);
        if ((temp as any) != "Invalid Date") {
          let month = temp.getMonth() + 1;
          let date = temp.getDate();
          let year = temp.getFullYear();

          if (year >= 1970 && year <= 2100) {
            result = to2digit(month) + separator + to2digit(date) + separator + year;
          }
          
        }
      }
    }
    return result;
  }
  static getMomentFromMMDDYYYY(value = "", separator = "/") {
    return utc(value).locale("en-GB");
  }
  static getFormattedCSVData(data) {
    if (data) {
      if (data.toString().indexOf('"') !== -1) {
        data = data.toString().replace(/"/g, '""');
      }
      if (data.toString().search(/\s|,/g) > -1) {
        data = '"' + data.toString().replace(/\s/g, " ") + '"';
      }
    } else {
      return "";
    }
    return data;
  }
}
