import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegexMask } from './regex.directive';
/**
 * Import this module for validations.
 */
@NgModule({
    imports: [CommonModule],
    declarations: [RegexMask],
    exports: [RegexMask]
})
export class ValidationModule { }