import { Pipe, PipeTransform } from '@angular/core';
import { Logger } from '../Logger/logger.module';

const PADDING = '000000';

/**
 * Pipe for Currency format
 */
@Pipe({ name: 'atlasCurrency' })
export class MyCurrencyPipe implements PipeTransform {

    private DECIMAL_SEPARATOR: string;
    private THOUSANDS_SEPARATOR: string;

    constructor(private logger: Logger) {
        // TODO comes from configuration settings
        this.DECIMAL_SEPARATOR = '.';
        this.THOUSANDS_SEPARATOR = ',';
    }

    /**
     * Method to transform input data in currency format.
     * 
     * @param value 
     * @param fractionSize 
     */
    transform(value: number | string, fractionSize: number, prefix: string = "", isBaseline:boolean = false): string {
        try {
            if (value === null || value === undefined || value.toString().trim() === "") {
                if (isBaseline) return prefix + 0;
            }
            let numberValue = this.parse(value + "", fractionSize);
            let input = Number.parseFloat(numberValue + "");
            value = Number(Math.round(input * Math.pow(10, fractionSize)) / Math.pow(10, fractionSize));
            if (value === 0 && isBaseline) return prefix + value;
            let [integer, fraction = ''] = (value || '').toString()
                .split(this.DECIMAL_SEPARATOR);
            if (fraction !== '') {
                fraction = fractionSize > 0
                    ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
                    : '';
            }
            
            integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
            
            return (integer + fraction) ? prefix + integer + fraction : "";
        } catch (err) {
            this.logger.error("Error @ MyCurrencyPipe: transform() ->"+ err);
        }
    }

    /**
     * Method to convert currency formatted string into number.
     * 
     * @param value 
     * @param fractionSize 
     */
    parse(value: any, fractionSize: number): string {
        try {
            let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

            integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

            fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
                ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
                : '';

            return integer + fraction;
        } catch (err) {
            this.logger.error("Error @ MyCurrencyPipe: parse() ->"+ err);
        }
    }

    /**
     * Method to round off input data to specified decimal places.
     * @param input 
     * @param decimalPlaces 
     */
    convertToDecimal(input, decimalPlaces) {
        try {
            return Number(Math.round(input * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces));
        } catch (err) {
            this.logger.error("Error @ MyCurrencyPipe: convertToDecimal() ->"+ err);
        }
    }

}
