export const MESSAGE_CONFIG = {
  errorMessages: {
    required: "REQUIRED"
  },
  messages: {
    atlasUpdate : "Are you sure you want to update the Primary Action and Primary Action FY in Atlas?",
    archiveProject: "Are you sure you want to permanently delete the selected project and all of its scenarios? Deleting cannot be undone.",
    archiveScenario: "Are you sure you want to permanently delete the selected scenario? Deleting cannot be undone.",
    updateProjectSuccess: "Project Updated Successfully.",
    noResultsFound: "No results found for current filter. Please change filter option and try again.",
    failedSaveProject:"Save Project Failed",
    scenarioSaved:"Scenario Successfully Saved",
    updateScenarioSuccess:"Scenario Updated Successfully",
    updatestoreSuccess:"Store Updated Successfully",
    savePipelinestoreSuccess:"Pipeline Store Updated Successfully",
    shareScenario: "Please select to share or deselect to unshare scenarios",
    noUnshareScenario: "Please select the new users to share the scenarios (Unshare is not available for multiple scenarios)",
    scenarioCopy:"Are you sure you want to copy the shared scenario to a new Project?",
    noProjects:"No Project exist with matching Project of the shared scenario",
    existingCopy: "Please select the Project to copy the scenario to.",
    pipelineCatSuccess: "Dummy Pipeline Category saved successfully",
    pipelineCatSuccess_Delete: "Dummy Pipeline Category deleted successfully",
    invalidProjects: "This project is invalid due to the following stores:",
    errorStores: "Below store processing has failed. Please ask your support team to check.",
    exportScenario: "Please select the scenarios to compare the store difference",
    exportScenarioDiffSuccess :"Exported Successfully",
    exportScenarioNoDiffSuccess : "No Differences found for export",
    sessionInvalidated: "You're being timed out due to inactivity",
    sessionWarning: "Your session is about to timeout in next few minutes. Any unsaved changes will be lost.",
    userAddSuccess: "Sat User saved successfully",
    geographyChanged:"Since the creation of this Project the geography has changed"
  },
  headers: {
    archiveProject: "Delete Project",
    archiveScenario: "Delete Scenario",
    updateProject: "Update Project",
    shareScenario: "Share Scenario",
    scenarioCopy:"Copy Shared Scenario",
    invalidProjects: "Invalid Project",
    storeProcessing: "Store Processing",
    export: "Scenario Store Difference",
    sessionInvalidate:"Session Timeout",
    sessionExpWarning : "Session Expiration Warning",
    atlasPortfolioValidation : "Atlas Portfolio Update"
  },
  errConfig:{
    'requirePrompt': 'Please Enter',
    'requireProjectName':'Please Enter Project Name',
    'duplicateProjectName':'Project Name already exist',
    'requiredRegion':'Please enter Project',
    'requiredGeoSelection':'Please Perform Project Geography Selection',
    'requireScenarioName':'Please Enter Scenario Name',
    'nullSearchValue': 'Search field empty',
    'valueTooShort': 'Search value too short',
    'emptySearchResults': 'No results found',
    'addressInvalidError': 'This Address does not exist. Please try again.',
    'InvalidLatLongFormat': 'This Latitude, Longitude does not exist. Please try again.',
    'storenumInvalidError': 'This Store Number does not exist. Please try again.',
    'storeNameInvalidError': 'This Store Name does not exist. Please try again.',
    'LatitudeInvalid': 'This Latitude does not exist. Please try again.',
    'LongitudeInvalid': 'This Longitude does not exist. Please try again.',
    'LatitudeOutOfRange': 'This Latitude does not exist. Please try again.',
    'LongitudeOutOfRange': 'This Longitude does not exist. Please try again.',
    'LatLongOutOfRange': 'This Latitude, Longitude does not exist. Please try again.',
    'uncheckBaseLine':'Baseline can not be deleted. Please uncheck baseline Scenario to proceed',
    'invalidRegion': 'No Region found matching value entered',
    'invalidMarket': 'No Market found matching value entered',
    'invalidMiniMarket': 'No Mini-Market found matching value entered',
    'somethingWrongTryAgain': 'Something went wrong! Please try again...',
    'duplicateUser': 'The user ID is already present.',
    'UnAuthorizedEditInRegion': 'You are not authorized for editing in this region',
    'duplicateScenarioInProj': 'The scenario name already exists in the project',
    'notSatUser': 'You do not have access to Atlas Scenario Builder.',
    'noRegionAccess': 'You do not have access to any regions.',
    'atlasPortfolioUpdateGUID': 'The Atlas Store is not available for Portfolio Update',
    'atlasPortfolioUpdateDate': 'The Date has exceeded the validation condition of SAT Portfolio Max Update Date:- '
  }
};