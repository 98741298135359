// Search Service

import { Injectable } from '@angular/core';
import { SERVICE_CONFIG, ENDPOINTS_CONFIG } from '../config/service.config';
import { MESSAGE_CONFIG } from '../config/message.config';
import { ServiceHelper } from '../helpers/service.helper';
import { ProjectHierarchy } from '../project/project-hierarchy.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor(private serviceHelper: ServiceHelper) { }

  getSuggestions(searchText) {
    return new Promise((resolve, reject) => {
      try {
        if (!searchText) {
          reject(MESSAGE_CONFIG.errConfig.nullSearchValue);
        }
        else {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.projectScenario + '?searchterm=' + searchText;
          this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getFilteredProjectHierarchy(searchString, geoLevel = '', geoValue = '') {
    return new Promise((resolve, reject) => {
      try {
        if (searchString) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.boundary + searchString;
          if (geoLevel && geoValue) {
            url = url + '&' + geoLevel.toLowerCase() + '=' + geoValue;
          }
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectHierarchy[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": searchString");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
