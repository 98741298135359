<!-- Dialog Header-->
<div *ngIf="!sharedTooltip">
  <div *ngIf="!params.isInvalidProject" class="hdr-pnl">
    <h4>{{params.headerText}} <mat-icon style="font-size: 1.5rem;color:red;vertical-align: bottom" class="ms-1"
        aria-label="" *ngIf="params.error">error</mat-icon>
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close('Cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <!-- Dialog Share Project Content-->
  <div class="msg-pnl" *ngIf="params.share">
  
    <label for="Share" class="hierarchy-label">Share a copy to:</label>
  
    <span class="pnl-expando">
      <mat-form-field style="width:100%;" appearance="outline" class="shareInput">
        <mat-chip-grid #chipList>
          <input type="text" [formControl]="shareControl" [matAutocomplete]="autoSelectUser" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" #matInput [matChipInputAddOnBlur]="addOnBlur"
            class="chipInput shareInputChip" placeholder="Search User..." [class.underline]="selectedUser.length > 0"
            (focus)="onInputFocus()">
          <mat-chip-row class="chipsColor" *ngFor="let users of selectedUser" [selectable]="selectable"
            [removable]="removable" (removed)="remove(users)" [color]="primary">
            {{users?.value}}
            <mat-icon matChipRemove class="chipRemove" *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
        </mat-chip-grid>
        <mat-autocomplete #autoSelectUser="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let user of scenarioShareUsers | async" [value]="user" class="noCbx">
            <span [class.selectedUser]="isSelectedUser(user)">{{user?.value}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </span>
  </div>
  
  <!-- Dialog message Content-->
  <div *ngIf="!params.isInvalidProject" class="msg-pnl">
    <label class="d-flex flex-row msg-txt">{{params.messageText}}</label>
  </div>
  
  <!-- Dialog Scenario Clone Content-->
  <div class="msg-pnl" *ngIf="params.scenarioClone">
    <mat-form-field class="full-width">
      <mat-select (selectionChange)="projectsChange($event)" [hideSingleSelectionIndicator] = "true">
        <mat-option *ngFor="let proj of params.projects" [value]="proj.id">
          {{proj.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="error" *ngIf="scnErrMsg">{{scnErrMsg}}</span>
    <span *ngIf="scnErrMsg">Please enter new scenario name:</span>
    <mat-form-field class="full-width" *ngIf="scnErrMsg">
      <input type="text" [(ngModel)]="scnName" matInput />
    </mat-form-field>
  </div>
  
    <div *ngIf="params.isInvalidProject" class="dia-content">
      <div class="header">
        <div class="header-txt">
          {{popup.header}}
        </div>
       </div>
      <div class="header-desc">
        <div class="desc">
          {{popup.desc}}
        </div>
       </div>
      <div class="pict-desc">
        <span><div class="circle1">
          <div class=circle-text>
            {{params.invalidStores.storeChangeCount}}
          </div>
          <div class="circle-footer-txt">
          STORE <br> CHANGE TOTAL
          </div>
        </div></span>
        <span class="plus-icon">+</span>
        <span><div class="circle2">
          <div class=circle-text>
            {{params.invalidStores.oldStoreCount}}
          </div>
          <div class="circle-footer-txt">
            <!-- {{popup.circle.desc2}} -->
            OLD <br> STORE COUNT
          </div>
        </div></span>
        <span class="equal-icon">=</span>
        <span><div class="circle3">
          <div class=circle-text-modified>
            {{params.invalidStores.modifiedStoreCount}}
          </div>
          <div class="circle-footer-txt">
            <!-- {{popup.circle.desc3}} -->
            MODIFIED <br> STORE COUNT
          </div>
        </div></span>
      </div>
      <div class="all-details">
      <div class="open-detail">
        {{popup.label.label1}} <span class="total-change">{{popup.label.change}}
          
        <span>
          <span *ngIf = "params.invalidStores.openStoreCount > 0"> +</span>
          {{params.invalidStores.openStoreCount}}
        </span>
        </span>
        <div class="detail-content">
            <span class="store-detail-text" *ngFor="let store of params.invalidStores.openStore">{{store}}</span>
        </div>
      </div>
      <div class="closed-detail">
        {{popup.label.label2}} <span class="total-change">{{popup.label.change}}
        <span>
          <span *ngIf = "params.invalidStores.closeStoreCount > 0"> +</span>
          {{params.invalidStores.closeStoreCount}}
        </span>
      </span> 
        <div class="detail-content">
            <span class="store-detail-text" *ngFor="let store of params.invalidStores.closeStore">
              {{store}}</span>
        </div>
      </div>
      <div class="change-detail">
        {{popup.label.label3}} <span class="total-change">{{popup.label.change}}
         <span>
          <span *ngIf = "params.invalidStores.geoChangeStoreCount > 0"> +</span>
           {{params.invalidStores.geoChangeStoreCount}}
          </span>
        </span>
        <div class="detail-content">
            <span class="store-detail-text" *ngFor="let store of params.invalidStores.geoChangeStore" [innerHTML]="getGeoChangeText(store) | textItalic: italicText">{{store}}</span>
        </div>
      </div>
      </div>
      <div class="footer">
        <button class="btn"
        (click)="close('Ok')" >
            GOT IT
        </button>
    
    </div>
    </div>
 
  

  <div class="msg-pnl" *ngIf="params.export">
    <mat-form-field class="half-width sat-dc-form-field-wrapper">
      <mat-select [value]="selectedObj" [hideSingleSelectionIndicator] = "true" [disableRipple] = "true" panelClass="sat-dc-mdc-panel" (selectionChange)="onScenariosPriChange($event)">
        <mat-option *ngFor="let scn of scenarios" [value]="scn">
          {{scn.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="half-width sat-dc-form-field-wrapper">
      <mat-select [value]="selectedObjSec" [hideSingleSelectionIndicator] = "true" [disableRipple] = "true" panelClass="sat-dc-mdc-panel" (selectionChange)="selectedObjSec = $event.value">
        <mat-option *ngFor="let scn of scenariosSec" [value]="scn">
          {{scn.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div class="msg-pnl store-list" *ngIf="params.processErrorStores">
    <ul>
      <li class="list-items" *ngFor="let store of params.errorStores">
        <span class="item-title">{{store}}</span>
      </li>
    </ul>
  </div>
  
  <div class="msg-pnl" *ngIf="params.dashboardFilterNoResult">
      <mat-icon style="font-size: 1.5rem;color:orange;">info</mat-icon> <div class="text-secondary filterWarningMsg">Previous active filter based data is displayed.</div>
  </div>
  
  <!-- Dialog Footer Content-->
  <div *ngIf="!params.isInvalidProject" [ngStyle]="{'border-top-style':params.error ? 'none' : 'solid'}" [ngClass]="btn-pnl" style="color: rgb(0,0,0,.87);">
    <button id="okBtnId" mat-raised-button *ngIf="params.isOkButton" class="my-3 mx-1 sat-button btn-save"
      (click)="close('Ok')">
      <mat-icon style="font-size: 1.5rem; margin-right: 4px !important;" [style.color]="color.save" class="icon cl-icon">check_circle</mat-icon>
      {{params.okButtonText}}
    </button> 
    <button mat-raised-button *ngIf="params.isCancelButton" class="my-3 mx-1 sat-button btn-cancel"
      (click)="close('Cancel')">
      <mat-icon style="font-size: 1.5rem;" [style.color]="color.cancel" class="icon cl-icon">cancel</mat-icon>
      {{params.cancelButtonText}}
    </button>
  </div>
  </div>
  <div *ngIf="sharedTooltip" style="font-family: 'Montserrat' !important;">
    <div *ngIf="filteredData">
    <div (click)="closeSharedWithDialog()" class="shared-close" style="float:right;margin-top:3px;">
          <mat-icon class="d-flex align-items-center" style="font-size: 28px;">cancel</mat-icon>
        </div>
    <div *ngIf="params.data.icon==='asc'" style="font-size:20px;">Sorting: Ascending Order</div>
    <div *ngIf="params.data.icon==='desc'" style="font-size:20px;">Sorting: Descending Order</div>
    
      <div class="shared-with">
        <div>Filtered for {{params.data.header}}: </div>
       </div>
      <div style="height: 210px;overflow: auto;">
      <div *ngFor="let i of params.data.data" style="margin-top:5px;">
        <ul><li>{{i}}</li></ul>
      </div>
    </div>
  
    </div>
    <div *ngIf="!filteredData">
      <div class="shared-with">
        <div>{{sharedWith}}</div>
        <div (click)="closeSharedWithDialog()" class="shared-close">
          <mat-icon class="d-flex align-items-center" style="font-size: 28px;">cancel</mat-icon>
        </div>
      </div>
      <div *ngFor="let i of params.data" style="margin-top:15px;">
        <div class="shared-main-div" [style.font-style]="i.value === 'Me'?'italic':''">
          <span class="shared-value">{{i.value}}</span>
          <span class="shared-date">{{i.date | date: 'MM/dd/yyyy'}}</span>
        </div>
      </div>
    </div>
  </div>