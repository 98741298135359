// Project Service

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MESSAGE_CONFIG } from '../config/message.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { ServiceHelper } from '../helpers/service.helper';
import { ProjectHierarchy } from './project-hierarchy.model';
import { ProjectModel } from './project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements OnDestroy {

  // for project update broadcast
  projectsUpdated = new Subject<any>();
  projectsUpdated$ = this.projectsUpdated.asObservable();

  // for project refreshs
  projectsRefreshed = new Subject<any>();

  constructor(private serviceHelper: ServiceHelper) { }

  getprojectsRefreshed(): Observable<any> {
    return this.projectsRefreshed.asObservable();
  }

  // service destroy lifecycle
  ngOnDestroy() {
    try {
      this.projectsUpdated && this.projectsUpdated.unsubscribe();
      this.projectsRefreshed && this.projectsRefreshed.unsubscribe();
      this.projectsRefreshed = null;
      this.projectsUpdated = null;
      this.projectsUpdated$ = null;
      this.getAllProjects = null;
      this.getProjectById = null;
      this.createProject = null;
      this.deleteProjectById = null;
      this.updateProjectById = null;
      this.serviceHelper = null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // get all accessible projects from service
  getAllProjects() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectModel[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  // get project by unique id
  getProjectById(id, isShare = false) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project + id;
          if (isShare) {
            url = url + "?share=" + isShare;
          }
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectModel) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Project Id");
        }

      } catch (err) {
        reject(err);
      }
    });
  }

  // update project by unique id
  updateProjectById(id, project: ProjectModel) {
    return new Promise((resolve, reject) => {
      try {
        if (id && project) {
          let prj = {
            Id:project.Id,
            geoType:project.geoType,
            Name:project.Name,
            Comments:project.Comments,
            Isactive:project.Isactive,
            tags:project.tags,
            pr:project.pr
          }
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project + id;
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, prj).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          // send missing data error message
          let tmpMsg = "";
          if (!id) {
            tmpMsg = "Project Id";
          }
          if (!project) {
            if (tmpMsg.length > 0) {
              tmpMsg = tmpMsg + ", ";
            }
            tmpMsg = tmpMsg + "Project Data";
          }
          reject(MESSAGE_CONFIG.errorMessages.required + ": " + tmpMsg);
        }

      } catch (err) {
        reject(err);
      }
    });
  }

  // create new project
  createProject(project: ProjectModel) {
    return new Promise((resolve, reject) => {
      try {
        if (project) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project;
          let temp = this.serviceHelper.PostServiceJsonDataWithPortalToken(url, project).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Project Data");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  // delete project by unique id
  deleteProjectById(id) {
    return new Promise((resolve, reject) => {
      try {
        if (id) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.project + id;
          let temp = this.serviceHelper.DeleteServiceJsonDataWithPortalToken(url).subscribe((data) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": Project Id");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getFilteredProjectHierarchy(searchString) {
    return new Promise((resolve, reject) => {
      try {
        if (searchString) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.boundary + searchString;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectHierarchy[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": searchString");
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  copyScenarioToProject(projectId, scenarioId, newName = "") {
    return new Promise((resolve, reject) => {
      try {
        if (projectId && scenarioId) {
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.copyScnToExisting + projectId + "/" + scenarioId;
          if (newName) {
            url = url + "?newName=" + newName;
          }
          let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, null).subscribe((data: ProjectHierarchy[]) => {
            resolve(data);
            temp.unsubscribe();
          }, (error) => {
            reject(error);
            temp.unsubscribe();
          });
        }
        else {
          reject(MESSAGE_CONFIG.errorMessages.required + ": searchString");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  getInvalidStores(projectId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.getInvalidStores + projectId;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any[]) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  acceptInvalidProject(projectId) {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.acceptInvalidProjects + projectId;
        let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, null).subscribe((data) => {
          resolve(data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

}
