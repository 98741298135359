import { Injectable } from '@angular/core';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { ServiceHelper } from './service.helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private serviceHelper: ServiceHelper) { }

  updateUserPreference(data) {
    return new Promise<void>((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.userProfile;
        let temp = this.serviceHelper.PutServiceJsonDataWithPortalToken(url, data).subscribe(() => {
          resolve();
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      }
      catch (error) {
        reject(error);
      }
    });
  }
  getUserPreference() {
    return new Promise((resolve, reject) => {
      try {
        let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.userProfile;
        let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any) => {
          resolve(data.respObj || data);
          temp.unsubscribe();
        }, (error) => {
          reject(error);
          temp.unsubscribe();
        });
      }
      catch (error) {
        reject(error);
      }
    });
  }
  logoutUser() {
    try {
      let url = SERVICE_CONFIG.securityUrl + ENDPOINTS_CONFIG.logout + "?appUrl=" + SERVICE_CONFIG.appUrl;
      let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: any) => {
        temp.unsubscribe();
        window.location.assign(data.url);
      }, (error) => {
        temp.unsubscribe();
      });
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
}
