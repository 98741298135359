import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DomainHelperService } from '../helpers/domain-helper.service';
import { APP_CONFIG } from '../config/app.config';
import { SaUserPreference, SharedScenariosNotif } from '../helpers/models.helper';
import { UserService } from '../helpers/user.service';
import { Logger } from '../Logger/logger.module';
import { DialogParams, DialogResultParams } from '../sat-dialog/sat-dialog.model';
import { SatDialogService } from '../sat-dialog/sat-dialog.service';
import { MESSAGE_CONFIG } from '../config/message.config';
import { ProjectService } from '../project/project.service';
import { CreateProjectComponent } from '../project/create-project/create-project.component';
import { ScenarioService } from '../scenario/scenario.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonHelper } from '../helpers/common.helper';
import { ProjectModel } from '../project/project.model';
import { HelpComponent } from '../help/help.component';
import { Subject } from 'rxjs';
import { EnhancedSearchComponent } from '../enhanced-search/enhanced-search.component';
import { TraceLoggerService } from '../Logger/trace-logger.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  
  static userPreferenceChange = new EventEmitter();
  static onUserPreferencesLoad = new EventEmitter();
  static onCurrentUserPrefLoad = new EventEmitter();
  static selectInvalidProject = new EventEmitter();
  static shareScenariosSubject = new EventEmitter();

  @Output() onToggleMenu = new EventEmitter();
  @Input() username = '';
  @Input() isAdminUser = false;

  isShowSearch = false;
  invalidProjects: any[] = [];
  sharedScenarios: SharedScenariosNotif[] = [];
  Profile: any[] = APP_CONFIG.userProfile;
  defaultProjectSort: any[];
  defaultScenarioSort: any[];
  projectSortBy: SaUserPreference;
  scenarioSortBy: SaUserPreference;
  projectSortOptions = false;
  scenarioSortOptions = false;
  getUserPrefer: any = null;
  userServ: any = null;
  scenarioOnOperation = '';
  initiatorId = '';
  projectOnoperation = '';
  subsCloseDialog;  
  displayText: any ;
  isEnvShow:boolean;

  subsRefreshScn;
  labels = APP_CONFIG.labels.appnavbar;


  constructor(public dialog: MatDialog, private router: Router, private domainHelper: DomainHelperService, private userService: UserService, private cd: ChangeDetectorRef, private logger: Logger, private projectService: ProjectService, private scenarioService: ScenarioService, private traceLogger: TraceLoggerService) {

  }

  ngOnInit() {
    this.displayText = "ASB "+this.domainHelper.getEnvironment();
    this.isEnvShow = this.domainHelper.isEnvironmentBanner(this.domainHelper.getEnvironment());
    try {
      this.getUserPrefer = this.domainHelper.getUserPreferencesOptions().then((data: SaUserPreference[]) => {
        try {
          APP_CONFIG.userPreferences = data;
          AppNavbarComponent.onUserPreferencesLoad.emit();

          this.defaultProjectSort = data.filter((val) => {
            return val.mItem === 'Project Landing Page' && val.sItem === 'Default Sort';
          });
          this.defaultScenarioSort = data.filter((val) => {
            return val.mItem === 'Scenario Landing Page' && val.sItem === 'Default Sort';
          });
          this.projectService.getprojectsRefreshed().subscribe(dataPrj => {
            try {
              this.invalidProjects = (dataPrj && dataPrj.length > 0) ? dataPrj.filter((val) => {
                return val.invDate;
              }) : [];
              this.cd.detectChanges();
            } catch (err) {
              this.logger.error("Error @ AppNavbarComponent: getprojectsRefreshed() ->" + err);
            }
          });

          this.userServ = this.userService.getUserPreference().then((dataUpr: any[]) => {
            try {
              APP_CONFIG.currentUserPref = dataUpr;
              AppNavbarComponent.onCurrentUserPrefLoad.emit();

              const proj = dataUpr.find((val) => {
                return val.sId === 1;
              });
              const sce = dataUpr.find((val) => {
                return val.sId === 2;
              });
              this.projectSortBy = proj ? this.defaultProjectSort.find((val) => {
                return val.sId === proj.sId && val.optId === proj.optId;
              }) : this.defaultProjectSort[0];
              this.scenarioSortBy = sce ? this.defaultScenarioSort.find((val) => {
                return val.sId === sce.sId && val.optId === sce.optId;
              }) : this.defaultScenarioSort[0];

              this.cd.detectChanges();
            } catch (err) {
              this.logger.error("Error @ AppNavbarComponent: getUserPreference() ->" + err);
            }
          });


          this.cd.detectChanges();
        } catch (err) {
          this.logger.error("Error @ AppNavbarComponent: getUserPreferencesOptions() ->" + err);
        }
      });
      
      this.subsCloseDialog = SatDialogService.closeDialog.subscribe((params: DialogResultParams) => {
        try {

          if (params) {
            if (params.initiator === this.initiatorId) {
              if (params.operation === 'scenarioCopy') {
                if (params.action === 'Ok') {
                  // this.router.navigate(['/project/create', 'share', this.projectOnoperation, this.scenarioOnOperation]);
                  try{
                    this.dialog.open(CreateProjectComponent , {
                      width:"1000px",
                      height:'975px',
                      data:{'mode':'share', 'id':this.projectOnoperation,'sid':this.scenarioOnOperation},
                      panelClass:'create-project-dialog',
                      disableClose: true
                    })
              
                  }
                  catch(err) {
                    this.logger.error("Error @ ProjectComponent: createProject() ->"+ err);
              
                  }
                  this.cd.detectChanges();
                }
              }
              else if (params.operation === 'scenarioCopyExist') {
                if (params.action == 'Ok') {
                  this.projectService.copyScenarioToProject(params.resultProj, this.scenarioOnOperation, params.resultScnName).then((data) => {
                    try {
                      this.refreshSharedScenarios();
                      this.projectService.getProjectById(params.resultProj).then((dataNew: ProjectModel) => {
                        this.scenarioService.setSelectedProject(dataNew);
                        this.router.navigate(['/project/scenario', dataNew.Id]);
                        AppNavbarComponent.shareScenariosSubject.next(true);
                        CommonHelper.refreshProjects.next(true);
                        this.cd.detectChanges();
                      });
                    } catch (err) {
                      this.logger.error("Error @ AppNavbarComponent: copyScenarioToProject() ->" + err);
                    }
                  }, (err) => {
                    try {
                      let paramsDiag = new DialogParams();
                      paramsDiag.headerText = 'Copy Scenario';
                      if (err.error === 'Duplicate Scenario Name') {
                        paramsDiag.messageText = MESSAGE_CONFIG.errConfig.duplicateScenarioInProj;
                      } else {
                        paramsDiag.messageText = MESSAGE_CONFIG.errConfig.somethingWrongTryAgain;
                      }
                      paramsDiag.isOkButton = false;
                      paramsDiag.isCancelButton = true;
                      paramsDiag.cancelButtonText = 'OK';
                      paramsDiag.operationId = 'copyScenario';
                      paramsDiag.initiatorId = 'AppNavbarComponent' + new Date().getTime();
                      paramsDiag.error = true;
                      SatDialogService.openDialog.next(paramsDiag);
                      this.logger.error(err);
                      this.cd.detectChanges();
                    } catch (err) {
                      this.logger.error("Error @ AppNavbarComponent: copyScenarioToProject() ->" + err);
                    }
                  });
                  this.cd.detectChanges();
                }
              }
              // else any other operation
            }
          }
        } catch (err) {
          this.logger.error("Error @ AppNavbarComponent: closeDialog() ->" + err);
        }
      });
      this.subsRefreshScn = CreateProjectComponent.refreshSharedScn.subscribe(() => {
        try {
          this.refreshSharedScenarios();
        } catch (err) {
          this.logger.error("Error @ AppNavbarComponent: refreshSharedScn() ->" + err);
        }
      });
      this.refreshSharedScenarios();
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: ngOnInit() ->" + err);
    }
  }
  refreshSharedScenarios() {
    try {
      this.domainHelper.getSharedScenarios().then((data: SharedScenariosNotif[]) => {
        this.sharedScenarios = data;
        this.cd.detectChanges();
      });
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: refreshSharedScenarios() ->" + err);
    }
  }


  ngOnDestroy() {
    try {
      if (this.getUserPrefer) {
        this.getUserPrefer.unsubscribe();
        this.getUserPrefer = null;
      }
      if (this.userServ) {
        this.userServ.unsubscribe();
        this.userServ = null;
      }

      if (this.subsCloseDialog) {
        this.subsCloseDialog.unsubscribe();
        this.subsCloseDialog = null;
      }
      this.onToggleMenu && this.onToggleMenu.unsubscribe();
      this.invalidProjects = null;
      this.defaultProjectSort = null;
      this.defaultScenarioSort = null;
      this.Profile = null;
      this.sharedScenarios = null;
      this.refreshSharedScenarios = null;
      this.onSearchResult = null;
      this.initScenarioCopy = null;
      this.selectInvalidProj = null;
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: ngOnDestroy() ->" + err);
    }
  }
  toggleMenu() {
    try {
      this.onToggleMenu.next(true);
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: toggleMenu() ->" + err);
    }
  }

  toggleSearch() {

  }

  onSearchResult(result) {
    try {
      if (result) {
        if (result.isProject) {
          this.router.navigate(['/project/scenario', result.data.pid.toLowerCase()]);
          this.traceLogger.trace("Search Project");
        } else if (result.isScenario) {
          this.router.navigate(['/scenario/edit/view', result.data.sid.toLowerCase()]);
          this.traceLogger.trace("Search Scenario");
        } else if(result.isTag){
            // this.filteredList=[];
            this.traceLogger.trace("Tag Search");
            let searchTerm = result.data.value.split(':');
            this.dialog.open(EnhancedSearchComponent, {
              width:'1200px',
              height:'880px',
              data:{'txtSearch':searchTerm[1].trim()},
              panelClass:'create-search-dialog',
              disableClose: true
            })
        }
      }
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: onSearchResult() ->" + err);
    }
  }


  initScenarioCopy(scenarioId, projectId, isNewProject, name = "") {
    try {
      if (isNewProject) {
        let params = new DialogParams();
        params.headerText = MESSAGE_CONFIG.headers.scenarioCopy;
        params.messageText = MESSAGE_CONFIG.messages.scenarioCopy;
        params.isOkButton = true;
        params.okButtonText = 'Yes';
        params.isCancelButton = true;
        params.cancelButtonText = 'No';
        params.operationId = 'scenarioCopy';
        params.initiatorId = 'AppNavbarComponent' + new Date().getTime();
        params.panelClass = 'custom-big';
        this.initiatorId = params.initiatorId;
        this.scenarioOnOperation = scenarioId;
        this.projectOnoperation = projectId;
        SatDialogService.openDialog.next(params);

      } else {
        this.domainHelper.getSimilarGeoProjects(projectId).then((data: any[]) => {
          try {
            let params = new DialogParams();
            params.headerText = MESSAGE_CONFIG.headers.scenarioCopy;
            if (data.length > 0) {
              params.projects = data;
              params.messageText = MESSAGE_CONFIG.messages.existingCopy;
              params.isOkButton = true;
              params.okButtonText = 'Yes';
              params.isCancelButton = true;
              params.cancelButtonText = 'No';
              params.operationId = 'scenarioCopyExist';
              params.scenarioClone = true;
              this.scenarioOnOperation = scenarioId;
              this.projectOnoperation = projectId;
              params.panelClass = 'custom-big';
              params.scenarioName = name;
            } else {
              params.messageText = MESSAGE_CONFIG.messages.noProjects;
              params.isOkButton = false;
              params.isCancelButton = true;
              params.cancelButtonText = 'OK';
              params.operationId = 'scenarioCopy';
            }
            params.initiatorId = 'AppNavbarComponent' + new Date().getTime();
            this.initiatorId = params.initiatorId;

            this.cd.detectChanges();
            SatDialogService.openDialog.next(params);
          } catch (err) {
            this.logger.error("Error @ AppNavbarComponent: getSimilarGeoProjects() ->" + err);
          }
        });
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: initScenarioCopy() ->" + err);
    }
  }
  selectInvalidProj(project) {
    // AppNavbarComponent.selectInvalidProject.next(project);
  }
  logout() {
    try {
      this.traceLogger.trace("Logout Button");
      this.userService.logoutUser();
    } catch (err) {
      this.logger.error("Error @ AppNavbarComponent: logout() ->" + err);
    }
    }

  showHelp() {
      try {
          let dialogRef = this.dialog.open(HelpComponent, {
              //width: '250px',
              data: {  }
          });
          this.traceLogger.trace("Help Button");
      } catch (err) {
          this.logger.error("Error @ AppNavbarComponent: showHelp() ->" + err);
      }
  }
  addDummyPipeline(){
    this.traceLogger.trace("View Dummy Pipeline Category");
  }
  homePageClicked(){
    this.traceLogger.trace("Home Page");
  }
  notificationBtnClicked(){
    this.traceLogger.trace("Notification Button");
  }
  profileBtnClicked(){
    this.traceLogger.trace("Profile Button");
  }
}