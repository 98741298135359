import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { Logger } from '../Logger/logger.module';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable ,  throwError } from "rxjs";
import { APP_CONFIG } from "../config/app.config";
import { SessionErrorServiceService } from "./session-error-service.service";
import { MESSAGE_CONFIG } from "../config/message.config";

@Injectable()
export class HttpIntercepterConfig implements HttpInterceptor {    

    constructor(private logger: Logger, private sessionError : SessionErrorServiceService){ }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const regexSBUXDomain = new RegExp("/\.*starbucks\.*(com|net)\.*/");

        try {
             //Before the Request Making a Intercept
        if(regexSBUXDomain.test(request.url)) {
            request = this.addAuthenticationToken(request);
        }
        
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // this.logger.info('sat api call', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.statusText ? error.statusText : '',
                    status: error.status
                };
                this.logger.error("api call response error :", data);
                if (error.status !== 401) {
                    return throwError(error);
                }
                if(error.status == 401){
                   // Open logout Dialog if 401 response returned from api
                    data["isSessionTimeOut"] = true;
                    data["messages"] = MESSAGE_CONFIG.messages.sessionInvalidated;
                    data["headers"] =  MESSAGE_CONFIG.headers.sessionInvalidate;
                    this.sessionError.openDialog(data);
                    return throwError(error);
                }                
               
            })
        );
        } catch (error) {
            this.logger.error("Error @ HttpIntercepterConfig: intercept() ->"+ error);   
        }
       
      }

      addAuthenticationToken(request) {
        // Get access token 
        const accessToken = APP_CONFIG.token;

        try {
           // If access token is null this means that user is not logged in
        // And we return the original request
        if (APP_CONFIG.token == "") {
            return request;
        }

        this.setLastActiveTime();

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + accessToken
            }
        }); 
        } catch (error) {
            this.logger.error("Error @ HttpIntercepterConfig: addAuthenticationToken() ->"+ error);   
        }        
    }

    private setLastActiveTime() { 
        try {
            APP_CONFIG.lastActiveTime = new Date(); 
        } catch (error) {
            this.logger.error("Error @ HttpIntercepterConfig: addAuthenticationToken() ->"+ error); 
        }        
    } 
        
}