<div *ngIf="isMenu" style="display: inline-block">
  <button color="primary" class="sb-icon" mat-icon-button matTooltip="Color Picker" [matMenuTriggerFor]="menu">
    <mat-icon aria-label="">color_lens</mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before" class = "color-picker-menu">
    <div *ngFor="let color of colorArray" class="option-wrapper" [class.selected]="color.priority===selectedPriority">
      <option mat-menu-item [ngValue]="color.priority" [ngStyle]="{'background-color':color.colorCode}" (click)="emitSelectedColor(color)"
        class="color-palette-menu">
      </option>
    </div>
  </mat-menu>
</div>

<div *ngIf="!isMenu" class="no-menu-cpl">
  <mat-icon aria-label="" *ngIf="!projectDialog">color_lens</mat-icon>
  <span class="lbl-color-palette">{{label}}</span>
  <div class="pnl-colors" [class.width-max]="projectDialog">
    <div *ngFor="let color of colorArray" class="option-wrapper"  (click)="emitSelectedColor(color)">
      <span [ngStyle]="{'background-color':color.colorCode}" [class.selected]="color.priority===selectedPriority" class="color-palette-menu">
      </span>
    </div>
  </div>
</div>