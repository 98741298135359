export class ProjectHierarchy {
  value: string;
  rgn: string;
  mkt: string;
  miniMkt: string;
}

export class ProjectStoreLkp {
    geoType: string
    rgn: string;
    mktIds: number[];
    miniMktIds: number[];
    opsArea: string[];
}