import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HelpComponent } from './help.component';
import { FormsModule } from '@angular/forms';
import { HelpRoutingModule } from './help-routing.module';

@NgModule({
    imports: [MatIconModule, CommonModule, FormsModule, HelpRoutingModule],
    declarations: [HelpComponent],
    exports: [HelpComponent]
})
export class HelpModule { }
