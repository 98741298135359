<div class="card createProject col-sm-12">
    <div class="pe-0 ps-0 pt-0 pt-0">
        <div class="headerStyle">
            <span class="title-style" *ngIf="mode=='add' || mode=='share'">{{prjLabel.label.titleCreate}}</span>
            <span class="title-style" *ngIf="mode=='edit'">{{prjLabel.label.titleEdit}}</span>
            <span class="p-0 col-2 d-flex flex-row-reverse">
                <button class="btn header-btn d-flex justify-content-center"
                    [routerLink]="['/scenario/create', projectId]" *ngIf="isCreateScenarioVisible"
                    [style.color]="color.add">
                    <mat-icon style="font-size: 1.5rem;" aria-label="">add_circle</mat-icon>
                    {{prjLabel.label.addScnBtn}}
                </button>
            </span>

        </div>
        <div class="cnt projectForm">
            <div class="">
                <div class="">
                    <span class="label-heading" id="projectNameId">{{prjLabel.label.geoName}}</span>
                    <input id="projectNameInputId" class="input-form" type="text" placeholder=""
                        [(ngModel)]="projectModel.Name" (ngModelChange)="isNameErrorVisible=false" required
                        [(disabled)]="disable.name" maxlength="50" [satRegex]="nameMask" [allow]="false">
                </div>
                <div class="">
                    <label for="Description" class="">
                        <span class="label-heading">{{prjLabel.label.desc}}</span>
                    </label>
                    <textarea class="input-form" id="Description" rows="2" [(ngModel)]="projectModel.Comments"
                        [disabled]="disable.description" maxlength="2000"></textarea>
                </div>
                <div style="display: flex;">
                    <div class="" id="select" style="width:55%; height:470px;">
                        <span class="label-heading" id="geographySelectionId"
                            style="display: flex;">{{prjLabel.label.selectGeo}}</span>


                        <mat-select class="mt-2"
                            style="border: 1px solid black;width: 50%;padding-left: 5px;margin-top: 3px !important;"
                            *ngIf="mode=='add'" aria-label="Select an geography option"
                            (selectionChange)="showGeoSelectionOption($event.value)" disableOptionCentering
                            panelClass="geography-panel">
                            <mat-option class="ps-2" [value]="prjLabel.region.SD">{{prjLabel.label.sd}}</mat-option>
                            <mat-option class="ps-2" [value]="prjLabel.region.CO">{{prjLabel.label.co}}</mat-option>
                            <mat-option class="ps-2" [value]="prjLabel.region.LS">{{prjLabel.label.ls}}</mat-option>
                        </mat-select>

                        <!--Sd start-->
                        <span *ngIf="geographySelection == prjLabel.region.SD">
                            <div class="">
                                <div id="region-div">
                                    <label id="regionId" style="margin-left:10px" class="hierarchy-label"
                                        for="Region">{{prjLabel.label.rgn}}</label>
                                </div>
                                <div class="col-12" *ngIf="mode==='add'">
                                    <input id="regionInputId" type="text" matInput [formControl]="regionControl"
                                        [matAutocomplete]="auto"
                                        [readOnly]="mode=='edit'|| (dropdowns.regions && dropdowns.regions.length===1)"
                                        [ngClass]="{'readOnly': mode=='edit'|| mode=='share' || (dropdowns.regions && dropdowns.regions.length===1)}"
                                        style="width:488px;border: 1px solid black; margin-top: 3px;">
                                    <mat-autocomplete #auto="matAutocomplete"
                                        [displayWith]="displayFn.bind(this,'Region','SD')"
                                        (optionSelected)="storeDetails('SD')" class = "create-project-auto-complete">
                                        <mat-option *ngFor="let region of filteredHierachy" [value]="region" #matOption
                                            (click)="onRegionChange(region,'SD')">
                                            {{region.value}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-12" *ngIf="mode==='edit'|| mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        [ngModel]="projectModel.rgn">
                                </div>
                            </div>
                            <div class="" *ngIf="isMarketVisible">
                                <div class="" id="market-div">
                                    <label id="marketId" for="Market" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.mkt}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">
                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addMarketsId"
                                                style="cursor: pointer;transform: rotate(-270deg);"
                                                matTooltip="{{prjLabel.tooltips.addMkts}}" aria-label=""
                                                class="add-hierarchy" *ngIf="selectedRegion">
                                                play_arrow
                                            </mat-icon>
                                        </span>
                                        <mat-select [formControl]="marketsControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">

                                            <mat-select-trigger>
                                                <mat-label class="selectTwo" *ngIf="marketsControl?.value.length==2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectMulti" *ngIf="marketsControl?.value?.length>2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectSingle"
                                                    *ngIf="marketsControl?.value?.length==1">
                                                    {{marketsControl.value[0].value}}
                                                </mat-label>
                                                <mat-chip-listbox #chipList
                                                    [ngClass]="selectedMarkets.length>0 ? 'projectMatChip' : 'projectNoMatChip'">

                                                    <mat-chip-option id="marketsChipId" class="chipsColor"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="remove(market)" [color]="primary"
                                                        *ngFor="let market of marketsControl.value">
                                                        {{ market.value }}
                                                        <mat-icon id="marketsChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>
                                            <mat-option class="selectOptions" #allMarketSelected
                                                (click)="toggleAllSelectionMarket()" [value]="selectAllOption">Select
                                                All</mat-option>
                                            <mat-option *ngFor="let market of filteredMarkets1" [value]="market"
                                                (click)="toggleOneMarket()" class="selectOptions">{{market.value}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>


                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.mkt.length>1?'[Multiple]':projectModel.mkt[0]}}"
                                        [title]="projectModel?.mkt?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.mkt.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let market of projectModel.mkt"
                                            [selectable]="selectable" [color]="primary">
                                            {{market}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>
                            <div class="" *ngIf="isMiniMarketVisible">
                                <div class="" id="miniMarket-div">
                                    <label id="minimarketId" for="MiniMarket" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.miniMkt}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addMiniMarketsId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addMiniMkts}}" aria-label=""
                                                class="add-hierarchy">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="miniMarketsControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">

                                            <mat-select-trigger>
                                                <mat-label class="selectTwo"
                                                    *ngIf="miniMarketsControl?.value?.length==2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectMulti"
                                                    *ngIf="miniMarketsControl?.value?.length>2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectSingle"
                                                    *ngIf="miniMarketsControl?.value?.length==1">
                                                    {{miniMarketsControl.value[0].value}}
                                                </mat-label>
                                                <mat-chip-listbox #chipListMiniMarket
                                                    [ngClass]="miniMarketsControl?.value?.length>0 ? 'projectMatChip' : 'projectNoMatChip'">

                                                    <mat-chip-option id="minimarketsChipId" class="chipsColor"
                                                        *ngFor="let miniMarket of miniMarketsControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeMiniMarket(miniMarket)">
                                                        {{miniMarket.value}}
                                                        <mat-icon id="minimarketsChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>
                                            <mat-option class="selectOptions" #allMiniMarketSelected
                                                (click)="toggleAllSelectionMiniMarket()" [value]="selectAllOption">
                                                Select All</mat-option>
                                            <mat-option class="selectOptions"
                                                *ngFor="let minimarket of filteredMiniMarkets1" [value]="minimarket"
                                                (click)="toggleOneMiniMarket(allSelected)">{{minimarket.value}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>


                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share' ">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.miniMkt.length>1?'[Multiple]':projectModel.miniMkt[0]}}"
                                        [title]="projectModel?.miniMkt?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.miniMkt.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let miniMarket of projectModel.miniMkt"
                                            [selectable]="selectable" [color]="primary">
                                            {{miniMarket}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>

                            <div class="" *ngIf="isStoreVisible">
                                <div class="" id="store-div">
                                    <label id="storeId" for="Store" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.store}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addStoresId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addStores}}" aria-label=""
                                                class="add-hierarchy">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="storesControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">
                                            <mat-select-trigger>
                                                <mat-label class="store-selectSingle"
                                                    *ngIf="storesControl?.value?.length==1">
                                                    {{storesControl.value[0].value}}
                                                </mat-label>
                                                <mat-label class="store-selectMulti"
                                                    *ngIf="storesControl?.value?.length>1">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-chip-listbox #chipListStore
                                                    [ngClass]="storesControl?.value?.length>0 ? 'storeMatChip' : 'storeNoMatChip'">

                                                    <mat-chip-option id="storesChipId" class="chipsColor"
                                                        *ngFor="let store of storesControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeStore(store)">
                                                        {{store.value}}
                                                        <mat-icon id="storesChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>

                                            <mat-option *ngIf="filteredStores1?.length>0" class="selectOptions"
                                                #allStoreSelected (click)="toggleAllSelectionStore()"
                                                [value]="selectAllOption">Select All</mat-option>
                                            <mat-option class="selectOptions" *ngFor="let store of filteredStores1"
                                                [value]="store" (click)="toggleOneStore(allSelected)">{{store.value}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share' ">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.storeNms.length>1?'[Multiple]':projectModel.storeNms[0]}}"
                                        [title]="projectModel?.storeNms?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.storeNms.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let store of projectModel.storeNms"
                                            [selectable]="selectable" [color]="primary">
                                            {{store}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>

                        </span>
                        <!--Sd end-->

                        <!--CO start-->
                        <span *ngIf="geographySelection == prjLabel.region.CO">
                            <div class="">
                                <div class="" id="region-div">
                                    <label id="coRegionId" class="hierarchy-label" style="margin-left:10px"
                                        for="CORegion">{{prjLabel.label.coRgn}}</label>
                                </div>
                                <div class="col-12" *ngIf="mode==='add'">
                                    <input id="coRegionInputId" type="text" matInput [formControl]="regionCoControl"
                                        [matAutocomplete]="autoCo"
                                        [readOnly]="mode=='edit'|| (dropdowns.coRegions && dropdowns.coRegions.length===1)"
                                        [ngClass]="{'readOnly': mode=='edit'|| mode=='share' || (dropdowns.coRegions && dropdowns.coRegions.length===1)}"
                                        style="width:488px;border: 1px solid black; margin-top: 3px;">
                                    <mat-autocomplete #autoCo="matAutocomplete"
                                        [displayWith]="displayFn.bind(this,'coRegion','CO')"
                                        (optionSelected)="storeDetails('CO')" class = "create-project-auto-complete">
                                        <mat-option *ngFor="let coRegion of filteredHierachyCo" [value]="coRegion"
                                            (click)="onRegionChange(selectedCoRegion,'CO')">
                                            {{coRegion.value}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-12" *ngIf="mode==='edit'|| mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        [ngModel]="projectModel.rgn">
                                </div>
                            </div>
                            <div class="" *ngIf="isCoOpsAreaVisible">
                                <div class="" id="market-div">
                                    <label id="coAreaId" for="CoArea" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.coArea}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addCoAreaId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addCoAreas}}" aria-label=""
                                                class="add-hierarchy">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="coAreaControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">
                                            <mat-select-trigger>
                                                <mat-label class="selectTwo" *ngIf="coAreaControl?.value?.length==2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectMulti" *ngIf="coAreaControl?.value?.length>2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectSingle" *ngIf="coAreaControl?.value?.length==1">
                                                    {{coAreaControl.value[0].value}}
                                                </mat-label>
                                                <mat-chip-listbox #chipList
                                                    [ngClass]="coAreaControl?.value?.length>0 ? 'projectMatChip' : 'projectNoMatChip'">

                                                    <mat-chip-option id="coAreasChipId" class="chipsColor"
                                                        *ngFor="let coArea of coAreaControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeOpsArea('CO', coArea)" [color]="primary">
                                                        {{coArea.value}}
                                                        <mat-icon id="coAreasChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>
                                            <mat-option class="selectOptions" #allCoAreaSelected
                                                (click)="toggleAllSelectionCoArea()" [value]="selectAllOption">Select
                                                All</mat-option>
                                            <mat-option class="selectOptions" *ngFor="let coArea of filteredCoArea1"
                                                [value]="coArea" (click)="toggleOneCoArea(allSelected)">{{coArea.value}}
                                            </mat-option>

                                        </mat-select>

                                    </mat-form-field>
                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.coArea.length>1?'[Multiple]':projectModel.coArea[0]}}"
                                        [title]="projectModel?.coArea?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.coArea.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let coArea of projectModel.coArea"
                                            [selectable]="selectable" [color]="primary">
                                            {{coArea}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>
                            <div class="" *ngIf="isStoreVisible">
                                <div class="" id="store-div">
                                    <label id="storeId" for="Store" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.store}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addStoresId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addStores}}" aria-label=""
                                                class="add-hierarchy">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="storesControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">
                                            <mat-select-trigger>
                                                <mat-label class="store-selectSingle"
                                                    *ngIf="storesControl?.value?.length==1">
                                                    {{storesControl.value[0].value}}
                                                </mat-label>
                                                <mat-label class="store-selectMulti"
                                                    *ngIf="storesControl?.value?.length>1">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-chip-listbox #chipListStore
                                                    [ngClass]="storesControl?.value?.length>0 ? 'storeMatChip' : 'storeNoMatChip'">

                                                    <mat-chip-option id="storesChipId" class="chipsColor"
                                                        *ngFor="let store of storesControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeStore(store)">
                                                        {{store.value}}
                                                        <mat-icon id="storesChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>

                                            <mat-option *ngIf="filteredStores1?.length>0" class="selectOptions"
                                                #allStoreSelected (click)="toggleAllSelectionStore()"
                                                [value]="selectAllOption">Select All</mat-option>
                                            <mat-option class="selectOptions" *ngFor="let store of filteredStores1"
                                                [value]="store" (click)="toggleOneStore()">{{store.value}}</mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share' ">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.storeNms.length>1?'[Multiple]':projectModel.storeNms[0]}}"
                                        [title]="projectModel?.storeNms?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.storeNms.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let store of projectModel.storeNms"
                                            [selectable]="selectable" [color]="primary">
                                            {{store}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>
                        </span>
                        <!--CO end-->

                        <!--LS Start-->
                        <span *ngIf="geographySelection == prjLabel.region.LS">
                            <div class="">
                                <div class="" id="region-div">
                                    <label id="lsRegionId" class="hierarchy-label" style="margin-left:10px"
                                        for="LSRegion">{{prjLabel.label.lsRgn}}</label>
                                </div>
                                <div class="col-12" *ngIf="mode==='add'">
                                    <input type="text" matInput [formControl]="regionLsControl"
                                        [matAutocomplete]="autoLs"
                                        [readOnly]="mode=='edit'|| (dropdowns.lsRegions && dropdowns.lsRegions.length===1)"
                                        [ngClass]="{'readOnly': mode=='edit'|| mode=='share' || (dropdowns.lsRegions && dropdowns.lsRegions.length===1)}"
                                        style="width:488px;border: 1px solid black; margin-top: 3px;">
                                    <mat-autocomplete #autoLs="matAutocomplete"
                                        [displayWith]="displayFn.bind(this,'lsRegion','LS')"
                                        (optionSelected)="storeDetails('LS')" class = "create-project-auto-complete">
                                        <mat-option *ngFor="let lsRegion of filteredHierachyLs" [value]="lsRegion"
                                            (click)="onRegionChange(lsRegion,'LS')">
                                            {{lsRegion.value}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-12" *ngIf="mode==='edit'|| mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        [ngModel]="projectModel.rgn">
                                </div>
                            </div>
                            <div class="" *ngIf="isLsOpsAreaVisible">
                                <div class="" id="market-div">
                                    <label id="lsAreaId" for="LsArea" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.lsArea}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addLsAreaId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addLsAreas}}" aria-label=""
                                                class="add-hierarchy" (click)="onRegionChange(selectedLsRegion,'LS')"
                                                *ngIf="selectedLsRegion">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="lsAreasControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">

                                            <mat-select-trigger>
                                                <mat-label class="selectTwo" *ngIf="lsAreasControl?.value?.length==2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectMulti" *ngIf="lsAreasControl?.value?.length>2">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-label class="selectSingle"
                                                    *ngIf="lsAreasControl?.value?.length==1">
                                                    {{lsAreasControl.value[0].value}}
                                                </mat-label>
                                                <mat-chip-listbox #chipList
                                                    [ngClass]="lsAreasControl?.value?.length>0 ? 'projectMatChip' : 'projectNoMatChip'">

                                                    <mat-chip-option id="lsAreasChipId" class="chipsColor"
                                                        *ngFor="let lsArea of lsAreasControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeOpsArea('LS', lsArea)" [color]="primary">
                                                        {{lsArea.value}}
                                                        <mat-icon id="lsAreasChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>

                                            <mat-option class="selectOptions" #allLsAreaSelected
                                                (click)="toggleAllSelectionLsArea()" [value]="selectAllOption">Select
                                                All</mat-option>
                                            <mat-option class="selectOptions" *ngFor="let lsArea of filteredLsArea1"
                                                [value]="lsArea" (click)="toggleOneLsArea(allSelected)">{{lsArea.value}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share'">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.lsArea.length>1?'[Multiple]':projectModel.lsArea[0]}}"
                                        [title]="projectModel?.lsArea?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.lsArea.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let lsArea of projectModel.lsArea"
                                            [selectable]="selectable" [color]="primary">
                                            {{lsArea}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>
                            <div class="" *ngIf="isStoreVisible">
                                <div class="" id="store-div">
                                    <label id="storeId" for="Store" class="hierarchy-label"
                                        [class.for-multi-sel]="mode==='add'">{{prjLabel.label.store}}</label>
                                </div>
                                <div class="col-12 pnl-expando" *ngIf="mode==='add'">

                                    <mat-form-field class="no-padding" appearance="outline">
                                        <span style="float:right;cursor: pointer;">
                                            <mat-icon id="addStoresId"
                                                style="transform: rotate(-270deg);cursor: pointer;"
                                                matTooltip="{{prjLabel.tooltips.addStores}}" aria-label=""
                                                class="add-hierarchy" (click)="getStoresList('LS')">
                                                play_arrow
                                            </mat-icon>
                                        </span>

                                        <mat-select [formControl]="storesControl" multiple class="market-select"
                                            disableOptionCentering panelClass="market-option">
                                            <mat-select-trigger>
                                                <mat-label class="store-selectSingle"
                                                    *ngIf="storesControl?.value?.length==1">
                                                    {{storesControl.value[0].value}}
                                                </mat-label>
                                                <mat-label class="store-selectMulti"
                                                    *ngIf="storesControl?.value?.length>1">
                                                    [Multiple]
                                                </mat-label>
                                                <mat-chip-listbox #chipListStore
                                                    [ngClass]="storesControl?.value?.length>0 ? 'storeMatChip' : 'storeNoMatChip'">

                                                    <mat-chip-option id="storesChipId" class="chipsColor"
                                                        *ngFor="let store of storesControl.value"
                                                        [selectable]="selectable" [removable]="removable"
                                                        (removed)="removeStore(store)">
                                                        {{store.value}}
                                                        <mat-icon id="storesChipRemoveId" matChipRemove
                                                            class="chipRemove" *ngIf="removable">
                                                            cancel
                                                        </mat-icon>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </mat-select-trigger>

                                            <mat-option *ngIf="filteredStores1?.length>0" class="selectOptions"
                                                #allStoreSelected (click)="toggleAllSelectionStore()"
                                                [value]="selectAllOption">Select All</mat-option>
                                            <mat-option class="selectOptions" *ngFor="let store of filteredStores1"
                                                [value]="store" (click)="toggleOneStore(allSelected)">{{store.value}}
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div class="col-12" *ngIf="mode==='edit' || mode=='share' ">
                                    <input type="text" [readOnly]="true" class="readOnly edit-hierachy"
                                        value="{{projectModel.storeNms.length>1?'[Multiple]':projectModel.storeNms[0]}}"
                                        [title]="projectModel?.storeNms?.join(' | ')">
                                    <mat-chip-listbox
                                        [ngClass]="projectModel.storeNms.length>0 ? 'editChipList' : 'editNoChipList'">
                                        <mat-chip-option class="chipsColor" *ngFor="let store of projectModel.storeNms"
                                            [selectable]="selectable" [color]="primary">
                                            {{store}}
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </div>
                            </div>
                        </span>
                        <!--LS end-->

                    </div>
                    <div style="width:45%">
                        <label for="GeographyPreview" class="">
                            <span class="label-heading">{{prjLabel.label.geoPrev}}</span>
                        </label>
                        <div class="geography-div">
                            <app-project-map [storeList]="storeDetail" [storeData]="selectedStores"></app-project-map>
                        </div>
                    </div>
                </div>
                <div style="padding-top: 85px;" id="project">

                    <div>
                        <label for="tag" style="font-size: 14px;">
                            <span class="label-heading">{{prjLabel.label.tags}} </span>{{prjLabel.label.comma}}
                        </label>
                        <textarea class="input-form" id="tag" rows="2" [(ngModel)]="projectModel.tags"
                            [disabled]="disable.tags" maxlength="2000"></textarea>
                    </div>
                    <div style="display: table-caption; font-size: 14px;">
                        <app-color-picker id="projectCardColorId" [colorArray]="priorityColors"
                            (selectedColor)="onColorSelection($event)" [selectedPriority]="projectModel.pr"
                            [defaultPriority]="defaultPriority" [isMenu]="false" type="projectAdd"
                            label="Project Card Color" sortBy="priority">
                        </app-color-picker>
                    </div>
                    <div class="col-12 col-sm-10">

                    </div>
                </div>
            </div>
            <div class="pnl-ctrls">
                <button id="saveBtnId" (click)="save()" class="button-header-save">
                    {{prjLabel.label.saveBtn}}
                </button>
                <button id="addCancelBtnId" (click)="GoTo()" *ngIf="mode==='add' || mode=='share'"
                    class="button-header-cancel">
                    {{prjLabel.label.cancelBtn}}
                </button>
                <button *ngIf="mode==='edit'" class="button-header-cancel" (click)="GoTo()">
                    {{prjLabel.label.cancelBtn}}
                </button>
                <button [disabled]="projectModel.isShared" *ngIf="mode==='edit'"
                    (click)="initiateArchiveProject($event)" class="button-header-delete">
                    {{prjLabel.label.archiveBtn}}
                </button>
            </div>

        </div>
    </div>
</div>