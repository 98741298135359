import { Component, Inject,OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import {DialogComponent} from '../modal/modal.component';
import { Logger } from '../Logger/logger.module';

/**
 * Component for functionality related to help widget.
 */
@Component({
  selector: 'help-feature',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  // languageList: Array<any> = [];
  regionList: Array<any> = [];
  TrainingMaterialList: Array<any> = [];
  //showDialog: boolean = true;
  SDRCAtlas = null;
  //@Output()
  //helpWindowClose = new EventEmitter();

  /**
   * Constructor for help component.
   * 
   * @param helpService 
   * @param _logger 
   */
    constructor(public dialogRef: MatDialogRef<HelpComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _logger: Logger) {
  }

  /**
   * Method to initialize data on help widget.
   */
  ngOnInit() {
    try {
      // this.languageList = this.helpService.getLanguageList();
      
    } catch (err) {
      this._logger.error('Error @ HelpComponent: ngOnInit() -> ' + err);
    }
  }

  saveAndClose() {
      try {
          this.dialogRef.close();
      //this.showDialog = false;
      //this.helpWindowClose.next("closeHelpWindow");
    } catch (err) {
      this._logger.error('Error @ HelpComponent: close() -> ' + err);
    }
  }
}

