<div id="app-searchId">
  <form>
    <div id="searchControlsId">
      <input autocomplete="off" type="text" name="txtSearch" id="txtSearch" [placeholder]="searchHelpText" class="inputSearchStyle floatLeft" [(ngModel)]="txtSearch" [style.padding-right]="txtSearch && txtSearch.length > 0 ? '30px' : '2px'" (keyup)="keyup($event)" (keyup.enter)="frmSubmit('enter_key')" (ngModelChange)="getSuggestions()" />
      <button type="button" id="btnSearch" title="Search" (click)="frmSubmit('button_click')" class="floatLeft">
        <i class="fa fa-search icon-style"></i>
      </button>
      <button class="close-icon" type="reset" title="Clear" [style.display]="txtSearch && txtSearch.length > 0 ? 'block' : 'none'" (click)="txtSearch='';searchValue=null"></button>      
      <div class="clearBoth"></div>
    </div>
     <div id="dvError" [hidden]="isHideError">
      <div class="error-icon" id="imgError"></div>
      <div id="errMsg"> {{errMsg}} </div>
    </div>

    <div *ngIf="filteredList && filteredList.length > 0 && txtSearch && txtSearch.length >= 3" id="dvSuggestions" tabindex="1" (keyup)="focusChild($event)">
      <ul id="listSuggestions">
        <li (click)= "searchDialog()"><a style="color: blue;">SEE DETAILED RESULTS</a></li>
        <li *ngFor="let item of filteredList; let i = index"  [innerHTML]="getItem(item) | highlightSearch: itemText" (click)="onSelect(item)" tabindex="1" [attr.data-index]="i">
          <a>{{item.value }}</a>
        </li>
      </ul>
    </div>
  </form>
</div>
