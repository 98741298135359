import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, Optional } from '@angular/core';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '../config/app.config';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { Level } from './level';

/**
 * Logger options.
 * See {@link Logger}.
 *
 * level - How much detail you want to see in the logs, 0 being off, 1 being the less detailed, 5 being the most. Defaults to WARN.
 * global - Whether you want the created logger object to be exposed in the global scope. Defaults to true.
 * globalAs - The window's property name that will hold the logger object created. Defaults to 'logger'.
 * store - Whether you want the level config to be saved in the local storage so it doesn't get lost when you refresh. Defaults to false.
 * storeAs - The local storage key that will be used to save the level config if the store setting is true. Defaults to 'angular2.logger.level'.
 *
 * Created by Langley on 3/23/2016.
 *
 */
export class Options {
    level: Level;
    global: boolean;
    globalAs: string;
    store: boolean;
    storeAs: string;
    consoleLog: boolean;
    serverLog: boolean
}

// For browsers that don't implement the debug method, log will be used instead. Fixes #62.
const CONSOLE_DEBUG_METHOD = console['debug'] ? 'debug' : 'log';

// Temporal until https://github.com/angular/angular/issues/7344 gets fixed.
const DEFAULT_OPTIONS: Options = {
    level: Level.WARN,
    global: true,
    globalAs: 'logger',
    store: false,
    storeAs: 'atlas.logger.level',
    consoleLog: true,
    serverLog: false
};

@Injectable({
    providedIn: "root"
})
export class Logger implements OnDestroy {

    private _level: Level;
    private _globalAs: string;
    private _store: boolean;
    private _storeAs: string;
    private _consoleLog: boolean;
    private _serverLog: boolean;

    public Level: any = Level;

    constructor(private http: HttpClient, @Optional() options?: Options) {
        // Move this to the constructor definition when optional parameters are working with @Injectable: https://github.com/angular/angular/issues/7344
        let { level, global, globalAs, store, storeAs, consoleLog, serverLog } = Object.assign({}, DEFAULT_OPTIONS, options);
        this._level = level;
        this._globalAs = globalAs;
        this._storeAs = storeAs;
        this._consoleLog = consoleLog;
        this._serverLog = serverLog;
        global && this.global();
        if (store || this._loadLevel()) { this.store(); }
    }

    ngOnDestroy() {
        this._level = null;
        this._globalAs = null;
        this._store = null;
        this._storeAs = null;
        this._consoleLog = null;
        this._serverLog = null;
        this.Level = null;
    }
    private _loadLevel = (): Level => Level[sessionStorage.getItem(this._storeAs)];

    private _storeLevel(level: Level) { sessionStorage[this._storeAs] = level; }

    error(message?: any, ...optionalParams: any[]) {
        // this._level = Level.ERROR;
        this.isErrorEnabled() && this._consoleLog && console.error.apply(console, arguments);
        if (this.isErrorEnabled() && this._serverLog && APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
    }

    warn(message?: any, ...optionalParams: any[]) {
        // this._level = Level.WARN;
        this.isWarnEnabled() && this._consoleLog && console.warn.apply(console, arguments);
        if (this.isWarnEnabled() && this._serverLog && APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
    }

    info(message?: any, ...optionalParams: any[]) {
        // this._level = Level.INFO;
        this.isInfoEnabled() && this._consoleLog && console.info.apply(console, arguments);
        if (this.isInfoEnabled() && this._serverLog && APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
    }

    debug(message?: any, ...optionalParams: any[]) {
        // this._level = Level.DEBUG;
        this.isDebugEnabled() && this._consoleLog && (<any>console)[CONSOLE_DEBUG_METHOD].apply(console, arguments);
        if (this.isDebugEnabled() && this._serverLog && APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
    }

    log(message?: any, ...optionalParams: any[]) {
        //this._level = Level.LOG;
        this.isLogEnabled() && this._consoleLog && console.log.apply(console, arguments);
        if (this.isLogEnabled() && this._serverLog && APP_CONFIG.token.length > 1) { this.postLogToServer(arguments) }
    }

    global = () => (<any>window)[this._globalAs] = this;

    store(): Logger {

        this._store = true;
        let storedLevel = this._loadLevel();
        // if (storedLevel && storedLevel === APP_SETTINGS.LOG_LEVEL) { this._level = storedLevel; }
        if (storedLevel) { this._level = storedLevel; }
        else { this._storeLevel(this.level); }
        return this;

    }

    unstore(): Logger {
        this._store = false;
        sessionStorage.removeItem(this._storeAs);
        return this;
    }

    isErrorEnabled = (): boolean => this.level >= this.Level.ERROR;
    isWarnEnabled = (): boolean => this.level >= this.Level.WARN;
    isInfoEnabled = (): boolean => this.level >= this.Level.INFO;
    isDebugEnabled = (): boolean => this.level >= this.Level.DEBUG;
    isLogEnabled = (): boolean => this.level >= this.Level.LOG;

    get level(): Level { return this._level; }

    set level(level: Level) {
        this._store && this._storeLevel(level);
        this._level = this.Level[level];
    }

    /**
     * Method to post UI logs to server.
     * 
     * @param logArgs 
     */
    postLogToServer(logArgs) {
        try {
            logArgs[logArgs.length] = APP_CONFIG.username;
            let data = { level: this.Level[this._level], arguments: logArgs };
            //TODO: add service url for posting data to server
            let serviceUrl = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.logger;

            return this.http.post(serviceUrl, data).pipe(map((r: Response) => r ? r.json() : {})).subscribe();
        }
        catch (err) {
            console.log("Logger Service Error");
            console.log(err);
        }
    }

}
