import { Directive, OnDestroy, ElementRef, Renderer2 , Output, EventEmitter, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Logger } from '../../Logger/logger.module';
/**
 * Directive for special character validation.
 */
@Directive({
    selector: '[ngModel][satRegex]',
    host: {
        '(onchange)': 'onInputChange($event)',
        '(input)': 'onInputChange($event.target.value, true)'
    }
})
export class RegexMask implements OnDestroy {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
    @Input() allow: boolean = true;
    @Input() satRegex: RegExp = null;

    constructor(public model: NgControl, public ele: ElementRef, public render: Renderer2 , private logger: Logger) { }

    /**
     * Method to filter out special charaters.
     * 
     * @param event 
     * @param backspace 
     */
    onInputChange(event, backspace) {
        try {
            let position = this.ele.nativeElement.selectionStart;
            let value = event;
            if (this.satRegex && value) {
                if (this.allow) {
                    let matches = value.match(this.satRegex) || [];
                    value = matches.join("");
                }
                else {
                    value = value.replace(this.satRegex, "");
                }
            }

            this.render.setProperty(this.ele.nativeElement, 'value', value);
            position = value.length < event.length ? --position : position;
            this.ele.nativeElement.selectionEnd = position;
            if (event !== value) {
                this.ngModelChange.emit(this.ele.nativeElement.value);
            }
        }
        catch (err) {
            this.logger.error(err);
        }
    }

    /**
     * Method on destroy of component.
     */
    ngOnDestroy() {
        this.ngModelChange && this.ngModelChange.unsubscribe();
        this.onInputChange = null;
    }
}
