import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2  } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Logger } from './../Logger/logger.module';
import { MyCurrencyPipe } from './currency-formatter.pipe';

/**
 * Directive for Foramtting data in cureency format.
 */
@Directive({ selector: '[ngModel][myCurrencyFormatter]', providers: [NgModel] })
export class MyCurrencyFormatterDirective implements OnDestroy {
  private el: HTMLInputElement;

  @Input() myCurrencyFormatter: number;
  @Input() formatterPrefix: string = "";

  @Output() ngModelChange = new EventEmitter();
  isBlur: boolean = true;
  ngModelListener: any;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: MyCurrencyPipe,
    public render: Renderer2 ,
    private ngModel: NgModel,
    private logger: Logger
  ) {
    this.el = this.elementRef.nativeElement;
    this.ngModelListener = this.ngModel.valueChanges.subscribe((val) => {
      try {
        if (this.isBlur && [null, undefined].indexOf(val) < 0) {
          this.onBlur(val.toString());
        }
      } catch (err) {
        this.logger.error("Error @ MyCurrencyFormatterDirective: constructor() ->" + err);
      }
    });
  }

  ngOnDestroy() {
    try {
      this.ngModelChange && this.ngModelChange.unsubscribe();
      this.ngModelListener && this.ngModelListener.unsubscribe();
      this.onBlur = null;
      this.onFocus = null;
      this.el = null;
      this.myCurrencyFormatter = null;
    } catch (error) {
      this.logger.error("Error @ MyCurrencyFormatterDirective: ngOnDestroy() ->" + error);
    }
  }

  /**
   * Host Listener for focus on an input element.
   * 
   * @param value 
   */
  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    try {
      this.isBlur = false;
      if (this.formatterPrefix && value.startsWith(this.formatterPrefix)) {
        value = value.substring(this.formatterPrefix.length);
      }
      if (value !== null && value !== undefined && value.trim() !== "") {
        let updatedValue = this.currencyPipe.parse(value, this.myCurrencyFormatter); // opposite of transform
        this.render.setProperty(this.el, "value", updatedValue);
      }
      this.ngModelChange.emit(this.el.value);
    } catch (err) {
      this.logger.error("Error @ MyCurrencyFormatterDirective: onFocus() ->" + err);
    }
  }

  /**
   * Host Listener for focus lost aevent and format the input text in currency format.
   */
  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    try {
      let updatedValue;
      if (value !== null && value !== undefined && value.trim() !== "") {
        if (this.formatterPrefix && value.startsWith(this.formatterPrefix)) {
          value = value.substring(this.formatterPrefix.length);
          updatedValue = this.currencyPipe.parse(value, this.myCurrencyFormatter);
        } else if (value == "0") { updatedValue = "0" }
        else { updatedValue = this.currencyPipe.transform(value, this.myCurrencyFormatter); }
        this.render.setProperty(this.el, "value", this.formatterPrefix + updatedValue);
      }
      // this.ngModelChange.emit(this.el.value);
      this.isBlur = true;
    } catch (err) {
      this.logger.error("Error @ MyCurrencyFormatterDirective: onBlur() ->" + err);
    }
  }
}
