import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionErrorDialogComponent } from './session-error-dialog/session-error-dialog.component';
import { Logger } from '../Logger/logger.module';
import { SERVICE_CONFIG } from '../config/service.config';

@Injectable({
  providedIn: 'root'
})
export class SessionErrorServiceService {

  constructor(public dialog: MatDialog, private logger: Logger) { }

  public openDialog(data): void {
    const dialogRef = this.dialog.open(SessionErrorDialogComponent, {
      width: data.isSessionTimeOut ? '300px' : '400px',
      data: data
    });

    dialogRef.disableClose = true;//disable default close operation
    dialogRef.backdropClick().subscribe(result => {
      dialogRef.close(data);
    });

    dialogRef.afterClosed().subscribe(result => {
      try {
        // result == true ? window.location.assign(SERVICE_CONFIG.appUrl) : false;
        if(result)
          window.location.assign(SERVICE_CONFIG.appUrl);
      }
      catch (err) {
        this.logger.error(err);
      }
    });
  }

}
