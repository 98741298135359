  
import { APP_CONFIG } from "../config/app.config";

// models for Project info

export class ProjectModel {
  Id: string = "00000000-0000-0000-0000-000000000000";
  Name: string = "";
  rgn: string = null;
  geoType: string = null;
  mkt: string[] = [];
  miniMkt: string[] = [];
  stores: string[] = [];
  coArea: string[] = [];
  lsArea: string[] = [];
  Comments: string = "";
  Isactive: Date;
  updDt: Date;
  scnCnt: number;
  pr: number = APP_CONFIG.defaults.project.priority;
  isShared: boolean = false;
  isOwner: boolean;
  isSharedCreate: boolean = false;
  sharedScenarioId: number;
  hasExistingCopy = false;
  isDefault: boolean = false;
  invDate: Date = null;
  tags:string = null;
  Desc: string = null;
  projectStores:[];
}