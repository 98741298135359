import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumComponent } from './breadcrum.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, RouterModule, MatIconModule],
  declarations: [BreadcrumComponent],
  exports: [BreadcrumComponent]
})
export class BreadcrumModule { }
