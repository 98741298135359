import { Component, OnInit, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SearchService } from './search.service';
import { MESSAGE_CONFIG } from '../config/message.config';
import { Logger } from '../Logger/logger.module';
import { MatDialog } from '@angular/material/dialog';
import { EnhancedSearchComponent } from '../enhanced-search/enhanced-search.component';
import { TraceLoggerService } from '../Logger/trace-logger.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class SearchComponent implements OnInit, OnDestroy {
  searchHelpText: string = 'Find Project / Scenario Name...';
  txtSearch: string = '';
  itemText = "";
  isHideError: boolean;
  filteredList = [];
  errMsg = '';
  searchValue = null;

  @Output() onResult = new EventEmitter();

  constructor(public dialog : MatDialog,public myElement: ElementRef, private searchService: SearchService, private cd: ChangeDetectorRef, private logger: Logger, private traceLogger: TraceLoggerService) { }

  ngOnInit() {
    try {      
    this.isHideError = true;
    this.cd.detectChanges();
    } catch (error) {
      this.logger.error("Error @ SearchComponent: ngOnInit() ->"+ error);
    }
  }

  ngOnDestroy() {
    try {
    this.onResult && this.onResult.unsubscribe();
    this.keyup = null;
    this.frmSubmit = null;
    this.getSuggestions = null;
    this.focusChild = null;
    this.onSelect = null;
    } catch (error) {
      this.logger.error("Error @ SearchComponent: ngOnDestroy() ->"+ error);
    }    
  }

  frmSubmit(type: string) {
    try {
      if (this.txtSearch) {
        this.txtSearch = this.txtSearch.trim();
      }
      if (!this.txtSearch) {
        this.isHideError = false;
        this.errMsg = MESSAGE_CONFIG.errConfig.nullSearchValue;
      }
      else if (this.txtSearch.length < 3) {
        this.isHideError = false;
        this.errMsg = MESSAGE_CONFIG.errConfig.valueTooShort;
      }
      else {
        if (type != 'autosearch') {
          if (this.filteredList.length > 0) {
            this.txtSearch = this.filteredList[0].value;
            this.searchValue = this.filteredList[0];
            this.filteredList = [];
          }
        }

        if (this.searchValue) {
          this.onResult.next({
            isProject: this.searchValue.value.indexOf('Project: ') == 0,
            isScenario: this.searchValue.value.indexOf('Scenario: ') == 0,
            isTag: this.searchValue.value.indexOf('Tag: ') == 0,
            data: this.searchValue
          });
        }
        else {
          this.isHideError = false;
          this.errMsg = MESSAGE_CONFIG.errConfig.emptySearchResults;
        }
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ SearchComponent: frmSubmit() ->"+ err);
    }
  }
  focusChild(event) {
    try {
      if (event.key === 'ArrowDown' || event.key === 'Down') {
        let element = (document.activeElement.nextElementSibling as HTMLElement);

        if (element) {
          element.focus();
        } else {
          document.getElementById('txtSearch').focus();
        }
      }
      else if (event.key === 'ArrowUp' || event.key === 'Up') {
        let element = (document.activeElement.previousElementSibling as HTMLElement);

        if (element) {
          element.focus();
        } else {
          document.getElementById('txtSearch').focus();
        }
      }
      else if (event.key === 'Enter' || event.code === 'NumpadEnter') {
        let element = (document.activeElement as HTMLElement);
        if (element && element.children[0] && element.children[0].textContent) {
          let _val = this.filteredList[element.dataset["index"]];
          if (_val) this.onSelect(_val); // .trim());
        }
      }
    }
    catch (err) {
      this.logger.error("Error @ SearchComponent: focusChild() ->"+ err);
    }
  }
  onSelect(item) {
    try {
      this.txtSearch = item.value;
      this.searchValue = item;

      this.frmSubmit('autosearch');
      this.filteredList = [];

      this.cd.detectChanges();
    } catch (err) {
      this.logger.error("Error @ SearchComponent: onSelect() ->"+ err);
    }
  }
  private removeSuggestionClicks() {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.filteredList && this.filteredList.length > 0) {
          let elements = this.myElement.nativeElement.querySelectorAll('#listSuggestions > li');

          for (let i = 0; i < elements.length; i++) {
            elements[i].onclick = null;
            elements[i].click = null;
            elements[i].removeEventListener('click', this.onSelect);
          }
        }

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  keyup(event) {
    try {
      this.isHideError = true;

      if (event.key === 'Enter' || event.code === 'NumpadEnter' || event.key === 'ArrowLeft' || event.key === 'Left' || event.key === 'ArrowRight' || event.key === 'Right') return; /*-- To trigger FrmSubmit() --*/
      /*-- activate keyboard navigation on suggestions --*/
      if (event.key === 'ArrowDown' || event.key === 'Down' || event.key === 'ArrowUp' || event.key === 'Up') {
        let ele = document.getElementById('listSuggestions');
        if (ele) {
          let element = ele.firstElementChild as HTMLElement;
          if (element) {
            element.focus();
          }
        }
        return; /*-- avoid calling autoSearchService if user is navigating away from search box --*/
      }

    } catch (err) {
      this.logger.error("Error @ SearchComponent: keyup() ->"+ err);
    }
  }
  handleClick(event) {
    try {

      let clickedComponent = event.target;
      let inside = false;
      do {
        if (clickedComponent.id === 'dvSuggestions' || clickedComponent.id === 'btnSearch') {
          inside = true;
        }
        clickedComponent = clickedComponent.parentNode;
      }
      while (clickedComponent);
      if (!inside) {
        this.isHideError = true;
        this.removeSuggestionClicks().then(() => {
          this.filteredList = [];
          this.cd.detectChanges();
        }, (err) => {
          this.logger.error(err);
        });
      }
      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error("Error @ SearchComponent: handleClick() ->"+ err);
    }
  }

  getSuggestions() {
    try {
      this.isHideError = true;
      this.searchValue = null;

      if (this.txtSearch && this.txtSearch.length > 2) {
        if (this.txtSearch.indexOf('Project: ') == 0) {
          this.txtSearch = this.txtSearch.substring('Project: '.length);
        }
        else if (this.txtSearch.indexOf('Scenario: ') == 0) {
          this.txtSearch = this.txtSearch.substring('Scenario: '.length);
        }
        this.searchService.getSuggestions(this.txtSearch).then((data: any[]) => {
          try {
            if (data.length > 0) {
              this.filteredList = data;
              this.cd.detectChanges();
            }
            else {
              this.filteredList = [];
              this.cd.detectChanges();
            }
          } catch (err) {
            this.logger.error(err);
          }
        }, (err) => {
          this.logger.error(err);
        });
      }
    } catch (err) {
      this.logger.error("Error @ SearchComponent: getSuggestions() ->"+ err);
    }
  }

  getItem(item){
       if(item.value.indexOf("Project: ")>-1){
          this.itemText = "Project";
       }
       else if(item.value.indexOf("Scenario: ")>-1){
         this.itemText = "Scenario";
       }
       else if(item.value.indexOf("Tag: ")>-1){
         this.itemText = "Tag";
       }
       return item.value;
  }
  searchDialog(mode,id){
    try{
      this.traceLogger.trace("Detail Search Box");
      this.filteredList=[];
      this.dialog.open(EnhancedSearchComponent, {
        width:'1200px',
        height:'880px',
        data:{'txtSearch':this.txtSearch},
        panelClass:'create-search-dialog',
        disableClose: true
      })

    }
    catch(err) {
      this.logger.error("Error @ ProjectComponent: createProject() ->"+ err);

    }
  }
}
