<nav class="background-header">
    <div *ngIf="isEnvShow" class="atlasEnvText">
        {{displayText}}
    </div>
    <div [ngClass]="isEnvShow? 'container-fluid p-0 vertical-center_banner' : 'container-fluid p-0 vertical-center' ">


        <div class="leftDivLogo" (click) = "homePageClicked()">

            <img class="imgLogo" alt="Logo" src="assets/images/asb-new-logo.svg" [routerLink]="['project']" style="cursor: pointer">
            <span style="vertical-align:middle">
          <label [routerLink]="['project']" style="cursor: pointer; font-weight: bold;"  class="navHeader mb-0">{{labels.label.header}}</label>
        </span>

        </div>

        <div class="dvSearchBoxTop" [class.clsSmallScreen]="isShowSearch">
            <app-search class="customElement appSearch" (onResult)="onSearchResult($event)"></app-search>
        </div>

        <div class="px-0" class="rightBtn">
            <button mat-icon-button class="me-2. nav-link-color" [matMenuTriggerFor]="notificationMenu" (click) = "notificationBtnClicked()">
            <mat-icon  class="icon navIcon" matBadge="{{sharedScenarios?.length}}"
              [matBadgeHidden]="(sharedScenarios?.length) <= 0" matBadgeColor="warn">notifications</mat-icon>
          </button>
            <mat-menu #notificationMenu="matMenu" style="z-index: 1040;" class="navbar-component-my-full-width-menu">

                <mat-accordion (click)="$event.stopPropagation()">
                    <!-- <mat-expansion-panel *ngIf="invalidProjects?.length > 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <label class="text-bold">{{labels.label.invalidGeography}}</label>
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <a mat-menu-item *ngFor="let invalidProject of invalidProjects" [routerLink]="['/project/scenario', invalidProject.Id]" (click)="selectInvalidProj(invalidProject)">{{invalidProject.Name}}</a>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel *ngIf="sharedScenarios?.length > 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <label class="text-bold">{{labels.label.sharedScenarios}}</label>
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let scn of sharedScenarios">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{scn?.scenarioName}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="notification-details">
                                    {{labels.label.notificationDetails.sharedBy}} <span class="text-bold">{{scn.sharedByName}}</span> {{labels.label.notificationDetails.fromGeo}} <span class="text-bold">{{scn.projectName}}</span>{{labels.label.notificationDetails.cloneTo}}
                                    <a class="text-bold" href="javascript:void(0)" (click)="initScenarioCopy(scn?.scenarioId, scn?.projectId, true)">{{labels.label.notificationDetails.new}}
                      </a> {{labels.label.notificationDetails.or}} <a class="text-bold" href="javascript:void(0)" (click)="initScenarioCopy(scn?.scenarioId, scn?.projectId, false, scn?.scenarioName)">{{labels.label.notificationDetails.existing}}
                      </a> {{labels.label.notificationDetails.geo}}
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-option [disabled]="true" *ngIf="sharedScenarios?.length < 1">
                    {{labels.label.noNotification}}</mat-option>
            </mat-menu>


            <div class='txtUsername' [matMenuTriggerFor]="userLogin">{{username ? username : 'Guest'}}</div>
            <mat-menu #userLogin="matMenu" [overlapTrigger]="false">
                <button mat-menu-item class="navIconButton" (click) = "profileBtnClicked()">
              <mat-icon class="icon">account_circle</mat-icon> {{labels.label.profile}}
            </button>
                <button mat-menu-item [routerLink]="['pipeline']" class="navIconButton" (click) = "addDummyPipeline()">
              <mat-icon class="icon">add_circle</mat-icon> {{labels.label.createPipleline}}
            </button>
                <button mat-menu-item (click)="showHelp()" class="navIconButton">
              <mat-icon class="icon">help</mat-icon> {{labels.label.help}}
            </button>
                <button mat-menu-item (click)="logout()" class="navIconButton">
              <mat-icon class="icon">power_settings_new</mat-icon> {{labels.label.logout}}
            </button>
            </mat-menu>
        </div>
    </div>
</nav>