import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APP_CONFIG } from '../../config/app.config';
import { Logger } from '../../Logger/logger.module';
import { DomainHelperService } from '../../helpers/domain-helper.service';
import { ScenarioService } from '../../scenario/scenario.service';

@Component({
    selector: 'app-project-map',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './project-map.component.html',
    styleUrls: ['./project-map.component.css']
})
export class ProjectMapComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('storeContentMap', { read: ElementRef, static: true }) storeContentMap: ElementRef;
    //  @HostListener('window:click', ['$event'])
    @Input() storeScenarioDetail: any;
    @Input() selectedDecision: string;
    @Input() scenarioId: any;
    @Input() projectSelectedId: any;
    @Input() storeData: any;
    @Input() storeList: any;
    @ViewChild('gmap', { read: ElementRef, static: true }) gmapElement: ElementRef;
    @ViewChild('gmapOverlay', { read: ElementRef }) gmapOverlay: ElementRef;
    isExtentChangeRequired = true;
    markersList: any[] = [];
    _markerClst: MarkerClusterer;
    isClusterStores: boolean = false;
    overlayList: any[] = [];
    map: google.maps.Map;
    showLoader: boolean = false;
    storeIcon2: string;
    storeIcon1: string;
    storeDetails: any[];
    mapId = APP_CONFIG.googleMapBaseId;
    activeInfoWindow;
    constructor(private eRef: ElementRef, private domainHelperService: DomainHelperService, private logger: Logger, private scenarioService: ScenarioService, private snackBar: MatSnackBar, private cdRef: ChangeDetectorRef) {
    }

    detectInputChanges() {
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.addMap();
    }
    ngOnChanges() {
        this.initMapControls();
    }
    ngOnDestroy() {
        try {
            console.log("");
        } catch (error) {
            this.logger.error("Error @ StoreWindowMapComponent: ngOnDestroy() ->" + error);
        }
    }


    private initMapControls() {
        if (this.storeList && this.storeList.length > 0) {
            this.addStoreMarkers(this.storeList);
        } else {
            this.addMap();
        }

    }
    addMap() {
        try {
            let mapProp = {
                center: new google.maps.LatLng(49.505211, -94.800256),
                zoom: 3,
                // mapId:this.mapId,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);


            let _overlayMapService = (_mapSrvUrl: string) => { this.overlayMapService(_mapSrvUrl); };

            let hideloading = (scopehl) => {
                if (scopehl.gmapOverlay != undefined) { scopehl.gmapOverlay.nativeElement.style.visibility = "hidden"; }
            };
            let showloading = (scopesl) => {
                if (scopesl.gmapOverlay != undefined) { scopesl.gmapOverlay.nativeElement.style.visibility = "visible"; }
            };
            let scope = this;
            google.maps.event.addListener(this.map, 'tilesloaded', function (event) {
                hideloading(scope);
            });
            google.maps.event.addListener(this.map, 'dragend', function (event) {
                hideloading(scope);
            });
            google.maps.event.addListener(this.map, 'idle', function (event) {
                hideloading(scope);
            });
            google.maps.event.addListener(this.map, 'zoom_changed', function (event) {
                showloading(scope);
            });
            google.maps.event.addListener(this.map, 'center_changed', function (event) {
                showloading(scope);
            });

            this.scenarioService.setMapReference(this.map);
        }
        catch (err) {
            this.logger.error("Error @ StoreWindowMapComponent: addMap() ->" + err);
        }

    }

    addStoreMarkers(storeList: any[]) {
        try {
            this._removeMarkers(this.markersList);
            this.markersList = [];

            let minlat = null;
            let maxlat = null;
            let minlon = null;
            let maxlon = null;

            let index = 0;
            if (this.storeList && this.storeList.length > 0) {
                this.scenarioService.setStoreList(storeList);

                for (index = 0; index < storeList.length; index++) {
                    let store: any = storeList[index];
                    if (store.lat != null && store.lat != undefined && store.lon != null && store.lon != undefined) {
                        let latlon = new google.maps.LatLng(store.lat, store.lon);
                        var infoWnd = new google.maps.InfoWindow({
                            disableAutoPan: true
                        });
                        let storeIcon;
                    if (store.cat == 'Pipeline') {
                        storeIcon = APP_CONFIG.storeIcon[store.cat][store.ownership] ? APP_CONFIG.storeIcon[store.cat][store.ownership] : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABFBJREFUSImNlWtsU2UYx3/P6dqt7drNldsGjElwhIGTi50BRLdEgQjBqEH8YDSiCUE/QIyJAk2BpcpHjBeCUSOSTcNtupBgTIyCZMAY6xgEWAoFBu6CjnUrtGeM7bx+GKd22wF8vpyT53nP7/m///dyMrhPKKWygaVKqYVAvog4lFIdItIMHBSRjvt9a0aGBdSrlPoIWA84RSRVS3vfaRjGHhHZJCKX/xdcKTXznqpHHyJKROQ1YIVS6g0ROfBAuFKqBKgDckY0pLe3l0QySVZmJrm5udhsNrPsAvbda1BlCb/nb206WL9zh0NH/uB06xWaerq4agzg9+Yyf9xEHi8s4omZs3C5XACilPpGKXVeRMJW8A9EZJqZbO1oZ/vean7Wb9H5n+Vcisf4MR7DHT3HjptdPOd/Cp/Ph4hk6rpeBZQMgyulnMD7ZuKfnhifVO1iv9FHQrCMhFK82XyC3QLLF5XjcrlwOp0zGhoaPvb7/ZvSlT8PeEx/v6jdz8nBOyQE3imYzOLpM1Pe39KT7D4b5s9kAoC1zfVMzhvDfH8ZAG63+8NQKHQgEAiETeVPm1us9UYnFzrauCAKAP/EQlaULaB/YIC7A3dxZzlZOsfP9K+2k1CKhFI0X7tK2dx52Gw28vPzbYZhfAo8YyovMKdx/q9WDAsbIm3XWbzney6v38iYnFye9eZwqLcHgCPtV1nZ04PP58PpdAIsqqysLA8Gg4czRMSR8jvea+lx0bgJnHzrXbIcDnpv3+JI2rizidskdR0foGkaDD1fAQ5nKKVumLY4MuyW8MHBAW70xGhpu0ZV4wkSSqVqBWLDk50NgK7rABiGMWSLiJxJKRw7Dk0BI3bJ9ZtdLKyptmxcljeWnJyh4xGLxVI9Ycjzg4ACZNaUqcQzbJQPwmGxcn94TFAwZ8rU1J1z8eJFs5RpKu80DGOfiLzqcblYV7GYXb/9wjQFaxrqWNNQZwl2AyvdOSwrrwAgmUzS2NholjtM5YjIRsMwXtI0zb5krp8rXX/D6UYmKesZzFDCQoeLdateJ8uRCcDx48fp7+83h5xJh0fj8fh6j8fzpaZprF2ynJKCSez4/VdW6H0YaWugAfOnPsbby17E6x5ayEgkwrFjx9L7/5SCA3i93h1NTU2rS0tL52maRnnpHBbMmMWljjbau7u4OzBIXnY2xRMn84jHm6JEIhFqamrSwdftdnvNMDhAbW3tqmg0eq6ioiIzLy8Ph91OSWERJYVFo6zRdZ36+nqOHj06LK9p2sYNGzb0jYJv2bIlWllZubqlpaV69uzZFBcXM378eJxOJ5qmoes63d3dRKNRTp06RV9f38ieXwcCgdS9Puo3FwwGf9i6das3HA5/Hg6HR9UfEN/quv5eesLy482bN+8MhUJnDcP4DJj7EGg7EAgGg9+NLNxXWSAQqAOeDIVCLxiG8TKwgKGTZwc6gWZN02oTicTebdu2Ja0Y/wJxgrVpAT9DFgAAAABJRU5ErkJggg==";
                    } else {
                        storeIcon = APP_CONFIG.storeIcon[store.ownership][store.sType] ? APP_CONFIG.storeIcon[store.ownership][store.sType] : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAApxJREFUOI2VlF1Ik1EYx3/v3ldf0yIzMcVCKI2CupEMIgJZLKqLcIHWRRcJ3WSwZeTHgsy6cDemTalugpC+tNT1gVAMh12ViUZBURiRRiyzMi3njna2LqztnW6Y/7vz/M//x3N4Ho5GLNlIRLFYQVp11HyBzALQUX0C2Q+qm2SPGyczc6PaPJi90AqmepBrAQQybAlkHpAH8iB+8ztsnKDJ+yAeUNFt5joB1TG7nq9cFO7rNss50eSpBUJRQN1mdgqFqv+ERbpWZI1ut4SEy1MbAZYXFong4mFhKLIGu7kPl7dLw0ECflO98YI9bzupKcviAiYDU9S/eWwsKXoo2CCcPNLwW/aDXGd0Tx8qZ+XyFfGBU37qHVFAhGJaj89SpIEsWsTrAFBVlZfHmklPTeNmt5uTT9v/OtKqAVsWC0xK1ElZkszo2FfKrKU093cxNDOFrqgFGpC1WGBgWrC3+TjZSUvpPnudrWmrGRoZRCCz5i82IKWMVQ5LTAveip8c3rwTf2CKOyODYU8DfECuMfDl+ygZaelxgT8mxilelUfpngP0vX4eruuoPg0YmAt82NfDptyNcYFrsrJprbrEsO8jpzpdkc6RAxoh7qJQYgxU9Lazu6AwJjQwLWhou8zY5MTcXQRUt4bf20mKeQjIMVpHrp6hw+4iOyMys1/+SZw3mql75YnV+HtCHrfGFQQ2qlG4ZXR7J79RerGKXRu2hWvPPryIGkC0ghU0MT075SZvK3bzDqDMeMUz/glPb3usdJR0gueFq6cTjN9XptfOZ7MCHF2QYISZ1Aui0Vv57xwBOvgN3jLshU/A5ASyF2ANg1opGj1txuL8xXb1XMNR3EFgtIRgwj6Q+UAmsx/oCKj9hGbuMZ5zm5aWwNz4H89R8C5yKBNHAAAAAElFTkSuQmCC";
                    }
                        var mouseoverTimeoutId = null;
                        let marker: any = new google.maps.Marker({
                            position: latlon,
                            map: this.map,
                            //animation: google.maps.Animation.DROP,
                            icon: { url: storeIcon },
                          cursor:'pointer'
                        });
                        if (minlat === null || minlat > store.lat) { minlat = store.lat; }
                        if (minlon === null || minlon > store.lon) { minlon = store.lon; }
                        if (maxlat === null || maxlat < store.lat) { maxlat = store.lat; }
                        if (maxlon === null || maxlon < store.lon) { maxlon = store.lon; }

                        marker["id"] = index;

                        // this.addInfoWindow(marker, store);
                        this.markersList.push(marker);
                        google.maps.event.addListener(marker, 'mouseover', function () {
                            mouseoverTimeoutId = setTimeout(function () {
                                if(this.activeInfoWindow != null){
                                    this.activeInfoWindow.close();
                                   }
                                   let tooltipText;
                                   for(var i=0; i < storeList.length; i++){
                                       if(marker.id === i){
                                           tooltipText =  (storeList[i].cat != 'Pipeline' ? '#' + storeList[i].value : storeList[i].value);
                                           break;
                                       }
                                   }
                                   infoWnd.setContent('<div class="scrollFix">' + tooltipText + '</div>');
                                infoWnd.open(this.map, marker);
                                this.activeInfoWindow = infoWnd;
                            }, 500
                            );
                        });
                        google.maps.event.addListener(marker, 'mouseout', function () {
                            infoWnd.close();
                        });
                    }

                }
            }
            if (this._markerClst && this.isClusterStores) {
                this._markerClst.clearMarkers();
                this._markerClst.addMarkers(this.markersList);
            } else if (this.isClusterStores) {
                this._markerClst = new MarkerClusterer(this.map, this.markersList,
                    { imagePath: './assets/images/gMap/satClstr', imageExtension: 'svg' });
                this._markerClst.setStyles(this._markerClst.getStyles().map(function (style) {
                    style.textColor = '#fff';
                    return style;
                }));
            } else if (this._markerClst) {
                this._markerClst.clearMarkers();
            }

            let NECoord = new google.maps.LatLng(maxlat, maxlon);
            let SWCoord = new google.maps.LatLng(minlat, minlon);
            let bounds = new google.maps.LatLngBounds(SWCoord, NECoord);
            if (this.isExtentChangeRequired) { this.map.fitBounds(bounds); }
        }
        catch (err) {
            this.logger.error("Error @ EditScenarioComponent: addStoreMarkers() ->" + err);
        }
    }

    _removeMarkers(markerList: string | any[]) {
        try {
            for (let i = 0; i < markerList.length; i++) {
                markerList[i].setMap(null);
            }
        } catch (err) {
            this.logger.error("Error @ EditScenarioComponent: _removeMarkers() ->" + err);
        }
    }

    overlayMapService(_mapSrvUrl: string) { // currently hiding MiniMKt & it's Labels
        try {
            let bounds = this.map.getBounds();
            let ne = bounds.getNorthEast();
            let sw = bounds.getSouthWest();

            let exportUrl = _mapSrvUrl + '/export?bbox=' + sw.lng() + ',' + sw.lat() + ',' + ne.lng() + ',' + ne.lat() + '&bboxSR=4326&imageSR=4326&size='
                + this.map.getDiv()["offsetWidth"] + ',' + this.map.getDiv()["offsetHeight"] + '&dpi=96&format=png32&transparent=true&layers=show:99,96,92,101' +//,102,91' +
                '&f=image&token=' + APP_CONFIG._gisToken;

            while (this.overlayList[0]) this.overlayList.pop().setMap(null);
            this.overlayList.push(new google.maps.GroundOverlay(exportUrl, { north: ne.lat(), south: sw.lat(), east: ne.lng(), west: sw.lng() }, { map: this.map }));
        } catch (err) {
            this.logger.error("Error @ StoreWindowMapComponent: overlayMapService() ->" + err);
        }
    }

}