// Create Project Component
import { ENTER, TAB } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../../config/app.config';
import { MESSAGE_CONFIG } from '../../config/message.config';
import { DomainHelperService } from '../../helpers/domain-helper.service';
import { KeyValueModel } from '../../helpers/models.helper';
import { RouteStateService } from '../../helpers/route-state.service';
import { Logger } from '../../Logger/logger.module';
import { Breadcrumb } from '../../models/breadcrum.model';
import { DialogParams, DialogResultParams } from '../../sat-dialog/sat-dialog.model';
import { SatDialogService } from '../../sat-dialog/sat-dialog.service';
import { ScenarioService } from '../../scenario/scenario.service';
import { ProjectModel } from '../project.model';
import { ProjectService } from '../project.service';
import { ProjectStoreLkp } from '../project-hierarchy.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreModel } from '../../models/store.model';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProjectComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren("checkboxes") checkboxes: QueryList<any>;
  @Input() store: StoreModel;
  @Input() storeScenarioDetail: any;
  @Input() selectedDecision: string;
  @Input() scenarioId: any;
  @Input() projectSelectedId : any;
  // project data
  projectModel = new ProjectModel();
  // dropdown controls
  dropdowns: any = {};
  // disable controls
  disable: any = {};

  isCreateScenarioVisible = false;
  projectId = "";
  geographySelection: string;
  // create or edit
  mode: any = "";
  //@ViewChild('marketInput') marketInput;
  @ViewChild('allMarketSelected') private allMarketSelected: MatOption;
  @ViewChild('allMiniMarketSelected') private allMiniMarketSelected: MatOption;
  @ViewChild('allCoAreaSelected') private allCoAreaSelected: MatOption;
  @ViewChild('allStoreSelected') private allStoreSelected: MatOption;
  @ViewChild('allLsAreaSelected') private allLsAreaSelected: MatOption;
  @ViewChild('miniMarketInput') miniMarketInput;
//  @ViewChild('marketInput', { read: MatAutocompleteTrigger }) autoTrgrMkt: MatAutocompleteTrigger;
  // @ViewChild('miniMarketInput', { read: MatAutocompleteTrigger }) autoTrgrMinMkt: MatAutocompleteTrigger;
  @ViewChild('coAreaInput') coAreaInput;
  @ViewChild('lsAreaInput') lsAreaInput;
  @ViewChild('coAreaInput', { read: MatAutocompleteTrigger }) autoTrgrCoArea: MatAutocompleteTrigger;
  @ViewChild('lsAreaInput', { read: MatAutocompleteTrigger }) autoTrgrLsArea: MatAutocompleteTrigger;
  @ViewChild('storeInput') storeInput;
  @ViewChild('storeInput', { read: MatAutocompleteTrigger }) autoTrgrStore: MatAutocompleteTrigger;
  selectedStores: any[] = [];
  selectedMarkets: any[] = [];
  selectedMiniMarkets: any[] = [];
  selectedCoAreas: any[] = [];
  selectedLsAreas: any[] = [];
  separatorKeysCodes = [ENTER, TAB];
  // helpers and identifiers for confirmation box
  initiatorId: string = "";
  scenarioOnOperation: string = "";

  private sub: any = null;
  private subsCloseDialog: any = null;
  previousRoute: string;
  isNameErrorVisible: boolean = false;
  errMsgName: string;
  color: any = APP_CONFIG.color;
  scenarioColumns: any;
  searchHelpText: string;
  txtSearch = ""
  regionControl = new UntypedFormControl();
  marketControl = new UntypedFormControl();
  miniMarketControl = new UntypedFormControl();
  regionCoControl = new UntypedFormControl();
  regionLsControl = new UntypedFormControl();
  coAreaControl = new UntypedFormControl();
  lsAreaControl = new UntypedFormControl();
  storeControl = new UntypedFormControl();
  filteredHierachy: any[];
  filteredMarkets: Observable<any[]>;
  filteredMiniMarkets: Observable<any[]>;
  filteredStores: Observable<any[]>;
  filteredHierachyCo: any[];
  filteredCoArea: Observable<any[]>;
  filteredHierachyLs: any[];
  filteredLsArea: Observable<any[]>;
  public breadcrumbs: Breadcrumb[];
  add_box: boolean = false;
  public selectedRegion: KeyValueModel;
  public selectedCoRegion: KeyValueModel;
  public selectedLsRegion: KeyValueModel;

  selectedMarket: KeyValueModel;
  selectedCoArea: KeyValueModel;
  selectedLsArea: KeyValueModel;
  selectedMiniMkt: KeyValueModel;

  isMarketVisible: boolean = false;
  isMiniMarketVisible: boolean = false;
  isCoOpsAreaVisible: boolean = false;
  isLsOpsAreaVisible: boolean = false;
  isStoreVisible: boolean = false;

  isMarketChipsVisible: boolean = true;
  isCoAreaChipsVisible: boolean = true;
  isLsAreaChipsVisible: boolean = true;
  isMiniMarketChipsVisible: boolean = true;

  isStoreReadOnly = false;
  isMarketReadOnly = false;
  isMiniMarketReadOnly = false;
  isCoAreaReadOnly = false;
  isLsAreaReadOnly = false;
  priorityColors = APP_CONFIG.priorityColors;
  defaultPriority = APP_CONFIG.defaults.project.priority;
  projectOnOperation: string = "";
  removable = true;
  selectable = false;
  nameMask = /[,]/gi;
  addOnBlur = false;
  originalProj: ProjectModel;
  selectAllOption = { id: "Select All", value: "Select All" };
  prjLabel = APP_CONFIG.labels.createProject;

  static refreshSharedScn = new EventEmitter();
  subsRegionCtrl;
  subsRegionCoCtrl;
  subsRegionLsCtrl;
  pid: string;
  sid:any;
  storeDetail: any[];
  marketsControl = new UntypedFormControl([]);
  miniMarketsControl = new UntypedFormControl([]);
  storesControl = new UntypedFormControl([]);
  lsAreasControl = new UntypedFormControl([]);
  // coAreasControl = new UntypedFormControl([]);
  filteredMarkets1=[];
  filteredCoArea1=[];
  filteredMiniMarkets1: KeyValueModel[];
  filteredStores1=[];
  filteredLsArea1=[];
 // toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  constructor(private routingState: RouteStateService, private projectService: ProjectService, private route: ActivatedRoute, private router: Router, private domainHelperService: DomainHelperService, private snackBar: MatSnackBar, private cd: ChangeDetectorRef, private logger: Logger, private scenarioService: ScenarioService,public dialogRef: MatDialogRef<CreateProjectComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.mode = data.mode;

    // this.projectModel.Id = data.id;
    if(data.id){
      this.pid = data.id;
    }
    if(data.sid){
      this.sid = data.sid;
    }
  }
 
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  // component init lifecycle
  ngOnInit() {
    try {
      this.initSubscriptions();
      // keep track of previous page
      this.previousRoute = this.routingState.getPreviousUrl();
      this.breadcrumbs = this.routingState.getBreadCrumMap();

      // initialise subscriptions, events and listeners
      // this.initSubscriptions();

      // initialise UI controls
      this.initDropdowns();
      this.initDisable();
      this.scenarioColumns = APP_CONFIG.scenarioColDef;
    }
    catch (err) {
      this.logger.error("Error @ CreateProjectComponent: ngOnInit() ->"+ err);
    }
  }

  // component destroy lifecycle
  ngOnDestroy() {
    try {
      if (this.sub) {
        this.sub.unsubscribe();
        this.sub = null;
      }
      if (this.subsCloseDialog) {
        this.subsCloseDialog.unsubscribe();
        this.subsCloseDialog = null;
      }
      this.subsRegionCtrl && this.subsRegionCtrl.unsubscribe();
      this.subsRegionCoCtrl && this.subsRegionCoCtrl.unsubscribe();
      this.subsRegionLsCtrl && this.subsRegionLsCtrl.unsubscribe();
  
      this.disable = null;
      this.dropdowns = null;
      this.previousRoute = null;
      this.projectModel = null;
      this.cancel = null;
      this.GoTo = null;
      this.initiateArchiveProject = null;
      this.onMarketChange = null;
      this.onRegionChange = null;
      this.save = null;
      this.filteredHierachy = null;
      this.displayFn = null;
      this.remove = null;
      this.selected = null;
      this.isSelectedMarket = null;
      this.removeMiniMarket = null;
      this.selectedMiniMarket = null;
      this.isSelectedMiniMarket = null;
      this.onColorSelection = null; 

        this.isSelectedStore = null;
        this.getStoresList = null;
        this.removeStore = null;
        this.selectedStore = null;
        this.filteredStores = null;
    } catch (error) {
      this.logger.error("Error @ CreateProjectComponent: ngOnDestroy() ->"+ error); 
    }   
  }

  ngOnChanges(){
  }
  showGeoSelectionOption(value) {
    this.geographySelection = value;
    this.storeDetail = null;
    if(this.mode==='add'){
    this.storeDetails(value);
    this.storesControl.reset();
    // this.isStoreVisible = false;
    }
  }

  // navigate to page
  GoTo() {
    try {
      // if(this.mode === 'edit')
      // this.router.navigate(['/project']);

      this.dialogRef.close();
    }
    catch (err) {
      this.logger.error("Error @ CreateProjectComponent: GoTo() ->"+ err);
    }
  }
  // raise confirmation for Project delete
  initiateArchiveProject(event) {
    try {
      // initialise confirmation dialog params
      event.stopPropagation();
      let params = new DialogParams();
      params.headerText = MESSAGE_CONFIG.headers.archiveProject;
      params.messageText = MESSAGE_CONFIG.messages.archiveProject;
      params.isOkButton = true;
      params.okButtonText = "Yes";
      params.isCancelButton = true;
      params.cancelButtonText = "No";
      params.operationId = "archive";
      params.initiatorId = "ProjectComponent" + new Date().getTime();
      this.initiatorId = params.initiatorId;
      this.projectOnOperation = this.projectModel.Id;

      SatDialogService.openDialog.next(params);
      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error("Error @ CreateProjectComponent: initiateArchiveProject() ->"+ err);
    }
  }

  // create or update project
  save() {
    try {
      // project name required
      if (!this.projectModel.Name || this.projectModel.Name.trim() === "") {
        let params = new DialogParams();
        params.headerText = "Create Project";
        params.messageText = MESSAGE_CONFIG.errConfig.requireProjectName;
        params.isOkButton = true;
        params.isCancelButton = false;
        params.okButtonText = "OK";
        params.operationId = "save";
        params.initiatorId = "CreateProjectComponent" + new Date().getTime();
        params.error = true;
        SatDialogService.openDialog.next(params);
        return;
      }

      this.projectModel.stores = this.projectModel.stores ? this.projectModel.stores : [];
      this.projectModel.miniMkt = this.projectModel.miniMkt ? this.projectModel.miniMkt : [];
      this.projectModel.mkt = this.projectModel.mkt ? this.projectModel.mkt : [];

      // if mode is add, then create project
      if (this.mode == 'add') {
        // region mandatory
        if (!this.geographySelection || this.geographySelection.toString().trim() === "") {
          let params = new DialogParams();
          params.headerText = "Create Project";
          params.messageText = MESSAGE_CONFIG.errConfig.requiredGeoSelection;
          params.isOkButton = true;
          params.isCancelButton = false;
          params.okButtonText = "OK";
          params.operationId = "save";
          params.initiatorId = "CreateProjectComponent" + new Date().getTime();
          params.error = true;
          SatDialogService.openDialog.next(params);
        }
        else {
          let hierarchyValid = true;
          let regionIndex = -1;

          // Validate region        
          let region = this.geographySelection == 'SD' ? this.regionControl.value :
            this.geographySelection == 'CO' ? this.regionCoControl.value :
              this.regionLsControl.value;

          if (region != null) {
            this.projectModel.rgn = region.value ? region.value : region;
            regionIndex = this.geographySelection == 'SD' ? this.dropdowns.regions.findIndex(x => x.value.toLowerCase() === this.projectModel.rgn.toLowerCase()) :
              this.geographySelection == 'CO' ? this.dropdowns.coRegions.findIndex(x => x.value.toLowerCase() === this.projectModel.rgn.toLowerCase()) :
                this.dropdowns.lsRegions.findIndex(x => x.value.toLowerCase() === this.projectModel.rgn.toLowerCase());
          }

          if (regionIndex <= -1) {
            hierarchyValid = false;
            let params = new DialogParams();
            params.headerText = "Create Project";
            params.messageText = MESSAGE_CONFIG.errConfig.invalidRegion;
            params.isOkButton = true;
            params.isCancelButton = false;
            params.okButtonText = "OK";
            params.operationId = "save";
            params.initiatorId = "CreateProjectComponent" + new Date().getTime();
            params.error = true;
            SatDialogService.openDialog.next(params);
          }

          if (hierarchyValid) {
            // create project through service
            this.projectModel.Name = this.projectModel.Name.trim();
            if (this.geographySelection == 'SD') {
              this.projectModel.mkt = this.getSelectedMarkets();
              this.projectModel.miniMkt = this.getSelectedMinimarkets();
              this.projectModel.coArea = []; this.projectModel.lsArea = [];
              this.projectModel.geoType = 'SD';
            } else if (this.geographySelection == 'CO') {
              this.projectModel.geoType = 'CO_OPS';
              this.projectModel.coArea = this.getSelectedOpsArea("CO");
              this.projectModel.mkt = [];this.projectModel.miniMkt = [];this.projectModel.lsArea = [];
            } else {
              this.projectModel.geoType = 'LS_OPS';
              this.projectModel.lsArea = this.getSelectedOpsArea("LS");
              this.projectModel.mkt = [];this.projectModel.miniMkt = [];this.projectModel.coArea = [];
            } 
            this.projectModel.stores = this.getSelectedStores();
            this.createProject();
          }

        }
      }
      else if (this.mode == "edit") {
        if (this.isUpdate()) {
          this.projectModel.Name = this.projectModel.Name.trim();
          this.projectModel.projectStores = [];
          this.projectService.updateProjectById(this.projectModel.Id, this.projectModel).then((data: any) => {
            try {
              // broadcast project updated
              let options = { doEmit: true, selectDefault: false, goto: true}
              this.projectService.projectsUpdated.next(options);
              // this.router.navigate(['/project/scenario', this.projectModel.Id]);
              this.router.navigate(['/project']);
              this.dialogRef.close();
              this.cd.detectChanges();
            }
            catch (err) {
              this.logger.error(err);
            }
          }, (err) => {
            try {
              let params = new DialogParams();
              params.headerText = "Edit Project";
              if (err.status === 401) {
                params.messageText = MESSAGE_CONFIG.errConfig.UnAuthorizedEditInRegion;
              }
              else if (err.error == "Duplicate Project Name") {
                params.messageText = err.error;
              }
              else {
                params.messageText = MESSAGE_CONFIG.errConfig.somethingWrongTryAgain;
              }
              params.isOkButton = false;
              params.isCancelButton = true;
              params.cancelButtonText = "OK";
              params.operationId = "update";
              params.initiatorId = "CreateProjectComponent" + new Date().getTime();
              params.error = true;
              SatDialogService.openDialog.next(params);
              this.logger.error(err);
              this.cd.detectChanges();
            }
            catch (error) {
              this.logger.error(error);
            }
          });
        }
        else{
          this.dialogRef.close();
        }
        // this.router.navigate(['/project/scenario', this.projectModel.Id]);
        // this.dialogRef.close();
      }
      else if (this.mode == "share") {
        this.createProject();
      }

    } catch (err) {
      this.logger.error("Error @ CreateProjectComponent: save() ->"+ err);
    }

  }

  createProject() {
    try {
      this.projectService.createProject(this.projectModel).then((data: any) => {
        try {         
          this.projectModel.Id = data.projectId;
          this.scenarioService.setSelectedProject(this.projectModel);
          let options = {doEmit: true, selectDefault: false, goto: false}
          this.projectService.projectsUpdated.next(options);
          if (this.projectModel.isSharedCreate) {
            CreateProjectComponent.refreshSharedScn.next(true);
          }
          this.router.navigate(['/project/scenario', data.projectId,'new']);
          // this.router.navigate(['/project/scenario', data.projectId,0]);
          // CreateProjectComponent.refreshSharedScn.next(true);
          this.dialogRef.close();
          this.cd.detectChanges();
        }
        catch (err) {
          this.logger.error("Error @ CreateProjectComponent: createProject() ->"+ err);
        }
      }, (err) => {
        try {
          let params = new DialogParams();
          params.headerText = "Create Project";
          if (err.status === 401) {
            params.messageText = MESSAGE_CONFIG.errConfig.UnAuthorizedEditInRegion;
          }
          else if (err.error == "Duplicate Project Name") {
            params.messageText = err.error;
          }
          else {
            params.messageText = MESSAGE_CONFIG.errConfig.somethingWrongTryAgain;
          }
          params.isOkButton = true;
          params.isCancelButton = false;
          params.okButtonText = "OK";
          params.operationId = "save";
          params.initiatorId = "CreateProjectComponent" + new Date().getTime();
          params.error = true;
          SatDialogService.openDialog.next(params);
          this.logger.error(err);
          this.cd.detectChanges();
        }
        catch (error) {
          this.logger.error("Error @ CreateProjectComponent: createProject() ->"+ error);
        }
      });
    } catch (err) {
      this.logger.error("Error @ CreateProjectComponent: createProject() ->"+ err);
    }
  }

  // cancel - clear data
  cancel() {
    try {
      this.projectModel = new ProjectModel();

      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error("Error @ CreateProjectComponent: cancel() ->"+ err);
    }
  }

  // region change event
  // - clear hierarchy if required
  // - load ops areas, markets based on region
  onRegionChange(region, geoType: string, isClear = true) {
    try {
      if (geoType == 'SD') {
        if (isClear) {
          this.projectModel.mkt = null;
          this.projectModel.miniMkt = null;
          this.marketControl.setValue("");
        }
        if (region) {
          // get markets based on region
          this.domainHelperService.getMarketsByRegions([region.id]).then((data: KeyValueModel[]) => {
            try {
              if (data && data.length === 1) {
                this.selectedMarket = data[0];
                this.marketControl.setValue(this.selectedMarket.value);
                this.isMarketVisible = true;
                this.isMarketReadOnly = true;
              } else {
                this.isMarketReadOnly = false;
              }
              this.dropdowns.markets = data;
              this.filteredMarkets1 =data;
             // this.filteredMarkets1.splice(0,0,this.selectAllOption);
              this.cd.detectChanges();
         //     this.marketControl.reset("");
        //      this.marketInput.nativeElement.value = '';
              setTimeout(() => {
           //     this.autoTrgrMkt.openPanel();
        //        this.marketControl.setValue(this.marketInput.nativeElement.value);
         //       this.marketInput.nativeElement.focus();
              });
              this.cd.detectChanges();
            } catch (err) {
              this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
            }
          }, (err) => {
            this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
          });
        }
      }
      if (geoType == 'CO') {
        if (isClear) {
          this.projectModel.coArea = null;
          // this.coAreaControl.setValue("");
        }
        if (region) {
          // get markets based on region
          this.domainHelperService.getOpsAreaByOpsRegions(geoType, [region.id]).then((data: KeyValueModel[]) => {
            try {
              if (data && data.length === 1) {
                this.selectedCoArea = data[0];
                this.coAreaControl.setValue(this.selectedCoArea.value);
                this.isCoOpsAreaVisible = true;
                this.isCoAreaReadOnly = true;
              } else {
                this.isCoAreaReadOnly = false;
              }
              this.dropdowns.coAreas = data;
              this.filteredCoArea1 =data;
              // this.coAreaControl.reset("");
              // this.coAreaInput.nativeElement.value = '';
              // setTimeout(() => {
                // this.autoTrgrCoArea.openPanel();
                // this.coAreaControl.setValue(this.coAreaInput.nativeElement.value);
                // this.coAreaInput.nativeElement.focus();
              // });
              this.cd.detectChanges();
            } catch (err) {
              this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
            }
          }, (err) => {
            this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
          });
        }
      }
      if (geoType == 'LS') {
        if (isClear) {
          this.projectModel.lsArea = null;
          this.lsAreaControl.setValue("");
        }
        if (region) {
          // get markets based on region
          this.domainHelperService.getOpsAreaByOpsRegions(geoType, [region.id]).then((data: KeyValueModel[]) => {
            try {
              if (data && data.length === 1) {
                this.selectedLsArea = data[0];
                this.lsAreaControl.setValue(this.selectedLsArea.value);
                this.isLsOpsAreaVisible = true;
                this.isLsAreaReadOnly = true;
              } else {
                this.isLsAreaReadOnly = false;
              }
              this.dropdowns.lsAreas = data;
              this.filteredLsArea1 =data;
              // this.lsAreaControl.reset("");
              // this.lsAreaInput.nativeElement.value = '';
              // setTimeout(() => {
              //   this.autoTrgrLsArea.openPanel();
              //   this.lsAreaControl.setValue(this.lsAreaInput.nativeElement.value);
              //   this.lsAreaInput.nativeElement.focus();
              // });
              this.cd.detectChanges();
            } catch (err) {
              this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
            }
          }, (err) => {
            this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
          });
        }
      }

      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error("Error @ CreateProjectComponent: onRegionChange() ->"+ err);
    }
  }

  // market value change event
  // - clear hierarchy if required
  // - load minimarkets based on market
  onMarketChange(selectedmarkets, isClear = true) {
    try {
      // clear hierarchy
      if (isClear) {
        this.projectModel.miniMkt = null;
        this.isStoreVisible = false;
        this.storesControl.reset();
        this.miniMarketsControl.reset();
      }
      if (this.marketsControl.value && this.marketsControl.value.length>0) {

        let marketsIds = [];
        // let isAll = selectedmarkets && selectedmarkets[0].id == this.selectAllOption.id;
        // if (isAll) {
          selectedmarkets = this.marketsControl.value;
          // selectedmarkets = selectedmarkets.filter(item => item.id !== this.selectAllOption.id)
        // }
        for (let market of selectedmarkets) { marketsIds.push(market.id); }
    /*  if (selectedmarkets) {

        let marketsIds = [];
        let isAll = selectedmarkets && selectedmarkets[0].id == this.selectAllOption.id;
        if (isAll) {
          selectedmarkets = this.dropdowns.markets;
        }
        for (let market of selectedmarkets) { marketsIds.push(market.id); }*/

        // get minimarkets based on market
        this.domainHelperService.getMinimarketsByMarkets(marketsIds).then((data: KeyValueModel[]) => {
          try {
            if (data && data.length === 1) {
              this.isMiniMarketVisible = true;
              this.isMiniMarketReadOnly = true;
              this.isMarketChipsVisible = false;
              this.miniMarketControl.setValue(data[0].value);
              this.selectedMiniMarkets.push(data[0]);
              this.filteredMiniMarkets1 = data;
            } else {
              this.isMiniMarketVisible = true
              this.isMarketChipsVisible = true;
              this.isMiniMarketReadOnly = false;
              this.dropdowns.minimarkets = data;
              this.filteredMiniMarkets1 = data;
              // this.miniMarketControl.reset("");
              // this.miniMarketInput.nativeElement.value = '';
            }
            // setTimeout(() => {
              // this.autoTrgrMinMkt.openPanel();
              // this.miniMarketControl.setValue(this.miniMarketInput.nativeElement.value);
              // this.miniMarketInput.nativeElement.focus();
            // });
            this.cd.detectChanges();
          } catch (err) {
            this.logger.error(err);
          }
        }, (err) => {
          this.logger.error(err);
        });
      }
      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error(err);
    }
  }

    // - clear hierarchy if required
    // - load stores based on mini market/ ops area
    getStoresList(geoType: string) {
        try {
            this.projectModel.stores = [];
            this.storesControl.reset();
            let _postData = new ProjectStoreLkp();
            _postData.geoType = geoType == 'CO' ? 'CO_OPS' : geoType == 'LS' ? 'LS_OPS' : geoType;
            _postData.rgn = this.projectModel.rgn;
            if (geoType == 'SD' && this.miniMarketsControl) {
              let miniMarketSelected;
              miniMarketSelected = this.miniMarketsControl.value;
              if(this.miniMarketsControl.value[this.miniMarketsControl.value.length-1].id =='Select All')
              {
                miniMarketSelected.pop();
              }
              let marketSelected;
              marketSelected = this.marketsControl.value;
              if(this.marketsControl.value[this.marketsControl.value.length-1].id =='Select All')
              {
                marketSelected.pop();
              }
                _postData.mktIds = marketSelected.map(v => { return v.id });
                _postData.miniMktIds = miniMarketSelected.map(v => { return v.id });
            }
            else if (geoType == 'CO' && this.coAreaControl) {
              let CoAreaSelected;
              CoAreaSelected = this.coAreaControl.value;
              if(this.coAreaControl.value[this.coAreaControl.value.length-1].id =='Select All')
              {
                CoAreaSelected.pop();
              }
                _postData.opsArea = CoAreaSelected.map(v => { return v.id });
            }
            else if (geoType == 'LS' && this.lsAreasControl) {
              let LsAreaSelected;
              LsAreaSelected = this.lsAreasControl.value;
              if(this.lsAreasControl.value[this.lsAreasControl.value.length-1].id =='Select All')
              {
                LsAreaSelected.pop();
              }
                _postData.opsArea = this.lsAreasControl.value.map(v => { return v.id });
            }
            
                // get stores based on mini-market
                this.domainHelperService.getStoresByMiniMarkets(_postData).then((data: KeyValueModel[]) => {
                    try {
                        if (data && data.length === 1) {
                            this.isStoreVisible = true;
                            this.isStoreReadOnly = true;
                            if (geoType == 'SD') this.isMiniMarketChipsVisible = false;
                            else if (geoType == 'CO') this.isCoAreaChipsVisible = false;
                            else this.isLsAreaChipsVisible = false;
                            this.storeControl.setValue(data[0].value);
                            this.selectedStores.push(data[0]);
                        } else {
                            if (geoType == 'SD'){ this.isMiniMarketChipsVisible = true; this.isStoreVisible = true;}
                            else if (geoType == 'CO') {this.isCoAreaChipsVisible = true; this.isStoreVisible = true;}
                            else{ this.isLsAreaChipsVisible = true; this.isStoreVisible = true;}
                            this.isStoreReadOnly = false;
                            this.dropdowns.stores = data;
                            this.filteredStores1 = data;
                            // this.storeControl.reset("");
                            // this.storeInput.nativeElement.value = '';
                        }
                        // setTimeout(() => {
                        //   this.autoTrgrStore.openPanel();
                        //   this.storeControl.setValue(this.storeInput.nativeElement.value);
                        //   this.storeInput.nativeElement.focus();
                        // });
                        this.cd.detectChanges();
                    } catch (err) {
                        this.logger.error(err);
                    }
                }, (err) => {
                    this.logger.error(err);
                });
            this.cd.detectChanges();
        }
        catch (err) {
            this.logger.error(err);
        }
    }

    storeDetails(geoType: string){
      try {
        
        this.storeControl.setValue("");
        let _postData = new ProjectStoreLkp();
        _postData.miniMktIds=[];
        _postData.mktIds=[];
        _postData.opsArea=[];
        _postData.geoType = geoType == 'CO' ? 'CO_OPS' : geoType == 'LS' ? 'LS_OPS' : geoType;
        if(this.mode == "add"){
          this.projectModel.stores = [];
        if(geoType === 'CO'){
          if(this.selectedCoRegion){
            _postData.rgn = this.selectedCoRegion.value;
          }else{
            this.storeDetail = null;
          }       
        }
        else if(geoType==='SD'){
          if(this.selectedRegion){
            _postData.rgn = this.selectedRegion.value;
          }else{
            this.storeDetail = null;
          }
        }
        else{
          if(this.selectedLsRegion){
            _postData.rgn = this.selectedLsRegion.value;
          }else{
            this.storeDetail = null;
          }         
        }
      }
      else{
        _postData.rgn = this.projectModel.rgn;
      }
        if (geoType == 'SD' && this.marketsControl.value && this.marketsControl.value.length>0) {
            _postData.mktIds = this.marketsControl.value.map(v => { return v.id });
                if(this.miniMarketsControl.value && this.miniMarketsControl.value.length>0) {
            _postData.miniMktIds = this.miniMarketsControl.value.map(v => { return v.id });
                } else {
                  this.isStoreVisible = false;
                }
        }
        else if (geoType == 'CO' && this.coAreaControl.value && this.coAreaControl.value.length>0) {
            _postData.opsArea = this.coAreaControl.value.map(v => { return v.id });
        }
        else if (geoType == 'LS' && this.lsAreaControl.value && this.lsAreaControl.value.length>0) {
            _postData.opsArea = this.lsAreaControl.value.map(v => { return v.id });
        }

        if(_postData.rgn){
      this.domainHelperService.getStores(_postData).subscribe((data: KeyValueModel[]) => {
        try {
            if(data && data.length >0) {
              this.storeDetail = data;
              this.filteredStores1 = data;
            } else {
              this.storeDetail = null;
              this.isStoreVisible = false;
            }
            this.cd.detectChanges();
        } catch (err) {
            this.logger.error(err);
        }
    }, (err) => {
        this.logger.error(err);
    });
  }
    this.cd.detectChanges();
        }
        catch (err) {
            this.logger.error(err);
        }
    }


  // initialise dropdown values
  private initDropdowns() {
    try {

      //SD 
      this.dropdowns.regions = [];
      this.dropdowns.markets = [];
      this.dropdowns.minimarkets = [];
      //CO
      this.dropdowns.coRegions = [];
      this.dropdowns.coAreas = [];
      //LS
      this.dropdowns.lsRegions = [];
      this.dropdowns.lsAreas = [];
      this.dropdowns.stores = [];
      this.domainHelperService.getRegions().then((data: KeyValueModel[]) => {
        try {
          this.dropdowns.regions = data;
          this.filteredHierachy = data;
          if (data && data.length == 1) {
            this.selectedRegion = data[0];
            this.regionControl.setValue(data[0].value);
            this.isMarketVisible = true;
            this.onRegionChange(this.selectedRegion,'SD');
          }
        }
        catch (err) {
          this.logger.error(err);
        }
      }, (err) => {
        this.logger.error(err);
      });

      this.domainHelperService.getOpsRegions(this.prjLabel.region.LS).then((data: KeyValueModel[]) => {
        try {
          this.dropdowns.lsRegions = data;
          this.filteredHierachyLs = data;
          if (data && data.length == 1) {
            this.selectedLsRegion = data[0];
            this.regionLsControl.setValue(data[0].value);
            this.isLsOpsAreaVisible = true;
            this.onRegionChange(this.selectedLsRegion,'LS')
          }
        }
        catch (err) {
          this.logger.error(err);
        }finally{
          this.cd.detectChanges();
        }
      }, (err) => {
        this.logger.error(err);
      });

      this.domainHelperService.getOpsRegions(this.prjLabel.region.CO).then((data: KeyValueModel[]) => {
        try {
          this.dropdowns.coRegions = data;
          this.filteredHierachyCo = data;
          if (data && data.length == 1) {
            this.selectedCoRegion = data[0];
            this.regionCoControl.setValue(data[0].value);
            this.isCoOpsAreaVisible = true;
            this.onRegionChange(this.selectedCoRegion,'CO')
          }
        }
        catch (err) {
          this.logger.error(err);
        }
      }, (err) => {
        this.logger.error(err);
      });

     
    }
    catch (err) {
      this.logger.error(err);
    }
  }
  // initialise disable params for controls
  private initDisable() {
    try {

      this.disable.name = false;
      this.disable.description = false;
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }


  // initialise subscriptions for events, listeners, observables
  private initSubscriptions() {
    try {
      // if (!this.mode) {
        // get route query params
      //  this.sub = this.route.params.subscribe(params => {
          try {
            // this.mode = params['mode'];
            let projectId = this.pid;
            // let projectId = params['projectOnoperation'];
       //     let sharedScenario = params['sharedScn'];

            if (this.mode) {
              if (this.mode === 'add') {

                if (this.subsRegionCtrl) { this.subsRegionCtrl.unsubscribe(); }
                this.subsRegionCtrl = this.regionControl.valueChanges.subscribe((value) => {
                  try {
                    this.selectedMarket = null;
                    this.filteredHierachy = [];
                    let filteredData = this.dropdowns.regions;
                    this.isMarketReadOnly = false;
                    if (value) {
                      let tempArr = [];
                      for (let i = 0; i < filteredData.length; i++) {
                        if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
                          tempArr.push(filteredData[i]);
                        }
                      }
                      this.filteredHierachy = tempArr;
                      this.projectModel.rgn = value.value ? value.value : value;
                    } else {
                      this.filteredHierachy = filteredData;
                      this.projectModel.rgn = "";
                      this.selectedRegion = null;
                      this.isMarketVisible = false;
                        this.isMiniMarketVisible = false;
                        this.isStoreVisible = false;
                    }
                    this.projectModel.mkt = [];
                      this.projectModel.miniMkt = [];
                      this.projectModel.stores = [];
                    this.selectedMarkets = [];
                      this.selectedMiniMarkets = [];
                      this.selectedStores = [];
                    this.dropdowns.markets = [];
                      this.dropdowns.minimarkets = [];
                      this.dropdowns.stores = [];
                    this.marketControl.reset("");
                      this.miniMarketControl.reset("");
                      this.storeControl.reset("");
                     
                    this.cd.detectChanges();
                  } catch (err) {
                    this.logger.error(err);
                  }
                });

          /*     this.filteredMarkets = this.marketControl.valueChanges.pipe(
                  map((market: string | null) => { return market ? this.filterMarkets(market) : this.attachSelect(this.selectAllOption, this.getDropdowns("markets")).slice() })
                );*/
            
                // this.filteredMiniMarkets = this.miniMarketControl.valueChanges.pipe(
                //   map((minimarket: string | null) => { return minimarket ? this.filterMiniMarkets(minimarket) : this.attachSelect(this.selectAllOption, this.getDropdowns("minimarkets")).slice() })
                // );

                // this.filteredStores = this.storeControl.valueChanges.pipe(
                //   map((store: string | null) => { return store ? this.filterStores(store) : this.attachSelect(this.selectAllOption, this.getDropdowns("stores")).slice() })
                // );

                // this.filteredCoArea = this.coAreaControl.valueChanges.pipe(
                //   map((coArea: string | null) => { return coArea ? this.filterOpsArea('CO', coArea) : this.attachSelect(this.selectAllOption, this.getDropdowns("coAreas")).slice() })
                // );

                // this.filteredLsArea = this.lsAreaControl.valueChanges.pipe(
                //   map((lsArea: string | null) => { return lsArea ? this.filterOpsArea('LS', lsArea) : this.attachSelect(this.selectAllOption, this.getDropdowns("lsAreas")).slice() })
                // );

                if (this.subsRegionCoCtrl) { this.subsRegionCoCtrl.unsubscribe(); }
                this.subsRegionCoCtrl = this.regionCoControl.valueChanges.subscribe((value) => {
                  try {
                    this.selectedCoArea = null;
                    this.filteredHierachyCo = [];
                    let filteredData = this.dropdowns.coRegions;
                    this.isCoAreaReadOnly = false;
                    if (value) {
                      let tempArr = [];
                      for (let i = 0; i < filteredData.length; i++) {
                        if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
                          tempArr.push(filteredData[i]);
                        }
                      }
                      this.filteredHierachyCo = tempArr;
                      this.projectModel.rgn = value.value ? value.value : value;
                    } else {
                      this.filteredHierachyCo = filteredData;
                      this.projectModel.rgn = "";
                      this.selectedCoRegion = null;
                      this.isCoAreaReadOnly = false;
                      this.isStoreVisible = false;
                      this.isCoOpsAreaVisible = false;
                    }
                    this.projectModel.coArea = [];
                    this.selectedCoAreas = [];
                    this.dropdowns.coAreas = [];
                    // this.coAreaControl.reset("");
                      this.projectModel.stores = [];
                      this.selectedStores = [];
                      this.dropdowns.stores = [];
                      this.storeControl.reset("");
                    this.cd.detectChanges();
                  } catch (err) {
                    this.logger.error(err);
                  }
                });

                if (this.subsRegionLsCtrl) { this.subsRegionLsCtrl.unsubscribe(); }
                this.subsRegionLsCtrl = this.regionLsControl.valueChanges.subscribe((value) => {
                  try {
                    this.selectedLsArea = null;
                    this.filteredHierachyLs = [];
                    let filteredData = this.dropdowns.lsRegions;
                    this.isLsAreaReadOnly = false;
                    if (value) {
                      let tempArr = [];
                      for (let i = 0; i < filteredData.length; i++) {
                        if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
                          tempArr.push(filteredData[i]);
                        }
                      }
                      this.filteredHierachyLs = tempArr;
                      this.projectModel.rgn = value.value ? value.value : value;
                    } else {
                      this.filteredHierachyLs = filteredData;
                      this.projectModel.rgn = "";
                      this.selectedLsRegion = null;
                      this.isLsAreaReadOnly = false;
                      this.isStoreVisible = false;
                      this.isLsOpsAreaVisible = false;
                    }
                    this.projectModel.lsArea = [];
                    this.selectedLsAreas = [];
                    this.dropdowns.lsAreas = [];
                    this.lsAreaControl.reset("");
                      this.projectModel.stores = [];
                      this.selectedStores = [];
                      this.dropdowns.stores = [];
                      this.storeControl.reset("");
                    this.cd.detectChanges();
                  } catch (err) {
                    this.logger.error(err);
                  }
                });
              }
              else {
                // get project data based on project id from query params
                let isShare = this.mode == "share";
                this.projectService.getProjectById(projectId, isShare).then((data: ProjectModel) => {
                  try {
                    if (data) {
                      this.projectModel = data;
                      if (isShare) {
                        //this.projectModel = new ProjectModel();                       
                        // this.projectModel.rgn = data.rgn;
                        // this.projectModel.mkt = data.mkt;
                        // this.projectModel.miniMkt = data.miniMkt;
                        // this.projectModel.Name = data.Name;
                        // this.projectModel.Comments = data.Comments;
                        // this.projectModel.pr = data.pr;
                        this.projectModel.isSharedCreate = true;
                       this.projectModel.sharedScenarioId = this.sid;
                      }
                      //else {
                      //this.projectModel = data;
                      if (this.projectModel.geoType == "CO_OPS") {
                        this.showGeoSelectionOption('CO');
                        if (this.projectModel.coArea) { this.isCoOpsAreaVisible = true; }
                      } else if (this.projectModel.geoType == "LS_OPS") {
                        this.showGeoSelectionOption('LS');
                        if (this.projectModel.lsArea) { this.isLsOpsAreaVisible = true; }
                      } else {
                        this.showGeoSelectionOption('SD');
                        if (this.projectModel.mkt) { this.isMarketVisible = true; }
                        if (this.projectModel.miniMkt) { this.isMiniMarketVisible = true; }
                      }
                      if (this.projectModel.stores) { 
                        this.isStoreVisible = true; 
                      }
                      this.originalProj = <ProjectModel>JSON.parse(JSON.stringify(this.projectModel));
                      this.storeDetail = JSON.parse(JSON.stringify(this.projectModel.projectStores));
                      // this.storeDetails('')
                      //}
                      // if (this.projectModel.mkt) { this.isMarketVisible = true; }
                      // if (this.projectModel.miniMkt) { this.isMiniMarketVisible = true; }
                      // if (this.projectModel.coArea) { this.isCoOpsAreaVisible = true; }
                      // if (this.projectModel.lsArea) { this.isLsOpsAreaVisible = true; }
                    }
                    else {
                      this.projectModel = new ProjectModel();
                    }
                    this.cd.detectChanges();
                  }
                  catch (err) {
                    this.logger.error(err);
                  }
                }, (err) => {
                  this.projectModel = new ProjectModel();

                  this.logger.error(err);
                });
              }
            }

            this.cd.detectChanges();
          }
          catch (err) {
            this.logger.error(err);
          }
    //    });
      // }

      // get notified from confirmation box
      this.subsCloseDialog = SatDialogService.closeDialog.subscribe((params: DialogResultParams) => {
        try {

          if (params) {
            if (params.initiator == this.initiatorId) {
              if (params.operation == "archive") {
                if (params.action == 'Ok') { // ok button clicked
                  // initiate project Archive
                  this.projectService.deleteProjectById(this.projectOnOperation).then(() => {
                    try {
                      let options = {doEmit: true, selectDefault: true, goto: true}
                      this.projectService.projectsUpdated.next(options);
                      this.dialogRef.close();
                      this.cd.detectChanges();
                    }
                    catch (err) {
                      this.logger.error(err);
                    }
                  }, (err) => {
                    this.logger.error(err);
                  });
                }
              }
              // else any other operation
            }
          }
        }
        catch (err) {
          this.logger.error(err);
        }
      });


    }
    catch (err) {
      this.logger.error(err);
    }
  }

  displayFn(hierarchyType, geoType, hierarchy) {
    try {
      if (!hierarchy) return;
      if (geoType == 'SD') {
          if (hierarchyType === 'Region') {
              if (typeof (hierarchy) == 'string') {
                  hierarchy = this.filteredHierachy.find((val) => {
                      return val.value == hierarchy;
                  });
              }
              this.selectedRegion = hierarchy;
              this.projectModel.rgn = hierarchy.value;
              if (!this.isMarketVisible) this.selectedMarket = null;
              this.isMarketVisible = true;

          } else if (hierarchyType === 'Market') {
              this.selectedMarket = hierarchy;
              this.isMiniMarketVisible = true;
              this.projectModel.mkt = hierarchy.value;
          }
          else {
              this.selectedMiniMkt = hierarchy;
              this.isStoreVisible = true;
              this.projectModel.miniMkt = hierarchy.value;
          }
      }
      if (geoType == 'CO') {
        if (hierarchyType === 'coRegion') {
          if (typeof (hierarchy) == 'string') {
            hierarchy = this.filteredHierachyCo.find((val) => {
              return val.value == hierarchy;
            });
          }
          this.selectedCoRegion = hierarchy;
          this.projectModel.rgn = hierarchy.value;
          this.selectedCoArea = null;
          this.isCoOpsAreaVisible = true;

        } else {
          this.selectedCoArea = hierarchy;
          this.isStoreVisible = true;
          this.projectModel.coArea = hierarchy.value;
        }
      }

      if (geoType == 'LS') {
        if (hierarchyType === 'lsRegion') {
          if (typeof (hierarchy) == 'string') {
            hierarchy = this.filteredHierachyLs.find((val) => {
              return val.value == hierarchy;
            });
          }
          this.selectedLsRegion = hierarchy;
          this.projectModel.rgn = hierarchy.value;
          this.selectedLsArea = null;
          this.isLsOpsAreaVisible = true;

        } else {
          this.selectedLsArea = hierarchy;
          this.isStoreVisible = true;
          this.projectModel.lsArea = hierarchy.value;
        }
      }
      this.cd.detectChanges();
      return hierarchy.value;
    } catch (err) {
      this.logger.error(err);
    }
  }

  onColorSelection(event: any): void {
    try {
      this.projectModel.pr = event.priority;
      this.cd.detectChanges();
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  getDropdowns(attr) {
    return this.dropdowns[attr];
  }
  attachSelect(selectOption, dropdowns: any[]) {
    return (dropdowns && dropdowns.length > 0) ? [selectOption].concat(dropdowns) : [];
  }
  filterMarkets(value) {
    try {
      let filteredData = this.dropdowns.markets;
      this.isMiniMarketReadOnly = false;
      if (value) {
        let tempArr = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
            tempArr.push(filteredData[i]);
          }
        }
        return tempArr;

      } else {

        this.projectModel.mkt = [];
        this.isMiniMarketVisible = false;
        this.selectedMarket = null;
          this.projectModel.miniMkt = [];
          this.isStoreVisible = false;
          this.projectModel.stores = [];
        return filteredData;
      }
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  filterOpsArea(geoType: string, value) {
    try {
      if (geoType == 'CO') {
        let filteredData = this.dropdowns.coAreas;
        if (value) {
          let tempArr = [];
          for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
              tempArr.push(filteredData[i]);
            }
          }
          return tempArr;

        } else {
          this.projectModel.coArea = [];
          this.selectedCoArea = null;
          this.isStoreVisible = false;
          this.projectModel.stores = [];
          return filteredData;
        }
      }
      if (geoType == 'LS') {
        let filteredData = this.dropdowns.lsAreas;
        if (value) {
          let tempArr = [];
          for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i].value.toString().toLowerCase().includes(value.toString().toLowerCase())) {
              tempArr.push(filteredData[i]);
            }
          }
          return tempArr;

        } else {
          this.projectModel.lsArea = [];
          this.selectedLsArea = null;
          this.isStoreVisible = false;
          this.projectModel.stores = [];
          return filteredData;
        }
      }
    }
    catch (err) {
      this.logger.error(err);
    }
  }

  filterMiniMarkets(value) {
    try {
      let filteredData = this.dropdowns.minimarkets;
      if (value) {
        let val = typeof (value) == "string" ? value : value.value;
        let tempArr = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].value.toLowerCase().includes(val.toLowerCase())) {
            tempArr.push(filteredData[i]);
          }
        }
        this.projectModel.miniMkt = val;
        return tempArr;
      } else {
          this.projectModel.miniMkt = [];
          this.selectedMiniMkt = null;
          this.isStoreVisible = false;
          this.projectModel.stores = [];
        return filteredData;
      }
    } catch (err) {
      this.logger.error(err);
    }
  }

    filterStores(value) {
        try {
            let filteredData = this.dropdowns.stores;
            if (value) {
                let val = typeof (value) == "string" ? value : value.value;
                let tempArr = [];
                for (let i = 0; i < filteredData.length; i++) {
                    if (filteredData[i].value.toLowerCase().includes(val.toLowerCase())) {
                        tempArr.push(filteredData[i]);
                    }
                }
                this.projectModel.stores = val;
                return tempArr;
            } else {
                this.projectModel.stores = [];
                return filteredData;
            }
        } catch (err) {
            this.logger.error(err);
        }
    }

  /*add(event: MatChipInputEvent): void {
    try {
      const input = event.input;
      const value = event.value;

      // Add our item
      if ((value || '').trim()) {
        this.selectedMarkets.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.marketControl.reset("");
      this.marketInput.nativeElement.value = '';

      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }*/

 /* addMiniMarket(event: MatChipInputEvent): void {
    try {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.selectedMiniMarkets.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.miniMarketControl.reset("");
      this.miniMarketInput.nativeElement.value = '';

      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

    addStore(event: MatChipInputEvent): void {
        try {
            const input = event.input;
            const value = event.value;

            if ((value || '').trim()) {
                this.selectedStores.push(value.trim());
            }

            if (input) {
                input.value = '';
            }

            this.storeControl.reset("");
            this.storeInput.nativeElement.value = '';

            this.cd.detectChanges();
        } catch (err) {
            this.logger.error(err);
        }
    }*/

  remove(market: KeyValueModel): void {
    try {
      const markets = this.marketsControl.value;
      this.removeFirst(markets, market);
      this.marketsControl.setValue(markets);
      this.onMarketChange(this.marketsControl.value);
            
      if (this.marketsControl.value.length <= 0) {
        this.isMarketChipsVisible = true;
          this.isMiniMarketVisible = false;
      }
        this.storeDetails('SD');
        // this._resetMiniMarketFormControl();
        this.miniMarketsControl.reset();
        this.isStoreVisible = false;
        this.storesControl.reset();
        this._resetStoreFormControl('SD');
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

  removeOpsArea(geoType: string, opsArea: KeyValueModel): void {
    try {
      if (geoType == 'CO') {
        const coOpsareas = this.coAreaControl.value;
        this.removeFirst(coOpsareas, opsArea);
        this.coAreaControl.setValue(coOpsareas);

        if (coOpsareas.length <= 0) {
          this.isCoAreaChipsVisible = true;
          this.isStoreVisible = false;
        }
        this.getStoresList('CO');
        this.storeDetails('CO');
      }
      if (geoType == 'LS') {
        const lsOpsareas = this.lsAreasControl.value;
        this.removeFirst(lsOpsareas, opsArea);
        this.lsAreasControl.setValue(lsOpsareas);
        if (lsOpsareas.length <= 0) {
          this.isLsAreaChipsVisible = true;
          this.isStoreVisible = false;
        }
        this.getStoresList('LS');
        this.storeDetails('LS');
      }
      this._resetStoreFormControl(geoType);
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

  removeMiniMarket(minimarket: KeyValueModel): void {
    try {
      const minimarkets = this.miniMarketsControl.value;
      this.removeFirst(minimarkets, minimarket);
      this.miniMarketsControl.setValue(minimarkets);
        if (this.selectedMiniMarkets.length <= 0) {
            this.isMiniMarketChipsVisible = true;
            this.isStoreVisible = false;
        }
        this.getStoresList('SD');
        this.storeDetails('SD');
        // this._resetStoreFormControl('SD');
        this.storesControl.reset();
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

    removeStore(store: KeyValueModel): void {
        try {
          const stores = this.storesControl.value;
          this.removeFirst(stores, store);
          this.storesControl.setValue(stores);
            this.storeDetail = JSON.parse(JSON.stringify(stores)) ;
            if(stores.length === 0){
              this.storeDetails(this.geographySelection)
            }
                
            this.cd.detectChanges();
        } catch (err) {
            this.logger.error(err);
        }
    }

  selected(event: MatAutocompleteSelectedEvent): void {
    try {

      let isAll = event.option.value == this.selectAllOption ||
        this.selectedMarkets && this.selectedMarkets.find((val) => {
          return val.id == this.selectAllOption.id;
        });

      if (!isAll) {
        let index = this.selectedMarkets.findIndex(x => x.id === event.option.value.id);
        if (index < 0) {
          this.selectedMarkets.push(event.option.value);
          this._resetMiniMarketFormControl();
          this.dropdowns.minimarkets = [];
        } else {
          this.selectedMarkets.splice(index, 1);
          this.cd.detectChanges();
        }
      }
      else {
        let isRemove = (event.option.value.id == this.selectAllOption.id) && (this.selectedMarkets && this.selectedMarkets.length == 1 && this.selectedMarkets[0].id == this.selectAllOption.id);

        this.selectedMarkets = isRemove ? [] : [{ id: this.selectAllOption.id, value: "All markets selected. Total count: " + this.dropdowns.markets.length }];
      }
      this.marketControl.reset("");

      setTimeout(() => {
      //  this.autoTrgrMkt.openPanel();
     //   this.marketControl.setValue(this.marketInput.nativeElement.value);
     //   this.marketInput.nativeElement.focus();
      });

      if (this.marketsControl.value.length >= 2) {
        this.isMarketChipsVisible = true;
      }

      if (this.marketsControl.value.length <= 0) {
        this.isMarketChipsVisible = true;
          this.isMiniMarketVisible = false;
          this.isStoreVisible = false;
      }

      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

  selectedOpsArea(geoType: string, event: MatAutocompleteSelectedEvent): void {
    try {

      if (geoType == "CO") {
        let isAll = event.option.value == this.selectAllOption ||
          this.selectedCoAreas && this.selectedCoAreas.find((val) => {
            return val.id == this.selectAllOption.id;
          });

        if (!isAll) {
          let index = this.selectedCoAreas.findIndex(x => x.id === event.option.value.id);
          if (index < 0) {
            this.selectedCoAreas.push(event.option.value);
          } else {
            this.selectedCoAreas.splice(index, 1);
          }
        }
        else {
          let isRemove = (event.option.value.id == this.selectAllOption.id) && (this.selectedCoAreas && this.selectedCoAreas.length == 1 && this.selectedCoAreas[0].id == this.selectAllOption.id);
          this.selectedCoAreas = isRemove ? [] : [{ id: this.selectAllOption.id, value: "All CO Ops Area selected. Total count: " + this.dropdowns.coAreas.length }];
        }
        this.coAreaControl.reset("");
        setTimeout(() => {
          this.autoTrgrCoArea.openPanel();
          this.coAreaControl.setValue(this.coAreaInput.nativeElement.value);
          this.coAreaInput.nativeElement.focus();
        });

        if (this.selectedCoAreas.length >= 2) { this.isCoAreaChipsVisible = true; }
        if (this.selectedCoAreas.length <= 0) { this.isCoAreaChipsVisible = true; }
      }
      if (geoType == "LS") {
        let isAll = event.option.value == this.selectAllOption ||
          this.selectedLsAreas && this.selectedLsAreas.find((val) => {
            return val.id == this.selectAllOption.id;
          });

        if (!isAll) {
          let index = this.selectedLsAreas.findIndex(x => x.id === event.option.value.id);
          if (index < 0) {
            this.selectedLsAreas.push(event.option.value);
          } else {
            this.selectedLsAreas.splice(index, 1);
          }
        }
        else {
          let isRemove = (event.option.value.id == this.selectAllOption.id) && (this.selectedLsAreas && this.selectedLsAreas.length == 1 && this.selectedLsAreas[0].id == this.selectAllOption.id);
          this.selectedLsAreas = isRemove ? [] : [{ id: this.selectAllOption.id, value: "All LS Ops Area selected. Total count: " + this.dropdowns.lsAreas.length }];
        }
        this.coAreaControl.reset("");
        setTimeout(() => {
          this.autoTrgrLsArea.openPanel();
          this.lsAreaControl.setValue(this.lsAreaInput.nativeElement.value);
          this.lsAreaInput.nativeElement.focus();
        });

        if (this.selectedLsAreas.length >= 2) { this.isLsAreaChipsVisible = true; }
        if (this.selectedLsAreas.length <= 0) { this.isLsAreaChipsVisible = true; }
      }
      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }

  }


  selectedMiniMarket(event: MatAutocompleteSelectedEvent): void {
    try {
      let isAll = event.option.value == this.selectAllOption ||
        this.selectedMiniMarkets && this.selectedMiniMarkets.find((val) => {
          //return val.indexOf("All mini markets selected") > -1;
          return val.id == this.selectAllOption.id;
        });

      if (!isAll) {
        let index = this.selectedMiniMarkets.indexOf(event.option.value);
        if (index < 0) {
          this.selectedMiniMarkets.push(event.option.value);
        }
        else {
          this.selectedMiniMarkets.splice(index, 1);
        }
      }
      else {

        let isRemove = (event.option.value.id == this.selectAllOption.id) && (this.selectedMiniMarkets && this.selectedMiniMarkets.length == 1 && this.selectedMiniMarkets[0].id == this.selectAllOption.id);

        this.selectedMiniMarkets = isRemove ? [] : [{ id: this.selectAllOption.id, value: "All mini markets selected. Total count: " + this.dropdowns.minimarkets.length }];
      }
      this.miniMarketControl.reset("");
      // setTimeout(() => {
      //   this.autoTrgrMinMkt.openPanel();

      //   this.miniMarketControl.setValue(this.miniMarketInput.nativeElement.value);
      //   this.miniMarketInput.nativeElement.focus();
      // });

        if (this.selectedMiniMarkets.length >= 2) {
            this.isMiniMarketChipsVisible = true;
        }

        if (this.selectedMiniMarkets.length <= 0) {
            this.isMiniMarketChipsVisible = true;
            this.isStoreVisible = false;
        }

      this.cd.detectChanges();
    } catch (err) {
      this.logger.error(err);
    }
  }

  private _resetMiniMarketFormControl() {
    try {
      this.selectedMiniMarkets = [];
      this.miniMarketControl.setValue("");
      if (this.selectedMarkets.length === 0) {
        this.isMiniMarketVisible = false;
      }
    } catch (err) {
      this.logger.error(err);
    }
  }
  private getSelectedMinimarkets() {
    try {
      let results = [];
      if (this.miniMarketsControl.value && this.miniMarketsControl.value.length > 0) {
        // let isAll = this.selectedMiniMarkets[0].id == this.selectAllOption.id;
        let selectedminimarkets = this.miniMarketsControl.value;
        // if (isAll) {
        //   selectedminimarkets = this.dropdowns.minimarkets;
        // }
        for (let minimarket of selectedminimarkets) { results.push(minimarket.value); }
      }
      return results;
    } catch (err) {
      this.logger.error(err);
    }
  }

    selectedStore(event: MatAutocompleteSelectedEvent): void {
        try {
            let isAll = event.option.value == this.selectAllOption ||
                this.selectedStores && this.selectedStores.find((val) => {
                    return val.id == this.selectAllOption.id;
                });

            if (!isAll) {
                let index = this.selectedStores.indexOf(event.option.value);
                if (index < 0) {
                    this.selectedStores.push(event.option.value);
                }
                else {
                    this.selectedStores.splice(index, 1);
                } 
            }
            else {

                let isRemove = (event.option.value.id == this.selectAllOption.id) && (this.selectedStores && this.selectedStores.length == 1 && this.selectedStores[0].id == this.selectAllOption.id);

                this.selectedStores = isRemove ? [] : [{ id: this.selectAllOption.id, value: "All stores selected. Total count: " + this.dropdowns.stores.length }];
            }
            if(this.selectedStores[0].id === 'Select All'){
              this.storeDetails(this.geographySelection)
            }
            else{
             this.storeDetail = JSON.parse(JSON.stringify(this.selectedStores)) ;
            }
            this.storeControl.reset("");
            setTimeout(() => {
                this.autoTrgrStore.openPanel();

                this.storeControl.setValue(this.storeInput.nativeElement.value);
                this.storeInput.nativeElement.focus();
            });

            this.cd.detectChanges();
        } catch (err) {
            this.logger.error(err);
        }
    }
    private _resetStoreFormControl(geoType: string) {
        try {
            this.selectedStores = [];
            this.storeControl.setValue("");
            if ((geoType == 'SD' && this.miniMarketsControl.value.length === 0)
            || (geoType == 'CO' && this.coAreaControl.value.length === 0) || (geoType == 'LS' && this.lsAreasControl.value.length === 0)) {
              this.isStoreVisible = false;
            }
            // else if (geoType == 'CO' && this.coAreaControl.value.length === 0) {this.isStoreVisible = false;}
            // else if (geoType == 'LS' && this.lsAreasControl.value.length === 0){ this.isStoreVisible = false;}
        } catch (err) {
            this.logger.error(err);
        }
    }
    private getSelectedStores() {
        try {
            let results = [];
            if (this.storesControl.value &&this.storesControl.value.length > 0) {
                // let isAll = this.selectedStores[0].id == this.selectAllOption.id;
                let selectedstores = this.storesControl.value;
                // if (isAll) {
                //     selectedstores = this.dropdowns.stores;
                // }
                for (let store of selectedstores) { results.push(store.id); }
            }
            return results;
        } catch (err) {
            this.logger.error(err);
        }
    }

  private getSelectedOpsArea(geoType: string) {
    try {
      let results = [];
      let selection = geoType == "CO" ? this.coAreaControl.value : this.lsAreasControl.value;
      if (selection && selection.length > 0) {
        // let isAll = selection[0].id == this.selectAllOption.id;
        let selectedAreas = selection;
        // if (isAll) {
        //   selectedAreas = geoType == "CO" ? this.dropdowns.coAreas : this.dropdowns.lsAreas;
        // }
        for (let areaOps of selectedAreas) { results.push(areaOps.value); }
      }
      return results;
    } catch (err) {
      this.logger.error(err);
    }
  }

  private getSelectedMarkets() {
    try {
      let results = [];
      if (this.marketsControl.value && this.marketsControl.value.length > 0) {
        // let isAll = this.selectedMarkets[0].id == this.selectAllOption.id;
        let selectedmarkets = this.marketsControl.value;
        // if (isAll) {
          // selectedmarkets = this.dropdowns.markets;
        // }
        for (let market of selectedmarkets) { results.push(market.value); }
      }
      return results;
    } catch (err) {
      this.logger.error(err);
    }
  }

  isSelectedMarket(market) {
    return this.selectedMarkets.findIndex(x => x.id === market.id) >= 0;
  }

  isSelectedMiniMarket(minimarket) {
    return this.selectedMiniMarkets.findIndex(x => x.id === minimarket.id) >= 0;
  }

    isSelectedStore(store) {
        return this.selectedStores.findIndex(x => x.id === store.id) >= 0;
    }

  isSelectedOpsArea(geoType: string, opsArea: KeyValueModel) {
    if (geoType == 'CO') return this.selectedCoAreas.findIndex(x => x.id === opsArea.id) >= 0;
    return this.selectedLsAreas.findIndex(x => x.id === opsArea.id) >= 0;
  }


  private isUpdate(): boolean {
    try {
      let original: string = JSON.stringify(this.originalProj);
      let current: string = JSON.stringify(this.projectModel);
      if (current != original) {
        return true;
      }
      else {
        return false;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }

  }
  close(){
    this.dialogRef.close();
  }
  check(){
    console.log("success")
  }
  
  // onrchange(){
  //   this.filteredMarkets = this.marketControl.valueChanges.pipe(
  //     map((market: string | null) => { return market ? this.filterMarkets(market) : this.attachSelect(this.selectAllOption, this.getDropdowns("markets")).slice() })
  //   );
  // }
  // onmchange(){
  //   this.filteredMiniMarkets = this.miniMarketControl.valueChanges.pipe(
  //     map((minimarket: string | null) => { return minimarket ? this.filterMiniMarkets(minimarket) : this.attachSelect(this.selectAllOption, this.getDropdowns("minimarkets")).slice() })
  //   );
  // }

  toggleOneMarket(){ 
    if (this.allMarketSelected.selected) {  
     this.allMarketSelected.deselect();
    }
   if(this.marketsControl.value.length==this.filteredMarkets1.length){
     //  this.allMarketSelected.select();
    this.marketsControl.patchValue([...this.filteredMarkets1.map(item => item),this.selectAllOption]);
  }
    this.onMarketChange(this.marketsControl.value);
    this.storeDetails('SD');
 }
 toggleAllSelectionMarket() {
     if (this.allMarketSelected.selected) {
       this.marketsControl.patchValue([...this.filteredMarkets1.map(item => item),this.selectAllOption]);
       let marketSelected = this.marketsControl.value;
       marketSelected.pop();
       this.onMarketChange(marketSelected);
     } else {
       this.marketsControl.patchValue([]);
       this.isMiniMarketVisible = false;
     }
     this.storeDetails('SD');
   }
   toggleOneMiniMarket(all){ 
    if (this.allMiniMarketSelected.selected) {  
     this.allMiniMarketSelected.deselect();
 }
   if(this.miniMarketsControl.value.length==this.filteredMiniMarkets1.length){
     //  this.allMiniMarketSelected.select();
    this.miniMarketsControl.patchValue([...this.filteredMiniMarkets1.map(item => item),this.selectAllOption]);
   }
    this.storeDetails('SD');
    this.getStoresList('SD');
 }
 toggleAllSelectionMiniMarket() {
     if (this.allMiniMarketSelected.selected) {
       this.miniMarketsControl.patchValue([...this.filteredMiniMarkets1.map(item => item),this.selectAllOption]); 
       this.getStoresList('SD');
     } else {
       this.miniMarketsControl.patchValue([]);
       this.isStoreVisible = false;
     }
     this.storeDetails('SD');
   }
   toggleOneCoArea(all){ 
    if (this.allCoAreaSelected.selected) {  
     this.allCoAreaSelected.deselect();
 }
   if(this.coAreaControl.value.length==this.filteredCoArea1.length){
     //  this.allCoAreaSelected.select();
    this.coAreaControl.patchValue([...this.filteredCoArea1.map(item => item),this.selectAllOption]);
   }
   this.getStoresList('CO');
   this.storeDetails('CO');
 }
 toggleAllSelectionCoArea() {
     if (this.allCoAreaSelected.selected) {
       this.coAreaControl.patchValue([...this.filteredCoArea1.map(item => item),this.selectAllOption]);
       
     } else {
       this.coAreaControl.patchValue([]);
       this.isCoAreaChipsVisible = false;
       this.isStoreVisible = false;
     }
     this.getStoresList('CO');
     this.storeDetails('CO');
   }
   toggleOneStore(){ 
    if (this.allStoreSelected.selected) {  
     this.allStoreSelected.deselect();
 }
   if(this.storesControl.value.length==this.filteredStores1.length) {
     //  this.allStoreSelected.select();
    this.storesControl.patchValue([...this.filteredStores1.map(item => item),this.selectAllOption]);
    //  this.storeDetail = this.storesControl.value;
    let storeSelected = this.storesControl.value;
    storeSelected.pop();
   }
    
    if(this.storesControl.value  && this.storesControl.value.length >0) {
      this.storeDetail = JSON.parse(JSON.stringify(this.storesControl.value)) ;
    } else{
      this.storeDetails(this.geographySelection);
    }
 }
 
 toggleAllSelectionStore() {
     if (this.allStoreSelected.selected) {
       this.storesControl.patchValue([...this.filteredStores1.map(item => item),this.selectAllOption]);
       let storeSelected = this.storesControl.value;
       storeSelected.pop();
      
     } else {
       this.storesControl.patchValue([]);
     }
    if(this.storesControl.value  && this.storesControl.value.length >0) {
      this.storeDetail = JSON.parse(JSON.stringify(this.storesControl.value)) ;
    } else{
      this.storeDetails(this.geographySelection);
    }
   }

   toggleOneLsArea(all){ 
    if (this.allLsAreaSelected.selected) {  
     this.allLsAreaSelected.deselect();
 }
   if(this.lsAreasControl.value.length==this.filteredLsArea1.length){
      //  this.allLsAreaSelected.select();
    this.lsAreasControl.patchValue([...this.filteredLsArea1.map(item => item),this.selectAllOption]);
   }
    
     this.getStoresList('LS');
     this.storeDetails('LS');
 }
 toggleAllSelectionLsArea() {
     if (this.allLsAreaSelected.selected) {
       this.lsAreasControl.patchValue([...this.filteredLsArea1.map(item => item),this.selectAllOption]);
       
     } else {
       this.lsAreasControl.patchValue([]);
       this.isLsAreaChipsVisible = false;
       this.isStoreVisible = false;
     }
     this.getStoresList('LS');
     this.storeDetails('LS');
   }

}