import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrum.model';
import { ScenarioService } from '../scenario/scenario.service';

@Injectable({
  providedIn: 'root'
})
export class RouteStateService {

  private history = [];
  public breadcrumbs: Breadcrumb[];
  previousUrl;
  mode: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private scenarioService: ScenarioService) {
    this.breadcrumbs = [];
  }

  public loadRouting(): void {
    try {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
          this.history = [...this.history, urlAfterRedirects];
          let root: ActivatedRoute = this.activatedRoute.root;
          this.breadcrumbs = this.getBreadcrumbs(root);
        });
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  public getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    try {
      const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

      //get the child routes
      let children: ActivatedRoute[] = route.children;

      //return if there are no more children
      if (children.length === 0) {
        return breadcrumbs;
      }

      //iterate over each children
      for (let child of children) {
        //verify primary route
        if (child.outlet !== PRIMARY_OUTLET) {
          continue;
        }

        const routeArray = child.snapshot.url.map(segment => segment.path);

        //verify the custom data property "breadcrumb" is specified on the route
        if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
          return this.getBreadcrumbs(child, url, breadcrumbs);
        }

        if (routeArray.length === 0) {
          return this.getBreadcrumbs(child, url, breadcrumbs);
        }

        //get the route's URL segment
        //routeArray.join("/");
        let label: string = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
        //append route URL to URL

        this.prepareBreadCrumMaps(label, breadcrumbs);

        //recursive
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  private prepareBreadCrumMaps(label: string, itemBreadcrums: Breadcrumb[]): Breadcrumb[] {

    try {
      switch (label) {
        case 'Dashboard':
        case 'Compare':
        case 'Scenarios':

          let proj = this.scenarioService.getSelectedProject();
          if (proj) {
            itemBreadcrums.push(<Breadcrumb>{ label: proj.Name, url: ['/project/scenario', proj.Id] });

          }
          break;

        default:
          itemBreadcrums = [];
      }
      return itemBreadcrums;

    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  private isParam(params: Params, segment: string) {
    try {
      for (const key of Object.keys(params)) {
        const value = params[key];
        if (value === segment) {
          return true;
        }
      }
      return false;
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    try {
      let returnUrl = ""
      if (this.previousUrl) {
        returnUrl = this.previousUrl
        this.previousUrl = null;
      } else {
        returnUrl = this.history[this.history.length - 2] || '/dashboard'
      }
      let qParamsIdx = returnUrl.indexOf("?");
      return qParamsIdx < 0 ? returnUrl : returnUrl.substring(0, qParamsIdx);
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }

  public setPreviousUrl(previousURL) {
    this.previousUrl = previousURL;
  }

  public getBreadCrumMap(): Breadcrumb[] {
    return this.breadcrumbs;
  }


}
