  <div class="help-window ">
      <div class="image-div ">

          <img class="atlas-image "  alt="Logo" src="assets/images/asb-new-logo.svg">

          <button class="save-close-button" (click)="saveAndClose() ">
          <span class="button-close-icon"></span><span>Close Window</span>
          </button>

      </div>
    <div class="region-list-div ">
		<div class='title'>Training Materials</div>
      <ul class="training-region-list ">
        
        
      </ul>
	  	<div class='title'>For Support</div>
      <ul class="help-region-list ">        
        
      </ul>
      <div class='bottom-link'>
        
      </div>
    </div>

  </div>
