import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceHelper {
  constructor(private http: HttpClient,private sanitizer: DomSanitizer) { }

  private isUserAdmin: boolean = false;
  setUserAdmin(isAdmin: boolean) { this.isUserAdmin = isAdmin; }
  checkIsUserAdmin(): boolean { return this.isUserAdmin }

  GetServiceJsonDataWithPortalToken(serviceUrl) {
    try {
      let _headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      serviceUrl = this.sanitizer.sanitize(SecurityContext.URL,serviceUrl);
      return this.http.get(serviceUrl, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  GetServiceJsonDataWithOutPortalToken(serviceUrl) {
    try {
      return this.http.get(serviceUrl);
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  GetServiceJsonDataObs(serviceUrl) {
    try {
      return this.http.get(serviceUrl, { observe: 'response' });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  GetServiceJsonDataMultipleWithPortalToken(serviceUrls: string[]) {
    try {
      let _obsString = [];
      serviceUrls.forEach(_url => { _obsString.push(this.GetServiceJsonDataWithPortalToken(_url)) });
      return forkJoin(_obsString);
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  PostServiceJsonDataWithPortalToken(serviceUrl, data) {
    try {
      let _headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      serviceUrl = this.sanitizer.sanitize(SecurityContext.URL,serviceUrl);
      return this.http.post(serviceUrl, data, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }
  PutServiceJsonDataWithPortalToken(serviceUrl, data) {
    try {
      let _headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.put(serviceUrl, data, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  PatchServiceJsonDataWithPortalToken(serviceUrl, data) {
    try {
      let _headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.patch(serviceUrl, data, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }
  DeleteServiceJsonDataWithPortalToken(serviceUrl) {
    try {
      let _headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.delete(serviceUrl, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }
  GetServiceFileDataWithPortalToken(serviceUrl) {
    try {
      let _headers = new HttpHeaders({

      });
      return this.http.get(serviceUrl, { headers: _headers, withCredentials: true });
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }



}
