import { Injectable, EventEmitter } from '@angular/core';
import { DialogParams, DialogResultParams } from './sat-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class SatDialogService {
  static openDialog = new EventEmitter<DialogParams>();
  static closeDialog = new EventEmitter<DialogResultParams>();
}
