<div *ngIf="isUserAuthenticated" class="flex-container">

  <div class="pnl-main" style="overflow-y: auto">
    <app-navbar (onToggleMenu)="showLeftPanel = !showLeftPanel" #header [username]="username" [isAdminUser]="isAdmin">
    </app-navbar>
    <router-outlet></router-outlet>
  </div>
  <sat-dialog></sat-dialog>
</div>

<!-- error message -->
<div *ngIf="!isUserAuthenticated && isSSODone && errMsg" class="err-comp">
  <div class="err-panel">
    <div class="img-pnl">
      <div class="ico-lock"></div>
      <div class="ico-logo"></div>
    </div>
    <div class="txt-pnl">
      <div class="title-pnl">Access Needed</div>
      <div class="msg-pnl">
        <p>You do not have access to Atlas Scenario Builder</p>
        <p>Please contact <a href="mailto:askAtlas@Starbucks.com" target="_top" style="
          text-decoration: underline;"> askAtlas&#64;Starbucks.com </a> for support.</p>
        <p>Thank You!</p>
      </div>
    </div>
  </div>
</div>




<!-- outage2 -->
<div class="Outage-screen" style="border: outset;" *ngIf="resp?.outagePermission < resp?.outageLevel">
  <div class="image-div">
      <img class="logo" src="./assets/images/asb-new-logo.svg">
  </div>
  <div class="outage-message-div">
      <div class="outage-title">
          Planned Maintenance
          <div class="outage-desc" style="width:800px;">
            {{resp?.outageMsg1}}<br><br> {{resp?.outageMsg2}} <br><br> Outage Time frame: <br>PST: <span style="font-weight: 600;"> {{outageStartPST | date:'MMMM dd, YYYY hh:mm a'}} to {{outageEndPST | date:'MMMM dd, hh:mm a'}} </span><br>                    UTC: <span style="font-weight: 600;"> {{resp?.startDateUTC | date:'MMMM dd, YYYY HH:mm ':'UTC'}} to {{resp?.endDateUTC | date:'MMMM dd, HH:mm ':'UTC'}}</span>
            <br><br> {{resp?.outageMsg3}}<br>
        </div>

      </div>
  </div>

</div>