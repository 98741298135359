import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textItalic'
})
export class TextItalicPipe implements PipeTransform {

  transform(value: string, search: string): string {
    const valueStr = value + ''; // Ensure numeric values are converted to strings
    return valueStr.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<em class="your-class">&#160;$1&#160;</em>');
  }

} 