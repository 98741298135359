import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap/dropdown/dropdown.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SatCommonModule } from './common/sat-common.module';
import { APP_CONFIG } from './config/app.config';
import { FormatterModule, MyCurrencyPipe, MyPercentPipe } from './formatter/formatter.module';
import { DomainHelperService } from './helpers/domain-helper.service';
import { ValidationModule } from './helpers/validation/validation.module';
import { HttpIntercepterConfig } from './interceptor/httpconfig.interceptor';
import { SessionErrorDialogComponent } from './interceptor/session-error-dialog/session-error-dialog.component';
import { Logger, Options as LoggerOptions } from './Logger/logger.module';
import { SatDialogContent } from './sat-dialog/sat-dialog-content.component';
import { SatDialogComponent } from './sat-dialog/sat-dialog.component';
import { SearchComponent } from './search/search.component';
import { HelpModule } from './help/help.module';
import { DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ScenarioDialogComponent } from './scenario-dialog/scenario-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { EnhancedSearchComponent } from './enhanced-search/enhanced-search.component';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSortModule } from '@angular/material/sort';
import { HighlightSearchPipe } from './enhanced-search/highlighter-text';
import { TextItalicPipe } from './sat-dialog/TextItalicPipe';
import { SortDirective } from './enhanced-search/sort.directive';
import { EllipsisModule } from 'ngx-ellipsis';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    SatDialogComponent,
    SatDialogContent,
    SearchComponent,
    SessionErrorDialogComponent,
    ScenarioDialogComponent,
    EnhancedSearchComponent,
    HighlightSearchPipe,
    TextItalicPipe,
    SortDirective
  ],
  imports: [
    BrowserModule,
    //NgbModule.forRoot(),
    //NgbDropdownModule.forRoot(),
    //NgbModalModule.forRoot(),
    NgbTooltipModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatDialogModule,
    ScrollingModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatChipsModule,
    MatExpansionModule,
    MatSelectModule,
    MatTabsModule,
    ValidationModule,
    FormatterModule,
    SatCommonModule,
    AppRoutingModule,
    MatTableModule,
    HelpModule,
    FontAwesomeModule,
    TooltipModule,
    MatCheckboxModule,
    MatInputModule,
    MatSortModule,
    EllipsisModule,
    NgChartsModule 
    // Logger
  ],
  providers: [DomainHelperService,DatePipe,TextItalicPipe,HighlightSearchPipe,
    Logger,
    {
      provide: LoggerOptions, useValue: {
        level: APP_CONFIG.LOG_LEVEL, store: true,
        consoleLog: APP_CONFIG.CONSOLE_LOG, serverLog: APP_CONFIG.SERVER_LOG
      }
    }, MyCurrencyPipe, MyPercentPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercepterConfig,
      multi: true
    }
  ], bootstrap: [AppComponent],
  //entryComponents: [SatDialogContent, SessionErrorDialogComponent, ScenarioDialogComponent]
})
export class AppModule { }
