import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { Logger } from './../Logger/logger.module';
// import { isBoolean } from 'util';

const PADDING = '000000';

/**
 * Pipe for Percent formatting. 
 */
@Pipe({ name: 'percentFormatter' })
export class MyPercentPipe implements PipeTransform, OnDestroy {

  private DECIMAL_SEPARATOR: string;
  private SYMBOL_PERCENT: string;

  public defaultValue: string = '';

  constructor(private logger: Logger) {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = '.';
    this.SYMBOL_PERCENT = '%';
  }

  ngOnDestroy() {
    try {
    this.DECIMAL_SEPARATOR = null;
    this.SYMBOL_PERCENT = null;
    } catch (error) {
      this.logger.error("Error @ MyPercentPipe: ngOnDestroy() ->"+ error); 
    }    
  }

  /**
   * Method to convert number to percentage formatted string.
   * 
   * @param value 
   * @param fractionSize 
   */
    transform(value: number | string, fractionSize: number = null, calculated: boolean = false, isBaseline: boolean = false): string {
    try {
        if (value === null || value === undefined || value.toString().trim() === "") {
            return isBaseline ? 0 + this.SYMBOL_PERCENT : this.defaultValue ? this.defaultValue + this.SYMBOL_PERCENT : "";
      }

      let input = parseFloat(value + "");
      input = calculated ? input * 100 : input;
      
      if (fractionSize == null || fractionSize == undefined) {
        value = input;
      }
      else {
        value = Number(Math.round(input * Math.pow(10, fractionSize)) / Math.pow(10, fractionSize));
      }

      if (!value) {
          return isBaseline ? 0 + this.SYMBOL_PERCENT : this.defaultValue ? this.defaultValue + this.SYMBOL_PERCENT : "";
      }
      let [integer, fraction = ''] = value.toString().split(this.DECIMAL_SEPARATOR);
        if (fraction !== '') {
            if (fractionSize !== null && fractionSize !== undefined) {
                fraction = fractionSize > 0
                    ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
                    : '';
            }
            else {
                fraction = this.DECIMAL_SEPARATOR + fraction;
            }
        } else fraction = this.DECIMAL_SEPARATOR + '0';
      return (integer + fraction) ? integer + fraction + this.SYMBOL_PERCENT : "";
    } catch (err) {
      this.logger.error("Error @ MyPercentPipe: transform() ->"+ err);
    }
  }

  /**
   * Parse percentage formatted string to a number.
   * 
   * @param value 
   * @param fractionSize 
   */
  parse(value: string, fractionSize: number): string {
    try {
      if (value === null || value === undefined || value.toString().trim() === "") {
        return this.defaultValue;
      }
      value = value.replace(new RegExp(this.SYMBOL_PERCENT, 'g'), '');

      let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

      if (fraction !== '') {
        if (fractionSize !== null && fractionSize !== undefined) {
          fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : '';
        }
        else {
          fraction = this.DECIMAL_SEPARATOR + fraction;
        }
      }
      return integer + fraction;
    } catch (err) {
      this.logger.error("Error @ MyPercentPipe: parse() ->"+ err);
    }
  }

  /**
   * Round off input to specified decimal places.
   * 
   * @param input 
   * @param decimalPlaces 
   */
  convertToDecimal(input, decimalPlaces) {
    try {
      return Number(Math.round(input * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces));
    } catch (err) {
      this.logger.error("Error @ MyPercentPipe: convertToDecimal() ->"+ err);
    }
  }

}
