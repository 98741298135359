import { Injectable } from '@angular/core';
import { ENDPOINTS_CONFIG, SERVICE_CONFIG } from '../config/service.config';
import { ServiceHelper } from '../helpers/service.helper';
import { ProjectScenarioDetail } from './project-scenario-detail';

@Injectable({
  providedIn: 'root'
})
export class EnhancedSearchService {

  constructor(private serviceHelper: ServiceHelper) { }

  getProjectScenerioDetail(searchText) {
    return new Promise((resolve, reject) => {
      try {
        
       
          let url = SERVICE_CONFIG.serviceUrl + ENDPOINTS_CONFIG.lookups.projectScenarioDetail + '?searchterm=' + searchText;
          let temp = this.serviceHelper.GetServiceJsonDataWithPortalToken(url).subscribe((data: ProjectScenarioDetail) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        
      } catch (err) {
        reject(err);
      }
    });
  }

}
