import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '../Logger/logger.module';
import { SatDialogContent } from './sat-dialog-content.component';
import { DialogParams, DialogResultParams } from './sat-dialog.model';
import { SatDialogService } from './sat-dialog.service';


@Component({
  selector: 'sat-dialog',
  templateUrl: './sat-dialog.component.html',
  styleUrls: ['./sat-dialog.component.css']
})
export class SatDialogComponent implements OnDestroy {
  closeResult: string;
  params = new DialogParams();
  subscription = null;

  constructor(public dialog: MatDialog, private logger: Logger) {
    try {
      this.subscription = SatDialogService.openDialog.subscribe((params) => {
        try {
          if (params) {
            this.params = params;
            this.open();
          }
        } catch (err) {
          this.logger.error("Error @ SatDialogComponent: SatDialogService.openDialog.subscribe() ->"+ err);
        }
      });
    } catch (err) {
      this.logger.error("Error @ SatDialogComponent: constructor() ->"+ err);
    }
  }
  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  open() {

    try {
      let config: any = {};
      config.data = this.params;
      config.panelClass = this.params.panelClass;
      config.width = this.params.isInvalidProject ? '560px' : this.params.isDummyPipeline ? '410px' : '350px';
      config.height = this.params.isInvalidProject ? '660px' : '';
      config.panelClass = this.params.isInvalidProject ? 'valid-invalid_dialog' : '';

      const dialogRef = this.dialog.open(SatDialogContent, config);

      let temp = dialogRef.afterClosed().subscribe(result => {
        try {
          this.closeResult = result;

          let resultParams = new DialogResultParams();
          resultParams.initiator = this.params.initiatorId;
          resultParams.operation = this.params.operationId;
          resultParams.action = this.closeResult;
          resultParams.results = dialogRef.componentInstance.selectedUser;
          resultParams.resultProj = dialogRef.componentInstance.selectedProj;
          resultParams.resultScnName = dialogRef.componentInstance.scnName; 
          
          if (this.params.export) {
            resultParams.resultScn = {
              primary: dialogRef.componentInstance.selectedObj,
              secondary: dialogRef.componentInstance.selectedObjSec
            };
          }
          else {
            resultParams.resultScn = dialogRef.componentInstance.scenarios;
          }

          if (this.params.operationId == "atlasUpdateConfirm")  {
            resultParams.resultScn = this.params.store;
          }

          SatDialogService.closeDialog.next(resultParams);
          temp.unsubscribe();
        } catch (err) {
          this.logger.error("Error @ SatDialogComponent: dialogRef.afterClosed().subscribe() ->"+ err);
        }
      });
    } catch (err) {
      this.logger.error("Error @ SatDialogComponent: open() ->"+ err);
    }
  }

}
