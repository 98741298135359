import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Logger } from '../Logger/logger.module';
import { MyCurrencyFormatterDirective } from './currency-formatter.directive';
import { MyCurrencyPipe } from './currency-formatter.pipe';
import { MyPercentFormatterDirective } from './percent-formatter.directive';
import { MyPercentPipe } from './percent-formatter.pipe';

export { MyCurrencyFormatterDirective };
export { MyCurrencyPipe };
export { MyPercentFormatterDirective };
export { MyPercentPipe };

@NgModule({
    imports: [CommonModule],
    declarations: [MyCurrencyFormatterDirective, MyCurrencyPipe, MyPercentFormatterDirective, MyPercentPipe],
    exports: [MyCurrencyFormatterDirective, MyCurrencyPipe, MyPercentFormatterDirective, MyPercentPipe],
    providers: [Logger]
})
export class FormatterModule { }