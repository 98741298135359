import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, DoCheck, ElementRef, Inject, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Logger } from '../Logger/logger.service';
import { EnhancedSearchService } from './enhanced-search.service';
import { ProjectScenarioDetail } from './project-scenario-detail';
import { Project } from './projects';
import { Scenario } from './scenarios';
import {cloneDeep} from 'lodash';
import { TraceLoggerService } from '../Logger/trace-logger.service';


@Component({
  selector: 'app-enhanced-search',
  templateUrl: './enhanced-search.component.html',
  styleUrls: ['./enhanced-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnhancedSearchComponent implements OnInit {

  colDef = [];
// displayedColumns = [];
 displayedColumns:any =  ['name','description', 'tags', 'updatedDate','scenariosCount'];
 displayedStores:any =  ['name','description', 'tags', 'updatedDate','storesCount'];
  dataSource:any;
  dataScenarios:any;
  selectedCreatedDate = -1;
  selectedUpdatedDate = -1;
  selectedItem = -1;
  txtSearch ="";
  projects :  Project[]=[];
  scenarios :  Scenario[]=[];
  firstAttempt:number =0;
  filteredScenarioData =[];
  filteredProjectData:  Project[]=[];
  orderValue = "desc";
 

  @ViewChild(MatMenuTrigger, { static: false }) menu: MatMenuTrigger;
  // @ViewChild('virtualScroll') viewport: CdkVirtualScrollViewport;
  @ViewChild('table1', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('table2', { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild('CompletePage') CompletePage: ElementRef;
  @ViewChild('matInput') matInput;
  @ViewChild('updatedDate') updatedDate: ElementRef;
  
  @ViewChild('createdDate') createdDate: ElementRef;
  @ViewChild('dateSort') dateSort: ElementRef;
  @ViewChild('dateSortScenario') dateSortScenario: ElementRef;
  ascSort: boolean =true;
  ascSorting:boolean =true;
  scnAsc: boolean=false;
  scnDesc: boolean=false;
  prjAsc: boolean;
  prjDesc: boolean;
  isElipses: boolean;
  

  constructor(private cd: ChangeDetectorRef,@Inject(MAT_DIALOG_DATA) public data: any,private enhancedSearchService:EnhancedSearchService,private logger: Logger,private router: Router,public dialog : MatDialog, private traceLogger: TraceLoggerService) {
    this.txtSearch = data.txtSearch;

   }

  ngOnInit(): void {
  this.getProjectScenerioDetail();
  }

  onCreatedDateChange(){
    if(this.createdDate.nativeElement.value){
      this.selectedCreatedDate = 1;
      this.selectedUpdatedDate = -1;
    } else {
      this.selectedCreatedDate = -1;
    }
    this.updatedDate.nativeElement.value="";
  }

  onUpdatedDateChange(){
    if(this.updatedDate.nativeElement.value){
      this.selectedUpdatedDate = 2;
      this.selectedCreatedDate = -1;
    } else {
      this.selectedUpdatedDate = -1;
    }
    this.createdDate.nativeElement.value="";
  }

  getProjectScenerioDetail() {
    try {
     

      if (this.txtSearch && this.txtSearch.length > 2) {
        if (this.txtSearch.indexOf('Project: ') == 0) {
          this.txtSearch = this.txtSearch.substring('Project: '.length);
        }
        else if (this.txtSearch.indexOf('Scenario: ') == 0) {
          this.txtSearch = this.txtSearch.substring('Scenario: '.length);
        }
        this.enhancedSearchService.getProjectScenerioDetail(this.txtSearch).then((data: ProjectScenarioDetail) => {
          try {
            if (data.projects.length > 0 || data.scenarios.length > 0) {
              this.projects = data.projects;
              this.scenarios = data.scenarios;
              this.projects.forEach((x:any) => {

                if (x.tags) {
                  if (x.tags.indexOf(',') > -1) {
                    x.tags = x.tags.split(",")
                  }
                  else {
                    x.tags = [x.tags];
                  }
                }
                else {
                  x.tags = [];
                }
               
              });
              this.scenarios.forEach((x:any) => {

                if (x.tags) {
                  if (x.tags.indexOf(',') > -1) {
                    x.tags = x.tags.split(",")
                  }
                  else {
                    x.tags = [x.tags];
                  }
                }
                else {
                  x.tags = [];
                }
               
              });
              // this.dataSource = new MatTableDataSource(this.projects);
              // this.dataScenarios = new MatTableDataSource(this.scenarios);
              
              // this.dataSource.sort = this.sort1;
              // this.dataScenarios.sort = this.sort2;
              this.filteredProjectData = cloneDeep(this.projects);
              this.filteredScenarioData = cloneDeep(this.scenarios);
              // console.log(this.CompletePage.nativeElement.innerHTML);
             this.cd.detectChanges();
            }
            else {
              this.projects = [];
              this.scenarios =[];
             this.cd.detectChanges();
            }
          } catch (err) {
            this.logger.error(err);
          }
        }, (err) => {
          this.logger.error(err);
        });
      }
    } catch (err) {
      this.logger.error("Error @ SearchComponent: getSuggestions() ->"+ err);
    }
  }
 

  onClick(projectId:string){
    this.dialog.closeAll();
    this.router.navigate(['/project/scenario', projectId.toLowerCase()]);
    
  }
  onScenarioClick(scenarioId:string){
    this.dialog.closeAll();
    this.router.navigate(['/scenario/edit/view', scenarioId.toLowerCase()]);
    
  }
 
  onDateSort(){
     
     if(this.ascSorting){
      this.dateSort.nativeElement.dataset.order = "asc";
      this.ascSorting = !this.ascSorting;
      this.prjAsc = true;
     this.prjDesc = false
     }
     else {
      this.dateSort.nativeElement.dataset.order = "desc";
      this.ascSorting = !this.ascSorting;
      this.prjAsc = false;
      this.prjDesc = true
     }
  }


  onDateSortScenario(){     
    if(this.ascSort){
     this.dateSortScenario.nativeElement.dataset.order = "asc";
     this.ascSort = !this.ascSort;
     this.scnAsc = true;
     this.scnDesc = false
    }
    else {
     this.dateSortScenario.nativeElement.dataset.order = "desc";
     this.ascSort = !this.ascSort;
     this.scnAsc = false
     this.scnDesc = true
    }
 }

  close(){
    this.menu.closeMenu();
  }

  /* onChangeCreatedDate(event:MatCheckboxChange){
       if(event.checked == true){
           this.selectedCreatedDate = 1;
       }
       else {
        this.selectedCreatedDate = -1;
        this.createdDate.nativeElement.value="";
       }
  } */

  /* onChangeUpdateddDate(event:MatCheckboxChange){
    if(event.checked == true){
        this.selectedUpdatedDate = 2;
    }
    else {
     this.selectedUpdatedDate = -1;
     this.updatedDate.nativeElement.value="";
    }
} */
onChangeMyItems(event:MatCheckboxChange){
  if(event.checked == true){
      this.selectedItem = 3;
  }
  else {
   this.selectedItem = -1;
  }
}
onChangeSharedItems(event:MatCheckboxChange){
  if(event.checked == true){
      this.selectedItem = 4;
  }
  else {
   this.selectedItem = -1;
  }
}


      setFilter(){
          this.traceLogger.trace("Search Filter");
           var filterDate = new Date();
           if(this.selectedCreatedDate == 1){
            filterDate.setDate(filterDate.getDate() - (this.createdDate.nativeElement.value));
           }
           else if(this.selectedUpdatedDate == 2){
            filterDate.setDate(filterDate.getDate() - (this.updatedDate.nativeElement.value));
           }
           
          
            if(this.selectedCreatedDate == 1 && this.selectedItem == 3){
              this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.createdDate).getTime() >= filterDate.getTime();
                });
                this.filteredScenarioData = this.scenarios.filter((d) => {
              return (new Date(d.createdDate).getTime() >= filterDate.getTime() && d.myItem == true);
                });
            // this.dataScenarios = this.filteredScenarioData;
            // this.dataSource = this.filteredProjectData;
            this.cd.detectChanges();;
           }
           else if(this.selectedCreatedDate == 1 && this.selectedItem == 4){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            /* this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.createdDate).getTime() >= filterDate.getTime();
                }); */
                this.filteredScenarioData = this.scenarios.filter((d) => {
              return (new Date(d.createdDate).getTime() >= filterDate.getTime() && d.myItem == false);
                });
            // this.dataScenarios = this.filteredScenarioData;
            // this.dataSource = this.filteredProjectData;
            this.cd.detectChanges();
           }
           else if(this.selectedUpdatedDate == 2 && this.selectedItem == 3){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.updatedDate).getTime() >= filterDate.getTime();
                });
            this.filteredScenarioData = this.scenarios.filter((d) => {
              return (new Date(d.updatedDate).getTime() >= filterDate.getTime() && d.myItem == true);
                });
            // this.dataScenarios = this.filteredScenarioData;
            // this.dataSource = this.filteredProjectData;
            this.cd.detectChanges();
           }
           else if(this.selectedUpdatedDate == 2 && this.selectedItem == 4){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            /* this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.updatedDate).getTime() >= filterDate.getTime();
                }); */
                this.filteredScenarioData = this.scenarios.filter((d) => {
              return (new Date(d.updatedDate).getTime() >= filterDate.getTime() && d.myItem == false);
                });
            // this.dataScenarios = this.filteredScenarioData;
            // this.dataSource = this.filteredProjectData;
            this.cd.detectChanges();
           }
           else if(this.selectedCreatedDate == 1){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.createdDate).getTime() >= filterDate.getTime();
                });
                this.filteredScenarioData = this.scenarios.filter((d) => {
                 return new Date(d.createdDate).getTime() >= filterDate.getTime();
                   });
                  
              //  this.dataSource = this.filteredProjectData;
              //  this.dataScenarios = this.filteredScenarioData;
               this.cd.detectChanges();
           }
           else if(this.selectedUpdatedDate == 2){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredProjectData = this.projects.filter((d) => {
              return new Date(d.updatedDate).getTime() >= filterDate.getTime();
                });
                this.filteredScenarioData = this.scenarios.filter((d) => {
                 return new Date(d.updatedDate).getTime() >= filterDate.getTime();
                   });
              //      this.dataSource = this.filteredProjectData;
              //  this.dataScenarios = this.filteredScenarioData;
               this.cd.detectChanges();
           }
           else if(this.selectedItem == 3){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredScenarioData = this.scenarios.filter((d) => {
                 return d.myItem == true;
                   });
                  
               this.filteredProjectData = this.projects;    
             //  this.dataScenarios = this.filteredScenarioData;
               this.cd.detectChanges();
           }
           else if(this.selectedItem == 4){
            this.filteredProjectData = [];
            this.filteredScenarioData = [];
            this.filteredScenarioData = this.scenarios.filter((d) => {
             return d.myItem == false;
               });
               
               
          //  this.dataScenarios = this.filteredScenarioData;
          //  this.dataSource = this.filteredProjectData;
           this.cd.detectChanges();
       }
       else{
        this.filteredProjectData = this.projects;
        this.filteredScenarioData = this.scenarios;
        this.cd.detectChanges();      
      }
             this.menu.closeMenu();
      }

      refreshData(){
        this.traceLogger.trace("Search filter reset");
        this.dataSource = new MatTableDataSource(this.projects);
        this.dataScenarios = new MatTableDataSource(this.scenarios);
        
        this.dataSource.sort = this.sort1;
        this.dataScenarios.sort = this.sort2;
        this.createdDate.nativeElement.value ="";
        this.updatedDate.nativeElement.value = "";
        this.selectedCreatedDate = -1;
        this.selectedUpdatedDate = -1;
        this.selectedItem = -1;
        this.filteredProjectData = this.projects;
        this.filteredScenarioData = this.scenarios;
      }
      tooltipOverText(parem): boolean {
        try {
          const elemInnerText = parem.currentTarget.innerText || '';
          if (elemInnerText.endsWith && elemInnerText.endsWith('...')) {
            this.isElipses = true;
          } else {
            this.isElipses = false;
          }
          return this.isElipses;
        } catch (err) {
          this.logger.error(err);
        }
      }
}
