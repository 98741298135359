export const APP_CONFIG = {
  googleKey:"",
  username: "",
  token: "",
  hideScenarioPopUp: false,
  lastActiveTime: new Date(),
  tokenExpiryDate: new Date(),
  _gisToken: "",
  openDecisions: [{ name: 'Renew' }, { name: 'Close' }, { name: 'Expand' }, { name: 'Relocate' }],
  pipelineDecisions: [{ name: 'Open' }],
  LOG_LEVEL: 3,
  CONSOLE_LOG: true,
  SERVER_LOG: true,
  googleMapBaseId:"e114276d213583cc",
  oneStoreReport: {
    CO: 'https://sbux-viz.starbucks.com/t/SS/views/OneStoreReport/OneStoreReport?Store_number=',
    LS: 'https://sbux-viz.starbucks.com/t/SS/views/OneStoreLS/OneStoreReportLS?Store_number='
  },

  projectsColDef: [
    {
      field: 'actionButtons', header: '', textAlign: 'center', sortable: true, sort: 'asc', class: 'table-light d-flex justify-content-around'
    },
    {
      field: 'Name', header: 'Geography Name', textAlign: 'left', sortable: true
    },
    {
      field: 'Comments', header: 'Comments', textAlign: 'center', sortable: true
    },
    {
      field: 'rgn', header: 'Region', textAlign: 'center'
    },
    {
      field: 'mkt', header: 'Market', textAlign: 'center', sortable: true
    },
    {
      field: 'miniMkt', header: 'Mini Market', textAlign: 'center', sortable: true
    }
  ],

  scenarioColDef: [
    {
      field: 'actionButtons', header: '', textAlign: 'center', sortable: true, sort: 'asc', class: 'table-light d-flex justify-content-around'
    },
    {
      field: 'Name', header: 'Scenario Name', textAlign: 'left', sortable: true
    },
    {
      field: 'Desc', header: 'Comments', textAlign: 'center', sortable: true, className: ['col-sm-3']
    },
  ],

  storeColDef: [
    {
      field: 'actionButtons', header: '', textAlign: 'center', sortable: true, sort: 'asc', class: 'd-flex justify-content-around'
    },
    {
      field: 'sNum', header: 'Store#', textAlign: 'left', sortable: true
    },
    {
      field: 'sName', header: 'Store Name', textAlign: 'left', sortable: true
    },
    {
      field: 'sType', header: 'Store Type', textAlign: 'left', sortable: true
    },
    {
      field: 'scnDecision', header: 'Baseline Decision', textAlign: 'left', sortable: true
    },
    {
      field: 'revDecision', header: 'Revised Decision', textAlign: 'left', sortable: true, className: ['bg-secondary']
    }
  ],

  projectsColDef1: [
    'Name', 'Comments', 'rgn', 'mkt', 'miniMkt', 'Id'

  ],
  color: {
    'edit': 'orange',
    'add': '#999900',
    'save': '#00cc00',
    'cancel': 'red',
    'plain': 'white',
    'rowEdited': '#378CC4',
    'rowNotEdited': '#a9a9a9',
    'archive': 'red',
    'closebtn': '#A0A0A0'
  },
  dashboardChartColor: {
    'sbgrey': 'rgba(116,119,122,0.85)',
    'sbgreenGeneral': 'rgba(159,205,157,0.85)',
    'sagegreen': 'rgba(157,193,131,0.85)',
    'olivegreen': 'rgba(57,255,20,0.85)',
    'archigreen': 'rgba(1,121,111,0.85)',
    'lauralgreen': 'rgba(0,165,114,0.85)',
    'teagreen': 'rgba(0,78,56,0.85)',
    'black': 'rgba(0,0,0,0.85)',
    'lightRed': 'rgba(247,75,12,0.85)',
    'lightOrange': 'rgba(255,178,102,0.85)',
    'lightYellow': 'rgba(255,255,102,0.85)',
    'lightBlue': 'rgba(149,200,216,0.85)',
    'lightPink': 'rgba(255,102,178,0.85)',
    'lightPurpel': 'rgba(178,102,255,0.85)',
    'azureBlue': 'rgba(0,128,255,0.85)',
    'babyBlue': 'rgba(137,207,240,0.85)',
    'darkGrey': 'rgba(169,169,169,0.85)',
    'lightGrey': 'rgba(211,211,211,0.85)',
    'portfolioBlue': 'rgba(79,121,165,0.85)'
  },
  dashboardTextColor: {
    'textcolor': 'white'
  },
  priorityColors: [
    {
      'priority': 1, 'colorCode': '#B5E61D'
    },
    {
      'priority': 2, 'colorCode': '#99D9EA'
    },
    {
      'priority': 3, 'colorCode': '#C8BFE7'
    },
    {
      'priority': 4, 'colorCode': '#FFC90E'
    },
    {
      'priority': 5, 'colorCode': '#FFABC9'
    },
    {
      'priority': 6, 'colorCode': '#FFFFFF'
    },
  ],
  defaults: {
    project: {
      priority: 6,
      prioritySort: 'asc'
    },
    scenario: {
      priority: 6,
      prioritySort: 'asc'
    }
  },
  userPreferences: null,
  currentUserPref: null,
  compareOptions: [
    { name: 'Sales', checked: true, id: 'SALE', additionalChartIds: ['SALETable'] },
    { name: 'Total Contribution', checked: false, id: 'TC', additionalChartIds: ['TCTable'] },
    { name: 'Cashflow', checked: false, id: 'CASHTC', additionalChartIds: ['CASHTCTable'] },
    {
      name: 'Expenses', id: 'EXPENSES', checked: false, children: [
        { name: 'COGS', checked: false, parent: 'COGS', id: 'COGS' },
        { name: 'Labor', checked: false, parent: 'LABOR', id: 'LABOR' },
        { name: 'Occupancy', checked: false, parent: 'OCCUPANCY', id: 'OCCUPANCY' },
        { name: 'LHI Depreciation', checked: false, parent: 'LHI', id: 'LHI' },
        { name: 'Equipment Depreciation', checked: false, parent: 'ED', id: 'ED' },
        { name: 'Other Costs', checked: false, parent: 'OTHER', id: 'OTHER' }
      ]
    },
    { name: 'Store Growth', checked: false, id: 'SGROWTH', additionalChartIds: ['SGROWTHNew', 'SGROWTHClouser'] },
    { name: 'Relocations & Expansions', checked: false, id: 'SGROWTHReloExpan', additionalChartIds: [] },
    { name: 'CO Renovation Types & Spend', checked: true, id: 'SGROWTHReno', additionalChartIds: [] }
  ],

  expenseCategoryLabels: ['COGS', 'Labor', 'Occupancy', 'LHI Depreciation', 'Equipment Depreciation', 'Other Costs'],

  storeImg: [{ 'storetype': 'Cafe', 'imgUrl': './assets/images/CO_Cafe.png' },
  { 'storetype': 'Coffee Stand', 'imgUrl': './assets/images/CO_CoffeeStand.png' },
  { 'storetype': 'Delivery Only', 'imgUrl': './assets/images/CO_DeliveryOnly.png' },
  { 'storetype': 'Drive Thru', 'imgUrl': './assets/images/CO_DriveThru.png' },
  { 'storetype': 'Drive Thru Only', 'imgUrl': './assets/images/CO_DriveThruOnly.png' },
  { 'storetype': 'Express', 'imgUrl': './assets/images/CO_Express.png' },
  { 'storetype': 'Kiosk', 'imgUrl': './assets/images/CO_Kiosk.png' },
  { 'storetype': 'Reserve Bar', 'imgUrl': './assets/images/CO_ReserveBar.png' },
  { 'storetype': 'Reserve Store', 'imgUrl': './assets/images/CO_ReserveStore.png' },
  { 'storetype': 'Roastery', 'imgUrl': './assets/images/CO_Roastery.png' },
  ],

  storeTypeToClass: {
    'Cafe': 'cafe',
    'Coffee Stand': 'coffeestand',
    'Delivery Only': 'deliveryonly',
    'Drive Thru': 'drivethru',
    'Drive Thru Only': 'drivethruonly',
    'Express': 'express',
    'Kiosk': 'kiosk',
    'Reserve Bar': 'reservebar',
    'Reserve Store': 'reservestore',
    'Roastery': 'roastery'
  },
  userProfile:
    [
      'Project Landing Page', 'Scenario Landing Page'

    ],
  storeTypes: [],
  OwnershipTypes: [],
  scenarioDecisions: {
    open: [],
    pipeline: [],
    projectType: []
  },
  storeIcon: {
    Pipeline: {
      LS :  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABEZJREFUSImNlWtok1cYx3/n5NakSVobL23dJgXbrbbUrq6b3aWbTEWcjE2HEwaDCWMfBJExtqkhaomI+7QLE0HHxtDhplaKMJjzg1BUOjVd64WYLqv10lbLWltM3iyXc/YhfWNqo+759PI85/ye//mf95xj5SGhtXYDK7TWLwEVQgi71npICNEDHBdCDD1srhnWAlCv1vpzYBPgFELkannfe5VSPwshtgoh/v5fcK113aSqqseIEkKIdcCbWuv3hRBHHwnXWi8ATgMlDzRkYnyceCyGvaiI0tJSLBaLWXYBhycbHCgIn/S3Ix+cSRhETvxKKtxLyUAPRfFh/q2sp+/pF8jU1FPVsBCXywUgtNb7tdZXhBChQvBPhBDzzaRxY4CL+79ifvQEVn3v/uhrw7ivnYQTNq6v2cms1tfx+XwIIRyGYRwAFkyBa62dwMc5xf+M0LtnN7U3fgedKmQl6BSVRz5lUHyBc/kqXC4XTqez9ty5czubm5u35itfBngmDSb6wx7mDfeATiHr38LavNQ0Hx0bJ33yEHrsEgCVR7bSX/4kdYtbACguLv4sGAwe9fv9IVP5y+Yvlr51nVh/mPLkLQAsNU0ULV2FTiXR6TTS6UK1ruDexpbsqnQKy9VeMs3PY7FYqKiosCilvgRaTeWVOUv6rmBBTXMh3d+HEVyDZ9+fyLKZiIpn0YN/AOC91Mndu+/g8/lwOp0Ar7S1tb0WCAROWYUQdhOiRkcKWmyZO4/i3ScRjiLUxDh6qPt+bSRMMh4Hnw8pJQBSyjXAKavW+nbu5Nnt6EL0TIbMyB3S0aukfjs4ZaMTjtmUerJbZhhGVqRSWVuEEL35CjPI6ezhmyR2ry64qvGqRTxVkj0eY2NjZroSsp4fBzQgrDX1zFIxRtz1zLp3qSAsP9LCja2uMXfn9PX1mSWHqXxYKXVYCLFWuj3MXruBy0d/xG2bCe0BUu2BwmRhI1q9kqblbwAQj8e5cOGCWR0ylSOE2KKUeltKaXO0LuOZm/2Eu6AkWV5wBXH7XAbmNtHw4UYsjiIAzp49SzKZNIf05sOjExMTmzwez7dIieu9j6ibX8udQ3v5y9aCBYV52WaQuBcupnndB0iPF4BIJMKZM2fy+x/LwQG8Xu+e7u7u9Q0NDYuklDgWv8oTTS2UD0RRtwchnUaUzMBaVY0snZGjRCIR2tvb88E3bDZb+xQ4QEdHx7vRaPTykiVLHGVlZQi7HVt1LVTXTrPGMAy6urro7OyckpdSbtm8eXNiGnz79u3Rtra29eFw+GBjYyM1NTXMmTMHp9OJlBLDMBgdHSUajXL+/HkSicSDPff5/f7cvT7tmQsEAj/t2LHDGwqFvgmFQtPqj4jvDMPYkJ8oOHnbtm17g8HgRaXU10DTY6CDgD8QCHz/YOGhyvx+/2nguWAwuFIptRp4kezJswHDQI+UsiMWi/2ya9eueCHGf0nurJ8ceDkoAAAAAElFTkSuQmCC',
      CO :  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABFBJREFUSImNlWtsU2UYx3/P6dqt7drNldsGjElwhIGTi50BRLdEgQjBqEH8YDSiCUE/QIyJAk2BpcpHjBeCUSOSTcNtupBgTIyCZMAY6xgEWAoFBu6CjnUrtGeM7bx+GKd22wF8vpyT53nP7/m///dyMrhPKKWygaVKqYVAvog4lFIdItIMHBSRjvt9a0aGBdSrlPoIWA84RSRVS3vfaRjGHhHZJCKX/xdcKTXznqpHHyJKROQ1YIVS6g0ROfBAuFKqBKgDckY0pLe3l0QySVZmJrm5udhsNrPsAvbda1BlCb/nb206WL9zh0NH/uB06xWaerq4agzg9+Yyf9xEHi8s4omZs3C5XACilPpGKXVeRMJW8A9EZJqZbO1oZ/vean7Wb9H5n+Vcisf4MR7DHT3HjptdPOd/Cp/Ph4hk6rpeBZQMgyulnMD7ZuKfnhifVO1iv9FHQrCMhFK82XyC3QLLF5XjcrlwOp0zGhoaPvb7/ZvSlT8PeEx/v6jdz8nBOyQE3imYzOLpM1Pe39KT7D4b5s9kAoC1zfVMzhvDfH8ZAG63+8NQKHQgEAiETeVPm1us9UYnFzrauCAKAP/EQlaULaB/YIC7A3dxZzlZOsfP9K+2k1CKhFI0X7tK2dx52Gw28vPzbYZhfAo8YyovMKdx/q9WDAsbIm3XWbzney6v38iYnFye9eZwqLcHgCPtV1nZ04PP58PpdAIsqqysLA8Gg4czRMSR8jvea+lx0bgJnHzrXbIcDnpv3+JI2rizidskdR0foGkaDD1fAQ5nKKVumLY4MuyW8MHBAW70xGhpu0ZV4wkSSqVqBWLDk50NgK7rABiGMWSLiJxJKRw7Dk0BI3bJ9ZtdLKyptmxcljeWnJyh4xGLxVI9Ycjzg4ACZNaUqcQzbJQPwmGxcn94TFAwZ8rU1J1z8eJFs5RpKu80DGOfiLzqcblYV7GYXb/9wjQFaxrqWNNQZwl2AyvdOSwrrwAgmUzS2NholjtM5YjIRsMwXtI0zb5krp8rXX/D6UYmKesZzFDCQoeLdateJ8uRCcDx48fp7+83h5xJh0fj8fh6j8fzpaZprF2ynJKCSez4/VdW6H0YaWugAfOnPsbby17E6x5ayEgkwrFjx9L7/5SCA3i93h1NTU2rS0tL52maRnnpHBbMmMWljjbau7u4OzBIXnY2xRMn84jHm6JEIhFqamrSwdftdnvNMDhAbW3tqmg0eq6ioiIzLy8Ph91OSWERJYVFo6zRdZ36+nqOHj06LK9p2sYNGzb0jYJv2bIlWllZubqlpaV69uzZFBcXM378eJxOJ5qmoes63d3dRKNRTp06RV9f38ieXwcCgdS9Puo3FwwGf9i6das3HA5/Hg6HR9UfEN/quv5eesLy482bN+8MhUJnDcP4DJj7EGg7EAgGg9+NLNxXWSAQqAOeDIVCLxiG8TKwgKGTZwc6gWZN02oTicTebdu2Ja0Y/wJxgrVpAT9DFgAAAABJRU5ErkJggg=='
    },
    CO: {
      'Cafe': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAApxJREFUOI2VlF1Ik1EYx3/v3ldf0yIzMcVCKI2CupEMIgJZLKqLcIHWRRcJ3WSwZeTHgsy6cDemTalugpC+tNT1gVAMh12ViUZBURiRRiyzMi3njna2LqztnW6Y/7vz/M//x3N4Ho5GLNlIRLFYQVp11HyBzALQUX0C2Q+qm2SPGyczc6PaPJi90AqmepBrAQQybAlkHpAH8iB+8ztsnKDJ+yAeUNFt5joB1TG7nq9cFO7rNss50eSpBUJRQN1mdgqFqv+ERbpWZI1ut4SEy1MbAZYXFong4mFhKLIGu7kPl7dLw0ECflO98YI9bzupKcviAiYDU9S/eWwsKXoo2CCcPNLwW/aDXGd0Tx8qZ+XyFfGBU37qHVFAhGJaj89SpIEsWsTrAFBVlZfHmklPTeNmt5uTT9v/OtKqAVsWC0xK1ElZkszo2FfKrKU093cxNDOFrqgFGpC1WGBgWrC3+TjZSUvpPnudrWmrGRoZRCCz5i82IKWMVQ5LTAveip8c3rwTf2CKOyODYU8DfECuMfDl+ygZaelxgT8mxilelUfpngP0vX4eruuoPg0YmAt82NfDptyNcYFrsrJprbrEsO8jpzpdkc6RAxoh7qJQYgxU9Lazu6AwJjQwLWhou8zY5MTcXQRUt4bf20mKeQjIMVpHrp6hw+4iOyMys1/+SZw3mql75YnV+HtCHrfGFQQ2qlG4ZXR7J79RerGKXRu2hWvPPryIGkC0ghU0MT075SZvK3bzDqDMeMUz/glPb3usdJR0gueFq6cTjN9XptfOZ7MCHF2QYISZ1Aui0Vv57xwBOvgN3jLshU/A5ASyF2ANg1opGj1txuL8xXb1XMNR3EFgtIRgwj6Q+UAmsx/oCKj9hGbuMZ5zm5aWwNz4H89R8C5yKBNHAAAAAElFTkSuQmCC',
      'Drive Thru': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA2tJREFUOI19lH9M1GUcx1/3/X7dCcwodBKgmTWckyN1lI5BTE4pdKu2NEcOmkVaMwbTzfVbcvzhBubtmFAz5sAWzTPK5TIL9x2yULbWVhFeQNxkEadoDjy5u6e77/PtD7pfHfj559ne78/zep7P5/mhMV/UV6QwNbkZhTwr6oNIIyAUJrIs9HiP6b/NN01LUl7buISFaYeYmqwGUpEgMGY9CV7AWmf/XaDW4+x23RNoPfDkZmEscAEZ8+4cELAajNPU2nfjVyto676TDKzb9LwwlNP/XyQvJZ2ipbkYUnJ+YhCvIWKmha2kGX28bS/miD4dBWbV2R/zwqfxsI9KXmJboZ1lmTlI00RVFELhMIMeN83n2mkf/yWSamOGz4FtUaAXWoDUSMbeFY+z57lKzl76lndONfKZ9ypWReWN3CJeLtvJ+xW1tB+tTtxpXdlOnN0uzbr/6S1Chorjy9xRWI772jA7vnbE+iYNjg31MnRzjLPvnuCFzFzO3BiJ9R/jsACXJmRoezysKttGwZr1/DE2yskt1cwV07477Huqil9dTQwJX/Sgsg7YbZoVSiJtrl9XzntV+wmHwxSsWceG/II5gYFggJL1hVzMaWFD057oQXlNNmkCsiOJ5QWl3Lx9i9Kjr1O2zEZzTQMWiyUB5hm/RrFjH3vzS/lg90EqVxXS5O6ZLVuSrQEpkeQFqoY/GGBI+Hj473FM00wC+vx38RoCz+Q4AKnWhTFTUVM04AbwEICUkkeXr2TszQ7uS1uEoihJ5a5dZcNz8CSL75+9+6GQEfUExnUNk0Ess8B2vQv/P0GK125EVVUGRq4ydXc6AZj3yGpW5qxgYvI6vT9d5kO3HjMlgxoWzgFbAVo9/bR6+gk0nkdVVbr6vuHwzxcSgAM1x8lIf4DhP0d55svGeGuGqeUXNVLpxE8DsDhqmeZ/g5lUshn1ZIJuVdRPREdHUOOIPk2t/RAWWiKm84s2lqZn0DXcnwQ8caGTJ0by+X7gh3j5ljBmGiDydpv1VmrtRVjYBfDWj18lgSJxfPQKjF6Jl0JWRW4Xjsu3Y0AAv/4KaXYJVM5LSw4fsEs4enojQgzYhgC9ijp7H9AALLknSlF1pFKD8zt3vJz8Yzv1j3m1rJNFxotIngXygEwgiMJfSC5ZkWeEQ++Za51/AaG4S4ZrIRiEAAAAAElFTkSuQmCC',
      'Drive Thru Only': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA31JREFUOI2NlH1M1HUcx1+/3/2O46G6Di7uAUkqfARMW0U1ZechOsylpkOsTOhhbGgcsmVrJYxytNV2dTptLmg1NwTGmps5VzzsBtmsZjULs42iLDjkQdA6zu8d97v+wDuOO3G9//p935/P5/X9/L7ffT8K86muNInJkUJkcnRozKhBn5AZski4Pc7un+crU+KcinwjiSm1TI68CCSjgiA4E1PBA+gc9ksCTR2ujrbbAnU1awpFUNsGpEb7RfoMev4ZRqgzYAFLIdhKlb2MKU0pjR3X44EO29MiKLfGbrJ/uZ0Dz1Xzh+cypY1v0Oe7NhuUKCYleJbX7at5p/taBGhx2Fd44Hgs7P0ndlC5tYwEbQK5DyzjjOMQFccOcGbicnRaLl5OABsjQA8cAZKjs048uYeSwi3IshzxMs0LOF7t5LXGBpr++n5up46iElwdbYpu34Z1Qg2sjoZ1PltH4SMFAPw9PMjgqAdZ1rAkK5s0vQFXZT2W5sMc/OnL2fMnWC+gTRFqYFvYzE5Iof2lBh5cnAuA+/xZVFVl6cJsRMDPJ6dbKN+0kzuT76B2dw2mk0Ze+ao5clGWGnuuooMCcRP4RdUh7l+QNZPg9/Ptrz+yw/YUgelpZElm1aI8zl34jqLH1qJVtFRuKyclMYkXOpsA8ISwKQKs4Q6dn31EXuZiKrbuxtn6ISuylrHugz30+70AvJm3ngyDGcPFH7AazbhOfswvVwZmf1vFqgBJYePI7+fYK0kAjP07ycPLV5LXY6F/tB+AlfflkJluZXRinDR9Ku9e7GaOZE2SAlwB7iVGm/PXs6qhjP35W9iZvxGAU+c7MenvoWpzOf7pQGwJguCwQog+pHhgwUOPY2pPpPjRtSzJWgTA0NURsi0LyTBZGRj8Mw6ISp8CfA4Ux0fhdPVRjIa0yHrXhu1oFe2tUgGmuDu9S0GnNuOX3+bm+x3zThAKhZAkCWu6eU5Fqt4Q+faJG7HAJupbfArvuSdx2OpBdgG0DF/Ce/BljMn6+ToB4OuR36KXV7nhfQvCb9flPsw+2xpUeTvAqfEBGL8tL1rTOk2gRBz7ZmwWCCHuMj/P9WHC0P+pKVB3CWdvV9iYnS71LT6gBIetCuRaYmZinEL0WiT2elzuC9F27MQO4XK7eNX2KUJ+BtiERA5gAnw6GBLQo9ME2oWzt8tzi33+Az7dKXFZJbXzAAAAAElFTkSuQmCC',
      'Reserve Bar': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA75JREFUSImllG9MVXUYxz/3cM49FyoSIujWtFWQLQ0QAhqJMAYR6MT8O7JZL5jLJvcOyhdtrGWzVdi4cpczX2TTkEFsGQu6S+LCYoChkY3IZKXN5o4rdwER8HfxnNsLdi/cP5jO78vP8+xzzvM7v/PIRI6EreAVTIoN9MxFegCGkKjH4W4CfKFFORRYdxev0KL0RiAd9Ft4AcjAoBF7gR30Chp6/1xcXl1Yphl6M3Df/1mDI2WB9KNaU/iSqHf3hslVe3GuMPQTgPnOxIE8IHS+tVYX5moO9/C8vCYzUejcjdifezWDdvYUpLG/Z3xOrt+/F/TEuxT7s0z1UivgLRlb4TKgMrRDq21CUUIG8fnAZApCR10tvHmqNYgJpCp2ldbLYGwEKezWJMY/iCRJQaz/7CC56dlBLCE2DnvK84xPX+Po5WE/NmMRG2VMrL2dWa9PT7Gz5UMGlzcSEx0T4OVrStlRtg3DMFjr/oat7c65gsE6GaQnFhPe8AqEVwBwemSIkZkJBn49Q8byVAAsZhVFVmju/IqMJ9Mozy/F6jpsaLqQgGQZSFpMrus6H7ccYt/wyQArOr4XgNpnXuDtl6twDXRR0XGQuqub2FPxBqmxSYY2dkkCHpIBI5LY5/NxT3QM77xaQ+KJBGx9TYHa/pzN2DZXYlYUrs9MAfB40lKEV9Azcdn//QwZuAIkh8qvTU0SF7sERVZIT14BffO1nKdWYVYUAK5OjlGyZClFWfmcuziKMOZWhuozNBk4H0l+7uIouWlzN2Pw/M/sfPRZXi/dzpHOLzn1+xB5q54DIDMllR0lW4iJjuZT1/x0wiSNykA7hN+YI9+3kr0yA4CUhx+jatPcMThSnubkQDfeWS+6rpOzMhPPuId9Xzg4/NfggnONapPxRX+NaeYAoC6Uf/b3ECXdHWwpKmf9mhcDXI6SKVtdzOzNm9Q1O3n3rCv0vQBmsHjaZJwdV1RbwUFhkmpCO7a2O+lLsIb9OFMz09Q1f8J7v3wXSYwK9aLup39lAIH3fbBsAx4JbSw8VsuBC9tRzUqAdY0McFz7LaIYuCC86kfg34rOfk+8PW+DB6UXsCzsFIbOrh+OLSYKzaRVj1qvHXJNzssBT0PvGbUmb53QlVYg7nZt84n6R5XYoDV0jvhJ0MIS9b1d2HKzMVk+B1bfgbkbn/6acLgvLYRh2xBn/x9AHtUF5RjsBikfUML6YBaMHpAaaHB3RHpiuNwfR08b0EZlcawaeyNL6IoVjHhgTJVUTVhmT/NBz8StxvkPsAJOXO15J74AAAAASUVORK5CYII=',
      'Express': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAxtJREFUOI19lH1I1Hccx1+/h/r5UJqWdWcWFUYOpX82ktHDrisJe8QeLpHpNgqsER1KMypmmYwmgXRJY0EFFWS6VfRAT5d26xgr1/4xtYcN1hrrnIVp5d19u/1+1x+hyd3Xff78fN6f1+cBPh+dkWx3cSJ9PYtQyTXQbFhmSKg8tSv4AvWtHSOl6XGe8vwJJCRX09ezAUjCAoH5LmZBADDczgcCbTceb/P/Ao3K+YuEOaoZSB/u35lbQNZ4G1/eOgmAgBwwm9jq/JygVswR78t4oNuxWphqU2yRD5PS2PPFNkbpOuGIoPKXYU0pFJJs/swO5zz2tfYPAe1u5+wAnJSt4E/xmme9z0lLSeX37r9ka8tjgEZg6RAwAIeAJJk6Q0+g6eZ5LnX5ae0PyCTvOnUXuPB4m3WjYsliYUXmyXRFGdkc3FBNlm0ySx87yTmwSQ4EDMwaAc26sCJrZILSzDwaNteSMmYsALOmzaRuzmq2t52VAgXk2CudeboBC4RE8NnCNfT2vyAogtgn2AiLMBuXlZCgj6bq9g8Iy4zLCURx6AIyZRXzZuRw7c5Nij5ZBkBL20+MSR7LxpWfkpGaTsmV7+LHtsjUgUQZ8NjlRn590kVpoQsATdNwnPgau2YQMGUzAaqWqAP/AlNjYzt/u8C+j1ahKAoDoSAF+QvZdf8u39zzymGAwOzWidKJEg8E+CArG4C6xgaqirewzbWZtiddePv/kRMtOnXgElAoBU6fRTAcorb9OmnJ46hYX07ZxyvwXv1eJg8ybmKLjmGd4o1aS8z9rps0k+wp07l97y4A5zp8VKwvx5Y+caSJj1JzOqSz39eH21EDqmd4tGxuEaqqcrHtBgD+Vz2E3wjGp6bJYL2EB/bC4O16fA1UOOZjqWsHFYqi8OjxH3zb2TKU1f6wg7+fxZ3ff4YWcYnDd56/B0KUFFsZL7sZhC4/UxfXRv7RqlhXEKxSUe8fqvr+u9ScDgEu3I6toFYTs9M4i+K3K2wJeHztw92x7yqKx+fhK8dxhFoCLEchF5gEhAx4KuCWoUV+FPX+FtnveQtZUw8IzZWMDQAAAABJRU5ErkJggg==',
      'Kiosk': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAoFJREFUOI2dlEtIFHEcxz/z2Jdha8YqiVIeJNBDoQVlmjUgBfaSOqRIEHXpwS56MIpQsBeVCe4hMkgPiyBIklIUCNtadOzW1gbhIRDNxAdm26j/mQ7boK6Oj77Hz/c/n/n9Z+CnYpfGsx4mRspR5HwXSiaG+K2bDIESIdj/2e4xdRmpK8rA9DYwOXoeSU7BAB2R6CQAAbVaFMNooDXSs6rQ5S8/qgvRBXhtJwcwKAD5OX6tFzw1BF/9Wi70a1W6JEKAsqpscSROQnwAf0WZJVUB0gOle8ahfUOyhRQixUNApSWUxlEeA+7/kFk5hf/QCYKRPpWAVgHstZr6fI1LFTV4U+0/o2EYfBqMUdV5m2GhJ6AkNwF9KqZyGinxFy/kFNJ4ro4Ut2fNkcoKi+lxNLH/2TUL7eJKcZ6KJA5apCi3gC+DX9d9TxmJAo+XaHwqAZxuTQWyrAOX34XgXQiXrKAbwlZk17uEkqUCzsXwSFoOL2600fmmm4vhjhWF3+o7+P5jiAMd15cWkkhRQRkDkWGxvK3ZuJ0ucrdtt53Ol+4jrv9Z1umyMqyCiAIZyWXJ7n3EfE8QhrGEb0n14nI6k4//uzJRFVl5iSEOW3BsZgLTNHE6HOzckbfigwD6/GwymtI39YdVHOMhdG8jsBmgayRGdtstMr0+WxnA69iHpdPBU/0ecyoPPv7Er91B4r5VNscGVpUtjzKqO+fugrUcguFmAloJcHyDJoBZlyLO6A8jkwtCMFBSq5if7kxskHVnGqjWW8LvLbCwvlp6Z4BKAuV1IG4CaWvI3mIqV5O3d/LGNmntf0RtaTvCUY3CMQzygUwgjswQBgMujG69NRJZ6S1/AUBvzooLfE8KAAAAAElFTkSuQmCC',
      'Reserve Store': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABNFJREFUSImNlX9MlHUcx1/PPc/xAIcIJ+JhadjUQMEVriXYEKjLbDL1mthcIoVKTj3UkZU2k5xR0U45f01X0rRIc/4acw5JBRYEc2KJib/yB+hA46fIj4fjuesP7oCTu633dtvz/Xzfn/fre9/nx1fCm8xxelnQmRXUd4EoTxYZruEQjyl07sBa0eLJI3moCZiNaxHUzQpqkNcFAApMQVCngO8azAnZWEvyAId3wPp5OtSuQ6AueCbr/pzg8TXRhhd7/Hxk+V5zo66k4fqk+6oyzjkfhKDZTmZSLEGhaWQf7h4OWIaM2lEEzHSV9KL2t7PpX9dOn/LyW8DcZ6COuw/u1X552Nr344O/op21FNoej+YzZpODzR2gS9o/JFzNiphlzf1o8zzgTS87JEx4PjwyP8vCoopzdXN+/WqsMy+RzqRdcD5jEGBOSgZSXZ174pdsWWlK+xTQeQl309txb4yvCR3bEr1rtb4fzQrZbDyuWIuLJPYjyn/zjeI0J+tf2LfSlLbKU3j70ycICAQGjBgGiZoYqT+anNm0sDAvBEAR1FygWJJrZycq2CKdvvpjG/dpAYOnlV68dhmtpGVWTJzHf2JKnBsy+/fjbUWt9UFAtJyZEC8pqm0eQr8hxRBxSCtpP/HU7HA4OFF5Fn8f2StAo9Gw4Z10uejnLc6Kdr6EwID7i5TMXkB0jZtam1FsvQC0tLey504lAKm3rhGo698mX1lmzKjQAci0SVP9XNcKvC4BYc6xLSJ84jiGyO6wsyF/GwUNtW4rnbZ7DQCLwyLZsWKL29yokcHoRa3aotpENGqYBIQ4555oNJoxQ82h+tHsXZ2D4aAFy40ytyDzxDi2pn1MYECgW10QBCL8g20VHY9F7IRIQLsT4g/08IwCdSNYZ1qOJccdsG7B8mHhLj1U2p3vl9gmAY1OgF9j8+N2w5D9dOl2/R0AXpJHECBKXOpq5Vb9HcKfGz/M++RpB/d7FSdAbZSAapxfy1OlZ7ozTEuHNVVer2ZTtJG1puWIosiuEwcoqanEOCNhmPdm3T+DA4dYLeEQCxHUVICN5UdmZpiW/guMdnlsfX3MiIghPiYOjUYDwKbUtZT/WUWP0oOv7OsG+KX0VDfQ/ySJtpMSkv8Z1I4mIKRFVV45UnyyYJFx/uKdR3/AXF4w2PnTsMUOyDpzMWsWpnP5+hUsN8pcj2kjgYazEpZTnWQmbAVNHsB7p3eaYqdOrzCXF3h+mzzIXF5ASlIyKQc29uH6vjnEzWQf7u4f+JfspcuYCup0wHfyt+mTazP3XomYMHna/wHce1jHEmtWz+3ebtd+VRBVfIABWg421jMflYuAQUENicxbGfx94gd/vD9nYYzsI8uegpVehXNVZT2mY7k+CqorvA50JlagDgIALMUPwtYnGRv6KEQgHBCXXciPXVV6sCtratLVhOjYAIM+NFgjaHRN7S2d1beu9Oy+dDr4dm/nwF2WHfabio9fMt8VPnLV3I7MBsv5q2S89iq+unycJ5hiV/231RRHbaspHmod6fwNkXhCke0fknumbWh1+KG/r6oJSJbNCYmKqP0cuxrv0dcvFSiTNfZsZfv5Uk8Gb40o1pILwAXMcXoEnRGNOkG2iwYABfURDu7S11nMnqpmxVsI8B/IkKyXd/GJJwAAAABJRU5ErkJggg==',
      'Roastery': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABTdJREFUSImFln1QFGUcxz+3u3cHd6CAqEBxviQKApo5Y/mCAomKyYj4lqaio2kO5YmhJmOkNmrpiANqBWk6OZbvho2aoohmOJSOJvjWKPmGIIJvyMvesXv9wS3yOv1mdmaf7/N9+e2zL89KtFULBnkZdeYFMsp4IKQNVqER8YDsqEonPe9JawSpFUzHgqiF6JQUGcWjzQbqK0RGCUHnYiUx6ks2Zm8EHG0HLBprRqneCcq4ZkZ3oz0tBX19u1e7Gl1dih4/dMstuRFwV5H9nfMeqMoGrJFhOFynk37kZcuAORhRKo8DgzXIS9SfPDH7q+v9e785Ahhzp/gugiBg8fUHcPz74M71VbvT63Y8+DvUKYlFV3OCOUSwFblpgHtUBqqimStJgcPS13+UMhYYrlHOXcnHoDdoAbpur3cN2p6UyuS8U/ei967xc/oNxByZCTnxrwISI0ejKvGa0TdDp6+YHzfzM8CsYTa7jc3n9uOhNzE+IgZRFBt6GzXoXUtBJ78noZs/9nJCM7BG7iUt54gECKh8rZFjvLpkzI+bmQCYX1RVsmHvt1wvLaLabiO/qgKoIHrVLFwkAyE+b7B0SgLt3doR0iPIa1+MtXzir2neAEZYJ2fym2S0hg+VXz2G9w8kZ+gBH4B2ZncWT05gza501l492dBx9vNiEnuFsWzqJ7ib3RrwuIgx3iPPHXx2/Ol9Dxl6GwvDhkkI+rGoCgCTfAJ36iX9UhqVm8nM59MXkpp8GtnJA1gZn4S7ya0xFUEQWDJ6tvH4rhX1gOgSI8kqQzTCF5OsNkCkWZWWlyGrCr2M7riJEhern1JaXoa7xa05lT4Bwa7auawSJiEofqgA2AO79vBvoQCuFt0gOTiKxAkfIooimw5t48qtqwRYurfgdmjviZeoV54odhFB8ZVQ8XbOvRAEoXNrAd1e68LoIVEIggDA8hmJXCu62RoVnU5HoMnTnldZJqLiLQGVQAfABNQWP3rIhoOZrYr/rxbGzsbi60+x/Fx7v55LCJSi0gFwLa0oe+7TsTOrZzW5zxgMBhSlfh31kkStXNtqgMFg4MXLSu7aZC2gRELlEhAMkHXmWM28uHhcXVybCH+/dJ6UQ5vp5WWhvPopFk8fVs5IavKIavXPvduNRuJFCUE8jKpMA0j+Y8/geXHxj4GOzYVzBo/jg5ETcDgcfJ+1k/yrFxk+YFiLgJ/PZNUA9R0K9iwJnekoVD4GOj5R5H57sn/5aXJU7NTmQtkmU1VTjWy3cb+ihH4Boc0pXLpxhdSbZ7XLL6WdzwmJ1KwqrOFfgpAO8P6RTXEDg/vnWfz8BzUW7/zrKH/eLmTHvYvsj/2U/r37NjF/VFHGpB+S69C+bwIrWLm7pn5gyv2O6vBpIAwAXHqum93zsnXzlcBuPfs0X6Lo3KM8KC9F0AkN5neK7zE3c3ntLVuNixM6T6ecbTSkrcVOgmssBvkCKn4yindQ2nzPrRGzzk+LnvgWYNTM3gsbyZKMVVy4dpnQHkGcyj9bG3dgvUFG0czvg3kcy6h7FQCw5VgJi6IjjY6aw7JO6AmIc05vH5hw5sfqpODIwvDQgW6Ft657CjrBHPvOqKpzBfm1U3akeN6yVWnGGOGmXFcbw5acRxrWdMtMPXZTXhz+NjYygYkAsqqYVhdkh6wuyG7MbO88Gtc+2aDOJS3vWWOw5aa/PvcZMMlojRoko6QAkYC+Ba++7ECOEXGVnJad1xqhtb8KAJyCUSwO98CmH4GgdDOqoi+ALCglIBYh2bNZn/tMbssE+A+73OJSJ34G1wAAAABJRU5ErkJggg==',
      'Cart': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAArhJREFUOI2dlE1IVGEUhp+5c/OmYykW6kS5KBeFEhiRUhnjWJQSLUpUhEoKkn5oULCIwDChkECZoigqKopMKVpUUP7UkBXZooVpZH9TFN0yp0x09Gu897YwnUm9ip3le877fOfwcY6MWRzKD6e7MxOJJAVrPLrWLyS+2C141Kr7bWY2eYxSlDqb6bYyuju3AxHoINCGcjqogOJyvhJYD+FuqJsQqJSkZwptWh0QY9o5IGAhaLXsdRbit+ZzrqFnLNDl2Cg0qXbcrs3CQhY27TEHnCs5ev/XCNDuci5W4fKUYMFIpo8aIHsEqMJJIOI/YMFOXWtycTfUyUrx2tVCD6ycij8xzEZRShYrkpbS5n3FjgcXUdDKBdTJQg9sCi2uXp5H2qIlprDICBuJ8+Zj6DodH9/yWvUCQx9lL3EmywqsEiGGjenZJNjnTtjhm4/vSDu+mx9a4B9dNXDIAuaEihaLZdKRv//0jYEBKDpzZCDczBgYHET8FkRG2EY0wzC4+/zB+AbJGi4D34CE0bnGZx52Xq/i0+AApUmZ7MvfzQxbJC/etFPRWj8uT6B9lTFoxxIE+gf8vP/8geP3rvBo/zliomKoqb/BhTs1bFtfQOXN02YDgU67DNwGsoa1rWcPsmBmLHnL1hE3KxaAtakZpBwppLa1kSe9XWY4P9GxTTKKfpXfUgV/97elz0dLn4/o8ChyMjaghIXx0tuBqgnUXmEGAzhP+bV+mWOeblyOcpDcodlT75/yqXIXibPnUt3RPBEI4AcDfYdheHfdnhMUO9LRpZzQqls+L/i8k8EGFWsgV5xp6QoCwWBm/BZ6vjIaOkn4Qd8sqpqbhoXgdSm/1g/k4nLsBamMSW4iBs12C3tUt6c1VB59rgzcHjeljksIqQBYj4UkIA7oV+CLgIeKNXBdVDU3qeO88weCXPg+DrPvTwAAAABJRU5ErkJggg==',
      'Mobile Truck': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA5NJREFUOI2d1H9M1HUcx/Hn53vfOOwOLu6O8wimtFFOGyrVQmkgQugYf1g2l7W5OdlqtQYdM7ubbtoPdApy0Vpszh/RP+WIsSwgdqmnbBqbwy1bRRRxIj9SCBAu/HJ8v9/+gEMITqDXf5/PZ5/H9/P9/HjLREpRhhURVQDSFiAJsAEDQBcSTchaPWX+oUjT5Tk9nhyLMYhbEbwNRM87S2PXSn3ZuNOzo6JlaPQwVY0jD4QTXDmpvUHOKYLkiH8ylUBIiQqEFHf+8hV7Gkvys6hobJsXtroyn+7VuITAtBA6M42DNx3pJvuPqcf2bji5r/z6bLiowPm3NvY1LA0NpyXYH5VkirvMm+nJfNoycB8WwVKQEv8PGk7t7XbzwWde+uo9WjZPwnvznyCk7M6OddS/k7dnqywbZCEkhCQAHRAIIRBTgA7ougY6qKpKzdXvONXVCsCJny5me06Uph55bf8NmZCyA5COv+Lufmr1urm3ZIEM/zMyDfeqCg6LfT+wUwbyASymmA2LxSZUlRT3NtaY4ynf5Z411tnfnTu5FUxeLVl+KGkpKw2ExggM3uSYrpEd68R/tw+AnqG/LGHYASAJEbtYVJIErW94AbBbrJRuL+ZQ3Sf4hru5PtQjh+G7gE3T9REgblGwkEhbtXa67bQvpyH1DIc+K+ParTb19ym4F7Cp6kQfEHdnsJ++/tskP7qCGJM5Ij52b4z2rg5sFiuJjgRkgwF7jJWkmPhgeMXNYFDsVvv3nd2B1aebviTryXRONn3B4UIPpmUPz0HHQ+McrC4nd+1zNLRcoCA9l9TH1wCw0ua8BiAbMdSButl96ijxMbH6Wy8UCofVjsVsoe5SPSmJj82Bu+/0sSUti+ef3URm2kYOnDnKi6Nb+aXnD80cFV0JICuVvvMU5wWrOq6+C5C7LpO4WAs3On6m8PzpiFvhzXiZzPUb+fXPdrxtzXjbmtmdtL6rwvX+t+Gt0IxSqETRJD8gNn1+IPKpzYjryllcV85OtxMMRv23gVs7mXyck7VC8fovU5y3D9SyRanzJCM+uaTWU/VDuH3/CVf6jlOUHY2QPlgKaJQMulOWS2o9VR/N7J9ZG3Q+9n+IK6cVjUogZSF0ldHU3nlv5PWA13fxv2Nzi473QgMefASzX8UgbUcjl9l1ehQMPnS1pi3umxo8TMz30fmr2RFC4K8GqgGBK/MRNKxEGQYo8w8zdUAPyr8qGDUyi598hwAAAABJRU5ErkJggg==',
      'Coffee Stand': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAodJREFUOI2tlF1IU2EYx387O9txfmEh+XFRFylIW7sQJDCNMYvQuugLkcAQgoIQh0JfFykrqCAyRkQEJoWROroJrBuZjA2TMkNCK6MEo5pJhGk63+yc04XOr7mV0f/y9/L8nocXnkcmVhoqLIyPlSBhVTBmoqlhIfE5y4A/1Ng1EKtMjiLHt6WTkFTP+NhRIBENBOrcmwYhQHE53wiMDXg6vXGFSl1xiVBNXmB9zMkBAXmgtlPjrGLaWEFT50S00OU4IFSpfdWpY8VAKUlqN2edRVzq+r4gzHI57SFo+VtZZbaN9JR1XBsKAtiYohUoWxCG4AaQGE9Sk1NIWb4De66VzPQMfL2BiHBuUteucjydXlmp3b1TaLNFKwWKZOSUrQSnfTtbc62kJacy/GmEQH8P9hxrVEMF1S3AKwtt9mAEbjJZqM7fQ7GtgC2b8zDLZt6OvONh4DF3nnUQnBwD4Pmxq1FCAXlZdU6brMAOMQ9fnLuHYjLzaniI5o5WbvY9YkhMRhV3D/QyOfMjiod0HLKA7Ag43XSRlo/9CE2N9524nrStyhWNbBmwREDThz4ALhfs58S+qrjSgfevKWw+sxxKRosMfAE2LutkMpOSlBxXmJRgiWICdVRGZxDDcuE/R2NQBjqA0v+gmyZtg09G0e7zU7rAkv3VdR1N0+IPo+sr0W3cbWGZK/5xXA43SJ7IS12Pl7qeqEMSL9+YmToPkd31+K9T6yhGkw6txTKfX4pxtlzcevp1UQg6qZlHmBhljdJp0CpFY9AXAYvXxd0WBspxOWpAqucPNxGdYJaB6pDH/3IpXnmudDx+DycddxHSYWAvBqxABhBW4LOAgGKcfSAag77QKn1+AzXy26+EEDwwAAAAAElFTkSuQmCC',
      'Delivery Only': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAnhJREFUOI2dlE9IVEEcxz9v9tnTlSAtzFXpkkSU1aWiNHLZXCIqTyGViKQEBbGbHoSI0IQSOvhwFSkRiZDcypIsAlPWPyt28hBpoJcIsaUO2ha5vmyeHbI1cfeJfU/z5zOf+Q3MjEq8lJYmsmmqAMFuDVs6powYgk8OGAzpgXfxlqmrRjy5qZot+YZhTl0EkjHBQP6ZMyEEaB7npIGowRfwA4txhVqFM98wRadhyi1xKwcMRewAHuJ1liFkEXpwdrXQ4yw0TNEJJFjJVkYUaKYYMTy5efhGZqJCR6UrJyTpWJ9sqVrYCcmPATewqAJKSHIXsK9XFo0ij+F1FdMQaFc1j9NpQJ4VX5KRQ1ZKOnXjfVZYNdCuGgrn1ypg22YHtWVVFI65qXnaRM/XqVhYtsPr2quCOLCWEEAogkN79vMsu5muoVeU97RgmHIFExIcVVPFQvnbqo5+RYiN8WRawoZo255kp/j4GfL3Haax+z533geWOZMMdUYPjmbVZ26IJbJKRpqD0wcLaJ0IMiMXlo5hS1Lxul5cba4WmhrfuStzO6Unz0b7H6Y/0vi8DX1yeAVnID+rAA2Tw5b377rNBkD4+zf8fV1cGnwQG5SMqwheYnLKSjj3c57XbwJUdjcxHgnHwyKkpPWpJOJnjttAajxSnwiiTwSt9gRBGzf9EZW6QJgK9zVMec96hWVmUcK18Pdz0Htb8LqOACX/IZOaSCgy6ke/LAsB7IFy5ty/QF5Yh+wHmCWG3hN9k8vCOhagtwyPewSFWyDT1pANOmxcCdUPjP07uPrH9vW2cvnEIxI5hykLgRxgKzCPYBqTIW3RfGL4BvpDMXb5DSG80zwYuBwlAAAAAElFTkSuQmCC',
      'Pickup': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAnxJREFUOI2tlV1IU2EYx3/n7MzjEvMDCwdCBV1o3ZQUjCBYq2VpxCiiCC+EIghqwywwCtRACsJkQwiCoJu+kMpCRV3YIJCFkBdRdhEUEW0K4cpqvh7PThdrOT1Hi9j/6rzPx+88z/Pyvq/C8pJo3L5O1e1O0FQhrYhRXPKBtnvJ5ZIUS6t/23qk/CbAR4pyIQHYAQ0Sk0kCnkEV23URDA/9HXoZRY27rwhk/2+KlRyAT6D7CHgGMRwNhPri1tDj3pXE9R4BO5ZrbZFqkJKjzkZPbaxz+NViqEyBfpcsYIfrEKcOHiPPni44OZPEke8AYFbT6Hpwk6ZoN0BFLEU//rqtmYrT0IC3EfTa7BJcldV/gBNfJlnTXs/Hi3dYXVpGnt2Oq7Ia0lCACqTkLWBPGnrOXcysfsHUmDT/uaqkjLHTXZQVl1r6M6NQA97dIhgeUpiTDwMlVgP7NPGZ208fzhtG0ptdv+uAVTgC/SQwpJBiv2UEkPj+lebRRyZ7nWvnUik1tBxxKBhssGjlf+UgMbVWQaI8Z0hANX46FUAA+bnD2oWiQkxAUa6QwqbFFGHYokh6ZY6YMTqfv1ewaT2k5IYcQR8DhkJVpFd97RkXULXAbUBRQSGtm/aaMosKCvk2Pb3YrGHMdAAonEDHbzuDpPeTdU6ib1+yZeNmWhrOmqCzmkZ3pHeBTSUVEqGRd2koIELhAQLuNpBbM0FN0e7MhfEveibKI82ZxfzVF4xcIuAG5BasTvbS6uOH7SjnmTNDwSAYaVP93hdI+jXTjM2aAls7wXAnkMp2mJ4TEQoPcIMw4+596HafKukuAU5AxSCOxBtknqCk7nN1OGH1t194xM6ZYDohoQAAAABJRU5ErkJggg=='
    },
    LS: {
      'Cafe': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAtBJREFUSInFlktIVGEYhp/fy4yYmahdThcEyxZdIWxVEUhTEE1okdkmWpUJEmlZEkS1SKiZIQhaREFRkV0tpyJ1VRuFcnbBlBGMXY5mDKM045wz4/wtLD0zZ47mInqX7/f97/N/nPNxThb/WFn/FdDFVZumjFQlEFUC1gHK75IKshdEW1j90VZDS2zGAK/iqdL46ZKIUmEul4EoA2pmKcUfvTZXgzNwzPu3ANGueM6DPCmnGm9Sy9BF+zPFfW6H2ngGSDpmAngVdwvIE3+XPSkJp72KRzrVhjOWgHbFVQnMONyAOe0tcb9xBhqfmwCtNGcLhMvYnr9vIdkFOZZxYxGd0M0vRkug4+mipcNBczwJkKcU7ZKw1Nhdfmo7uYX5lgA9HKXz5pVUe/mokl2JysMkgIRKyyQLZWRmUN6xi9zifD609jBw0f97DFEFKQAQ5TMFZOXYsOXZiQyNsLZ2C4PX+5DBMYD1Ez2GfsWUMI3iUZ2ePQ/InGdj24ta7KtnE30VSsqacpMT8ak3Ia7HSHyLU7y3lFhE+xOeJCNABZYZi+HvIfLmzbEERENhcjYXsGL/Rr6+7TOW1HQAXyrgc+c75q8qsQTMWTyXijsHGO4fwn/29YQvx7OSARLxRCCrjQEDLj+DW1emhcSjOr5LL9GHo6m7QAayzQSIqeKxTZEBICnNV/+MDbdryF9UNOFpP0fpvfCC4LWACSwQn+zqbDNgN0c1L56TIO8aD4y91+g+9JCiiiUTXujNQNoHCpCA4w4O6iYAgFNtaPWWuDahizqjH/OFGfD50wYm3V7D7Qw2PDZ6ptc0JxA7Ei2xCXQOT5tolMYle3BNU6ptAjhojhOgrr3Q3S3ssgXEommi+wWyaUfw2L10RctF2xlsvNXF5UdaYaxa2hM7QawDFjD+QRkEeoXGU3tQv++gOWqVM+UmO6iPEOQGcGOaKSz1z/8qfgEIk/6iYTmYIgAAAABJRU5ErkJggg==',
      'Drive Thru': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA1BJREFUOI211H9olHUcwPH3d7fdc9ss5+NcPnGrlcq22kgNjCtqMjwrzXXRQK0NLGQQKC43GhMiKUQQj2E/IFZEIWT2h65tRVOjMkQdk2UxVuxuYuSeE9zd2nZzz3M/vv1x3u0e79gG0Qce+H6/n+f7+n6fz/fhm8v/FLnzJb/l2DKpRl6SCi+AeAhQgVsSRgR0o8tvttEaXjTcSadN06Za40TbQSy9K/2AgPXAdjQR6Da8b9cFWz5dEO7icFGuOvW1BPd8X3MnVgqFT3q0ozWmbtv9Mm8aWeGvaM+zqfbTUmFjckyoNlY2lbPs0fvJK1SIzBgEfx/jZscw0pTJtxrsWgx0GrPCS7Tig5I5tHBrMS5vPbFIlNBogMi0QV6BwiMNT1L5qosLu09i9k+n8G7V+3OyLCm4t7zDKSfj+9MXKtu5ltBogCtbujJq4PplB6WN1fj7L6bGhMJ7PRw9sY3WcAqWk7IRcFgK+Pgqrn50LgMF+Pv8H5RtqsbPRcsUNPEiOl/OlcIut2AmmgWbVda+8xz5SwtZ3/ws7MuERY4g12Fnww/1/Nr2PeZAoiQS6iAdNlmdbFY2P4Pj3gIGO8/hrKlgRbkzA75xxcetq39Rsd3Fmv0uhl45m1gQ1oD18JYnG46iAsYG/Nw4+BvGnjArDmTCf354gZm+cZZXOclX70lPFVthgxAKJcmu01VO5P1ZtA2rMusAVLXVEqwb477qMsZHxtJTQSuscA0SsN7v58HaKirrXUgpmb45YUGFLYeSilJKKkqZCoTQL/nSsvKaBRbQJ+EJgNHmywQ2j1D7+S5kLM5P6z6zwI6aIjadeB2A8xu/QE7G5xY16LXAUTvHbSYHWOBiWiBC0WDklAX2XG/x9ajej1HYA3D70gT+M4NEb0cyZhsD/+DvGyRmRi27lXYOeWifsMAAjqC9bVYznwLWyck4w7t+zLotGZYMv3Z3Tn6Xf/2xjmTPArvZO9OlerfagvSSuBoXGeKM0JUdbtyp7WfU0zPUop/lg6dnVeMwingDyJtHDEs4EtCXHGqiKZaeyHpQbvbOEGRf1+ojx3LCtoYc5PPSEA+jUASMY8cnTHqjKsc9Qy16NmPeP8Dje2sUePfOkxlZyUXA/yX+BciMJ0eqsc64AAAAAElFTkSuQmCC',
      'Drive Thru Only': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA3xJREFUOI21lV1MHFUYhp8zuzuzm/I7FsISFEiwa7QYWmxjSmzrBQ2BLQ2ptDFp9aZpxJj+WDDBpD+xUWIKEqM3LdUEq7bai1Kg1RRjEBMT5aKWCyyhWDYl7JrWRWiB7uzOHi/o/gwsaGJ8k0nm+877PTnnncyMnf9J9pUWr/BhttTDdVLDC6IY0IF7EkYFdOOXl7fTOPuvwWc4Y3O77zdGiTSDyFy0/ISA9cBu3CLQHWo7Whs8cvYfwV20ZNn1+19LqEzu2zwaxQfKGTv6MzJoxtp5QqOjx926xfDb9u3kcCgl+ALNDpuuXpIaW5P7zhcy2dheR0a+Tm5ZIYMNXYSH5pMcYo/qNsHP3pTgNPfqExIrNKM+jw3v1OLKTANAL3ZT8dnL/PJWF3PXghZ4t972QyyWOLjX014gZ6JvJkNzD5Sw7mAVDpdqiSstN4uKj3Yz+F4Pf3VOJNAaJ3toPb+dxtk4WM7IvYAzVj/+bhmlr2xFsSlMXh/j3tAdbJqdyJxBfsUacjwFPH+ijl9z+gi03oyN5eEWO/DzZSIKVVZjLNx6OjZTUl2OEIKoGWVqxM+zryYSGursJ8dTgF1zsP5QFcPudMaPDC5sEGohGWxQAqDk29H0VUzdDpBdlMeNT75n+kaA/h/HE1mYMOz6iad3beLuzQnSC/REHPCkJWPgMYDoZIShnd9QcLIM10vpOFapPHfMS/+6T+PGqt/f4PrHfUTDJmNfDRLs8CU/gtVWcIgpNHKTHXbVwd0BH4Grt3BuyVo4qmky0HAeu64hbIIUClrAUmNcYAU7XBrlx6oZ2NBJzcQhhKLwcGaW7546zbbR1xGKkoIrb1vACnwrYeNim0tPY+2FbXGI6nLyzBeV2J3qYisAIkSvNQo1+jmG8nasJ8NmfNdFm9fGbYrDRvGLpfHafBh/vQGmRdBxyQL2+ppGewvbzkqD1wD8p3/DDJkojlTHfQSdCzN9biK51VLDwSnrjoF5n9HkcjsqJKI0+keEwPvDy0IXS4Tomwymt8ZqC7ie5geXM1prbDPyikSULh1fRiH6I0Fj1372x3NZ8tncMdJ45yItm1yF6ilpsC+VJyE5LxDtWtA4XklzJHkl5VA9zQ/w0dBbeOoDDGVPFKpEiCI0soE/UbmFIa6KDOWcd+TwRCrGir8mr69pFDj+6Foq//KzK4L/i/4GVtYjSDsgTBgAAAAASUVORK5CYII=',
      'Reserve Bar': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABDVJREFUSInFll1sk1UYx3+n7dquw42yjvJ2bAuzI6ix040oMxACc7gZYYxh1AsvxMTIhWQfbGpiSCTqEnAdNxqzaLwhCkw3thIzGCiShY/opkRkIDj52Hgp67pBZ9d3W3u82Fq2FRa9ID5X5z3Pc/6/9zz/nPO+Bh5wGP5XQAeNRk25UxZBlAnIA5SplCqhW4dsManJLUW8MfafAR7FXaYx8rFEZIv4dI6AHIl4SVNGej24t69Xq1r+LUC0Ke6PQL4jpybmlS3EXuhknsOKlJK/1WG8Ry8zcvAWEpkNNLcp9bsSVde7RRRF5gR4lPo6kG8DJK61ytwdRcK2dDEAAxf7EDpBZsEyHtm0Al9FH2d3djD6/RACakPKWYFK7X0BHsVdFhW3bV0i86pLhNFijuX7T1xAl6DHlpMOgG3pYlY1vkJ3Qzu+T3oBUdOmuE9vUKua4wAdNBonew6Ja+aH86pL9EaLmeFrt7jZ9RcgufHpeTAJDBYjAMrTTqxZdvIqi+m8tI/gET862N1B46Go8THAqDJSLib7yRPvF+ujb56SkYbvfB8XthyP7aS34gzLvlzD/MyFABgtZnLfW8epI/uQyOyQMrIJlX2zWiRLASzrUoOpTocl5rgQOIvzGKpU8TZcBCBtmxPnc0/O8C7V6eChTXYCzV4gUgqzAALyARY972R2RMIR/MeuYX0tA2HQ4T98ncj2MDqDfkadvfDhKYBYHucBU4coyWE1zQYEVD/O6gKWrHWBgCvHz3G734c1yz6jLjEtOTpcdC8AADISCUXCkaTejl+nzUmETjB9znfuOoM9/bHnJYWuuJ3PAAiEVyKz71wdDIiVIintscy7VQKM8yZND49NENYm4oSEXsfowJ3ooxoHkNANZHtb/zC4Xl1NSoYttjg8NkFHSSNppdmMDQWxZMwnd8vaOIj32J9Rta44ANAKbNZOBmy+S/1BW066Zfpiy+NW8rcVA3Ci4mvGgxoJlrt2DV6+MWUwgIjdSzHAmCqajIr8EMj8+c1WrdDzumW6QKg3wIWWM4R8AVJcdgyJxlhuPKhxdudhCQiB6B1RB+IB5VRqHuprgP0TPSHrydpvbjyza7MjCkmwm3E85eT3z3/k0S2rEELExLv3tMvg0SEBEIGal6kbv1eLWK9WH/AsqC/AREWg2es41vPF0PLPSk3WLLtFn5RAcnoquW89S1fddxR8UM7tvgF+2dE+EfpheFJHw73Bf/ceigMAmP2uam3Bb1KaZOVET8h6evV+TCtTfMrGpeNXO88nC73ObFuRFTqx9SuC7YNJMQ2NPWa/q2a2XhygiKIIfqoOKe5TErkbyNI6b9uudP40vSxp2vgqULveX31gttY9AdF4Qa1q+paGNqMiXwS5UUK+mDqhEm4K6AJxcEwVTeVUavfTmfObXE6lhspeYO9cdXPFA/+r+AfsmpDr4lzkTgAAAABJRU5ErkJggg==',
      'Express': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAypJREFUOI211V9IVFkcwPHvGZ25mmk6M22OG46FIWVBW+wSRUz0j9pMi7AI9K0iX4pKiQnCXdrwIS16CCp9K2qppPVP4VKw/YP+U5FCk4WNOo6RXWu0tbmNc/ZhG5s7c6eC6Af34Zzf737u755zDzeZ7xTJn0te4HCWtH5YKxWKQEwBrMCAhE4Bzfhl02oq3301fJzjSQ7HUGWYkBvEhJh0roA5wAYcor85WLe3WN3V8EX4L2oyk61DZyQsi55PcWXy074VqJ4+PJuvRaazhUJ9i6PWpfmTNq1jR9AQ/hO3OclqOS8VFsU+cNLKqdjyc0jPseLhWkxWlFkco+Cn3BAe77D/JolHAXqOtZOWk4na0W+UBkRZs7XuamRZxuDWgkOTZSC80/CWNMH0P1xMyJvI8/r7CWAQCvtaqD29msp3Y7AMyHIgJbbYPDOVuUeKsU/7EYAZuxfx4HpTIjsbhyjBz6lPS2GRv6LFVxbuX4I5VRkb58yeinezE7XeayhLKIZoWCM/7tUyTEwqdPKk8Razyl0A9Nx6wry9axjY4OPO0nNxsIBpoN88W1yRLZmXHd0EB/4/A1JK2rdd4mnBTeyL8ww7Bux6OMggCj9EV4S7NB6WtFDQ4EJKCUDh4aU8Lm2j95+HiWBVB0uFFwI9HIn0XDsjb4Z51d6Nc2EharUP3++PE7iySweboE3CL7FlwiLIzLXztvc17RWXyfo7m+kbFySERZBWHYwlfBLNtIeYQzOxIp+UjDQ8t28j1VFePnpB/so5mKZYCHfFfUZvhWo+r4OLvFWdrc66BqmxNbpy8vIZhN5r+I51ADDyagghBON/thHo8sfCNavYPqjvGBjxalWpDvMCiZgVmRtnS8d39xnh7g8ADN7r4/3afwkF9N2KIJf61PTayFgHl+IebsqoXZUUkBci+I15J3XAUGM/lxuP6vsMciWkauu3sGXUEAYo8VT2nKVmfqrTckBqbDKq+RRyRCAOKapWvQx3KDpjeFMp7mG8VLQ6DxxEM5WFYYUIkodCFvAaC8/QxEWRYTpR5NnRa2R89tdU5K3qBKo/XvERt3dfCX9L/AcwnBaZrZF4FgAAAABJRU5ErkJggg==',
      'Kiosk': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAArBJREFUOI211V1IU2Ecx/Hvo3OzMlM3ymO4aVppQQgWoWFQMYulQyrohQIRlQIhEzF6uUuSTK3orptKvIjKyPkSOLoIuqwbU/Alil3I0RLBWe4lj6cLt7m5zReiHxw4/+cPn/Oc5zkvGv5TNCs1+7inV1I0p9BxEsgAkoGfAkZVhE2RE7rKqHatGbbTpHFLcdcVRAOQuKxtVCEf1POxknPc5mm5ZZ2uf74q3MujZFfKfKeAIyvdzWLEdqHjmU1qPTwnT10+R9OfiLCdJ1q3abZLeClaHQ3ioSLBZFjAQVVE2CXN3lkv6o/qpbJbavtQKtd1hMC9u5uNC06u+uuYbRpSq3KIS4yPirknfzHZOhzM37XT9NrMDXcAVpyx5QJ0ADHGOApfniXJuHXVmcrHcvhseesv012SzoLMmwAsoMR/vvf+0TWhAFLeDkzN+3E0fPI7VgiCgWz/yeClfgbj7WuCAVTnQnC1E3xrbMce42YgOdDyqsQfTGRX7SGGbr5HGfGEYRstBrIrDvClun95yxCAzZgXuml1EvQyGIozMRbkMHFijB8jY2Fw6vFsjIW5OCwDzHSMB8YF6nQA9uUbkLccyDqTT0KmHlCXBoUgvSg37GK+5vdlsPoORABW5xUA9Flp6LPSoiCgepTQGnpCYBXaBTQAsQATL0YZSd1CrC76d8oz48L5amJpQMuUcOi6Q2CrXD/cY2p9qnqpBFBGPIxd+RgVjTh7aCylZjYEBsChq0PyFAJ71iUuoj0bHPse++sQuISaWdumhxbxW+lbHy76Y2TtBTPmwAMdtoDWr7WOTh4UaE1KG15Rjm/No2QO1GZZ3txYTXXILkbcmdNcc+Kg0ia1tAjERaAYLRl4SQKm0DKGlz4lhfayoXo5krHir8kq1w8Dt31HeCKSa4D/JX8BOsbZ8+c8TP0AAAAASUVORK5CYII=',
      //'Reserve Store': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABNFJREFUSImNlX9MlHUcx1/PPc/xAIcIJ+JhadjUQMEVriXYEKjLbDL1mthcIoVKTj3UkZU2k5xR0U45f01X0rRIc/4acw5JBRYEc2KJib/yB+hA46fIj4fjuesP7oCTu633dtvz/Xzfn/fre9/nx1fCm8xxelnQmRXUd4EoTxYZruEQjyl07sBa0eLJI3moCZiNaxHUzQpqkNcFAApMQVCngO8azAnZWEvyAId3wPp5OtSuQ6AueCbr/pzg8TXRhhd7/Hxk+V5zo66k4fqk+6oyzjkfhKDZTmZSLEGhaWQf7h4OWIaM2lEEzHSV9KL2t7PpX9dOn/LyW8DcZ6COuw/u1X552Nr344O/op21FNoej+YzZpODzR2gS9o/JFzNiphlzf1o8zzgTS87JEx4PjwyP8vCoopzdXN+/WqsMy+RzqRdcD5jEGBOSgZSXZ174pdsWWlK+xTQeQl309txb4yvCR3bEr1rtb4fzQrZbDyuWIuLJPYjyn/zjeI0J+tf2LfSlLbKU3j70ycICAQGjBgGiZoYqT+anNm0sDAvBEAR1FygWJJrZycq2CKdvvpjG/dpAYOnlV68dhmtpGVWTJzHf2JKnBsy+/fjbUWt9UFAtJyZEC8pqm0eQr8hxRBxSCtpP/HU7HA4OFF5Fn8f2StAo9Gw4Z10uejnLc6Kdr6EwID7i5TMXkB0jZtam1FsvQC0tLey504lAKm3rhGo698mX1lmzKjQAci0SVP9XNcKvC4BYc6xLSJ84jiGyO6wsyF/GwUNtW4rnbZ7DQCLwyLZsWKL29yokcHoRa3aotpENGqYBIQ4555oNJoxQ82h+tHsXZ2D4aAFy40ytyDzxDi2pn1MYECgW10QBCL8g20VHY9F7IRIQLsT4g/08IwCdSNYZ1qOJccdsG7B8mHhLj1U2p3vl9gmAY1OgF9j8+N2w5D9dOl2/R0AXpJHECBKXOpq5Vb9HcKfGz/M++RpB/d7FSdAbZSAapxfy1OlZ7ozTEuHNVVer2ZTtJG1puWIosiuEwcoqanEOCNhmPdm3T+DA4dYLeEQCxHUVICN5UdmZpiW/guMdnlsfX3MiIghPiYOjUYDwKbUtZT/WUWP0oOv7OsG+KX0VDfQ/ySJtpMSkv8Z1I4mIKRFVV45UnyyYJFx/uKdR3/AXF4w2PnTsMUOyDpzMWsWpnP5+hUsN8pcj2kjgYazEpZTnWQmbAVNHsB7p3eaYqdOrzCXF3h+mzzIXF5ASlIyKQc29uH6vjnEzWQf7u4f+JfspcuYCup0wHfyt+mTazP3XomYMHna/wHce1jHEmtWz+3ebtd+VRBVfIABWg421jMflYuAQUENicxbGfx94gd/vD9nYYzsI8uegpVehXNVZT2mY7k+CqorvA50JlagDgIALMUPwtYnGRv6KEQgHBCXXciPXVV6sCtratLVhOjYAIM+NFgjaHRN7S2d1beu9Oy+dDr4dm/nwF2WHfabio9fMt8VPnLV3I7MBsv5q2S89iq+unycJ5hiV/231RRHbaspHmod6fwNkXhCke0fknumbWh1+KG/r6oJSJbNCYmKqP0cuxrv0dcvFSiTNfZsZfv5Uk8Gb40o1pILwAXMcXoEnRGNOkG2iwYABfURDu7S11nMnqpmxVsI8B/IkKyXd/GJJwAAAABJRU5ErkJggg==',
      //'Roastery': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAABTdJREFUSImFln1QFGUcxz+3u3cHd6CAqEBxviQKApo5Y/mCAomKyYj4lqaio2kO5YmhJmOkNmrpiANqBWk6OZbvho2aoohmOJSOJvjWKPmGIIJvyMvesXv9wS3yOv1mdmaf7/N9+e2zL89KtFULBnkZdeYFMsp4IKQNVqER8YDsqEonPe9JawSpFUzHgqiF6JQUGcWjzQbqK0RGCUHnYiUx6ks2Zm8EHG0HLBprRqneCcq4ZkZ3oz0tBX19u1e7Gl1dih4/dMstuRFwV5H9nfMeqMoGrJFhOFynk37kZcuAORhRKo8DgzXIS9SfPDH7q+v9e785Ahhzp/gugiBg8fUHcPz74M71VbvT63Y8+DvUKYlFV3OCOUSwFblpgHtUBqqimStJgcPS13+UMhYYrlHOXcnHoDdoAbpur3cN2p6UyuS8U/ei967xc/oNxByZCTnxrwISI0ejKvGa0TdDp6+YHzfzM8CsYTa7jc3n9uOhNzE+IgZRFBt6GzXoXUtBJ78noZs/9nJCM7BG7iUt54gECKh8rZFjvLpkzI+bmQCYX1RVsmHvt1wvLaLabiO/qgKoIHrVLFwkAyE+b7B0SgLt3doR0iPIa1+MtXzir2neAEZYJ2fym2S0hg+VXz2G9w8kZ+gBH4B2ZncWT05gza501l492dBx9vNiEnuFsWzqJ7ib3RrwuIgx3iPPHXx2/Ol9Dxl6GwvDhkkI+rGoCgCTfAJ36iX9UhqVm8nM59MXkpp8GtnJA1gZn4S7ya0xFUEQWDJ6tvH4rhX1gOgSI8kqQzTCF5OsNkCkWZWWlyGrCr2M7riJEhern1JaXoa7xa05lT4Bwa7auawSJiEofqgA2AO79vBvoQCuFt0gOTiKxAkfIooimw5t48qtqwRYurfgdmjviZeoV54odhFB8ZVQ8XbOvRAEoXNrAd1e68LoIVEIggDA8hmJXCu62RoVnU5HoMnTnldZJqLiLQGVQAfABNQWP3rIhoOZrYr/rxbGzsbi60+x/Fx7v55LCJSi0gFwLa0oe+7TsTOrZzW5zxgMBhSlfh31kkStXNtqgMFg4MXLSu7aZC2gRELlEhAMkHXmWM28uHhcXVybCH+/dJ6UQ5vp5WWhvPopFk8fVs5IavKIavXPvduNRuJFCUE8jKpMA0j+Y8/geXHxj4GOzYVzBo/jg5ETcDgcfJ+1k/yrFxk+YFiLgJ/PZNUA9R0K9iwJnekoVD4GOj5R5H57sn/5aXJU7NTmQtkmU1VTjWy3cb+ihH4Boc0pXLpxhdSbZ7XLL6WdzwmJ1KwqrOFfgpAO8P6RTXEDg/vnWfz8BzUW7/zrKH/eLmTHvYvsj/2U/r37NjF/VFHGpB+S69C+bwIrWLm7pn5gyv2O6vBpIAwAXHqum93zsnXzlcBuPfs0X6Lo3KM8KC9F0AkN5neK7zE3c3ntLVuNixM6T6ecbTSkrcVOgmssBvkCKn4yindQ2nzPrRGzzk+LnvgWYNTM3gsbyZKMVVy4dpnQHkGcyj9bG3dgvUFG0czvg3kcy6h7FQCw5VgJi6IjjY6aw7JO6AmIc05vH5hw5sfqpODIwvDQgW6Ft657CjrBHPvOqKpzBfm1U3akeN6yVWnGGOGmXFcbw5acRxrWdMtMPXZTXhz+NjYygYkAsqqYVhdkh6wuyG7MbO88Gtc+2aDOJS3vWWOw5aa/PvcZMMlojRoko6QAkYC+Ba++7ECOEXGVnJad1xqhtb8KAJyCUSwO98CmH4GgdDOqoi+ALCglIBYh2bNZn/tMbssE+A+73OJSJ34G1wAAAABJRU5ErkJggg==',
      'Cart': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAsFJREFUOI211V1Ik1Ecx/Hv2XSPkVlOSxeRVmoX4UURXRi9QUHUUiK0G71KpCKKSqkJYRTkhW/UTVAGRVFRRFNXREJYd1ld2IuwNGxZTq2mzfmyp7nTjXN77HEroh88F+f8z//DOed54InjPyUuWvEB55Ol+eduqWAFsQwwA98kdAloxi2bdlE++sfwJS4ZLZaR8iABG4j5M8pLBawB9mIR/c3+ulP5nuONMWE71QvizCN3JGyLdpqppAuFyy2W2k2q21i6h6N+Xfg2tnij2XRfKmz+AzQiothkmQQ3JbpwoiX1tORv0TDebK57GrqWadixsmGJ9AaP/TVnNjJ3Ywo++yBC4WwLtbd2UT46DUuvLAESQmNjlomElUn6mNFA8rrFpK1dTmrOYia847TZrwCkYxEFuLkZvgqT3IEabt5wr4TEhTM/CG16nztpP9eCp9E1PSchHyJhlazIJiUxgVj5cO0FPvug9jSQDdqXlxJTisiEd4zR1q96pVQt7GcIhUUzV7k7euh9/A6DKY4VBatJzkwDoNvxCjkq9WCPBpYKHwVhOBgMMtD5ic6aNlad2EJgQqX9kJ28xiLkZBBX5ctZziJ7NLABHklYFxq/vviE8b4Rcg7nkZ6bCYBv3xAdF1oZezuMVHV3i/Dj0F6FKXgD1VAZmhuodwIwsTV7eslYv5fhq59n2SkAP4Qn/r4GtroquhwZdY1SZX/kyvcHn+E76SEwojLQ4IyGAlTv5MiQdsfAuEutmGOJXy8RuaE5qUq+nHkTC0T4ae3zzKsNjTVwITZfU1LtTqNXPojEY8ZPW8CjFpVRNqkLAxQ4y3vvUp03J8NUI1VK9daEI8cFokHxqFXbsAUiK7pNhdh8uDjgyKipRzUUB2G78JOJQjLwHRPdqOKhSDJctzqP6r7NqL8mq6uiC6iaen6Pe/beqPC/5Bcq/vmpv+9QowAAAABJRU5ErkJggg==',
      'Mobile Truck': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAy5JREFUSInFlU1MVFcUx393mJnHQBmCQvXFRqTDwsak9aMLF9ZEE10Nymiipot25XTTjQzanTWmqSxgSFdNSFOTqrE1JZRx0ho/iE0JflBdNH5Eq00BYWi0TxzQmfeceacLGBzmA4c0xv/u3nPv/3fPPTf3OHnFcr5WQDedmluXAEgAWAvogADjAtccSI8W8/ZsIWgtGBDRwzsUdjvQUCDcqKBRULuT+uT9Xne4ZftQS6QkQBddZboePwKyf77ssuRzWNIb1TsO+2OhQ0xnWByg61NtoFpLNJ+VwMGo3oE/Fvq8KCCih3eALNh8DqS+fdA/1BrNA3TTqc3c+f+SWKrzHF1nM4WfBUy/Fho2Xv047Vn8RplyOFBKgZqOK6XyjisIYgvDv93i1kcXMpHGpD7ZTIxTcwCCNCvA7S0vc3m0kk47/sffPPtnAu+Kutw8ApADUPB+Sa5ZGuu/y9gXN1j9kx/P5hoSfY8zbusya7KLvHShgOrGOuzWlVjxBMs/fJc7fb9mQnohQM4lv1y+rWtg6xoARITaS2/xeyjy0ByYrMgDKNS4IG9nxmkrhZ1K46qYvx6ppAVK4dRc1NQvodxXfc8cmJwtSlaRuV7ZVFfr1Nzef++PcfubftzV5SxetxzfltUFzUeu3OHBmZtIWmjYuRb9vQbM4SkDGMkHmBKRtLwTH3206s8fBll/KECZ28m1zl8wfEtQjrk3KAKj52+z/mAAsYUrX0ZweVyjybMTlWjqeB7ANqp+TJx/1Dbw80lUpcIMJij3VvBs+AkDG04WzMCzuYZU0iJlpZjoG+Xy138tQ8OyYqonD9BMMHHaCn8GckyeChc3fIvjTSfpe0V/YhJ9jzm3qQuZshEjDYCC/TvZZ+YBAJpiLSei9eEPxJKgxG3S8eLms5kPP38xMAn7jVB3djz3N5WpoYefVtbXKiz2vtQ9S8rkqzGj6kDufN53vYcjzxnik+iijn6pUm1YoueuydEIqAN+o+X7QsFiHU38Rui700Z7N4vULqWxTaZb5lJmWibIdTHptQ3vqWaCiWL0eXtyE61PMTgKHJ2ZyrxVKbJlYYACKtk4o/8AdPA3Al2tE+gAAAAASUVORK5CYII=',
      'Coffee Stand': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAq1JREFUOI211E1IFGEcx/Hvs+vuaLimU21OhlNQ2Yu90KFD71FCqCkV1SUjSKROYelhgyjo4KE16Wp700ryYJlFEFFR1CHrEoi2UdmLK6FrrprNqPt0aW1Hx1WSfjAwz/P85zPP88zDJPGfkpRo8B5XM6Q6sl8qFIJYCqhAj4SggGZC8s4+KoZmDNdS69S0gYoooz4QcycMZwvYCBxBE93NRvX5ovDZwLTwbarSk9SBWxLyEq3mTzKFwrW7mn+HGXKWHqTcsIUb8LmcqrtJKuycARoXcdStjUGIEls4VZt/UTI9mrwrnawDq/Bu0Hm5rWEcb1arn8a2ZRxuyalZLCPRM1NhngMLWZS/Eu86HY+mMtjdh0fLsM5b4dJd/Df3UTE0DsuILAGS4wvVE9lou1awYK3OnPRUej+E+PK0ja7GdhwpSey+fnzi+zPRRDEhbvzdCrfMxwRHtovcmj141+g4XU56Or4RbHpFd307Y+/N8XJlq4dfkZ+TViahCOJhk2UA0c8jjA6ZvK19zPdAEBmJ2m6N8XyAR6trJ/ULWA7WjzcvdtN27BEAC8/ksGCTbgvH8s7/ArN1ML5rvhU26EPBG1+hrs9iyfbchPDHujeYWOCwBZYKnwRW+N8iP1pgBzyQsGm2rDBoscC4o/WYjnNM82OaJv0i7GqywIWdlcEWvTogTU7G+j4EXtP1sCOhNNz6I75ZVcDpPuuMgeFOszJFc22RiLUAxrN+jGf9M5qqMHjYFfb4Y20LfAjf4J00f4EzIu/F8BnF4Mlo2DxcRtmYLQxQ3FHxpZGqzSm6+7I0KbWr+Rs5LBA1Sti8kIdvNH7E9qFD+Abp5FSLfvkKpuNoFPYKgyUoZAC9uHmPKe6LNEddYUf5Vzsj4Qko7KwMAhf+XJMTmvrZ2RythPkN/e/isT2DZhEAAAAASUVORK5CYII=',
      //'Delivery Only': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAnhJREFUOI2dlE9IVEEcxz9v9tnTlSAtzFXpkkSU1aWiNHLZXCIqTyGViKQEBbGbHoSI0IQSOvhwFSkRiZDcypIsAlPWPyt28hBpoJcIsaUO2ha5vmyeHbI1cfeJfU/z5zOf+Q3MjEq8lJYmsmmqAMFuDVs6powYgk8OGAzpgXfxlqmrRjy5qZot+YZhTl0EkjHBQP6ZMyEEaB7npIGowRfwA4txhVqFM98wRadhyi1xKwcMRewAHuJ1liFkEXpwdrXQ4yw0TNEJJFjJVkYUaKYYMTy5efhGZqJCR6UrJyTpWJ9sqVrYCcmPATewqAJKSHIXsK9XFo0ij+F1FdMQaFc1j9NpQJ4VX5KRQ1ZKOnXjfVZYNdCuGgrn1ypg22YHtWVVFI65qXnaRM/XqVhYtsPr2quCOLCWEEAogkN79vMsu5muoVeU97RgmHIFExIcVVPFQvnbqo5+RYiN8WRawoZo255kp/j4GfL3Haax+z533geWOZMMdUYPjmbVZ26IJbJKRpqD0wcLaJ0IMiMXlo5hS1Lxul5cba4WmhrfuStzO6Unz0b7H6Y/0vi8DX1yeAVnID+rAA2Tw5b377rNBkD4+zf8fV1cGnwQG5SMqwheYnLKSjj3c57XbwJUdjcxHgnHwyKkpPWpJOJnjttAajxSnwiiTwSt9gRBGzf9EZW6QJgK9zVMec96hWVmUcK18Pdz0Htb8LqOACX/IZOaSCgy6ke/LAsB7IFy5ty/QF5Yh+wHmCWG3hN9k8vCOhagtwyPewSFWyDT1pANOmxcCdUPjP07uPrH9vW2cvnEIxI5hykLgRxgKzCPYBqTIW3RfGL4BvpDMXb5DSG80zwYuBwlAAAAAElFTkSuQmCC'
      'Pickup': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAvdJREFUOI2tlVtIE1AYx/9Hzy7NvIu1zSXe0C0p3NRUEEQLHxQpxEwJCgsC6cUII0akRUZl+RAEQUb0YHlJxFQCCwZmarJJ5qZ2caRupmbecO2mp4d0OTZnUf+3833/8+P7zuE7h8KDOgvuCrhyYfHail1uN9lC7YuWuZXhhZFVva2zcOri0Fb7qLvghOZT0mTv6ENDrW6vvXGUuPM8y7g9YVWZzxdB2eARyhijE+oPdepLHQXm9mVPTcCmMksIUN+kuHWGqJeO5OPqkguUMeY3ofnY3ZPfHM++rHkEbpZdbc2kCj/1U/XNxGMoX3RAGWNe85MzbW9LW52A4hsyhMrDXUCrFhtGqrthVf3YAEdTBbcZamRtrrTs/eOudHufxWlzalkuvDm/LLrnfQhLjIGfMMiRH1S9dKr4Ca4dLYKygTLGAr6NGSumlKMuFW0AN8TAfud4HBc/N4NfDRUaKIBCo2Zsp4tjXeP9ozAvmkB3cDGtHce0dhzihCi3XpvKLKkXXo+nAPKm3+i3YkJ7/zWWaqedYoKBwi393hGcQxSAzKT7vqXpb+UjDYijAHbbJi3bmv9U1J8XTAFYBEmB/GXt7P+BCjgzFMBUYILIf/nR/4F6+VANZYz1ilKi4sbx7p+BVMFlVs1UHSWEtIj2RZ7k5/hiu3nfTuJTMm1qaY6JAmjj8LnD+5VZ0r72FifTqs0ORXk2rGedLzIkSgi9Yc4pxs/xRVhKbAkAUELIKmPsXHiKtGP2gYGMne53GHtq2tzOvt4wh5HqbseahHtBXpndKJFH9wPrs08IecEYq1ScyKwAgA2w4YIOBug8tkwP8JB8L29IIo8pdsQ25a94UW8klhy8HCIVk8GqV9uesagqFvHH07sCJaG5hBC7C5QQwgBUMsb6ItJkd8KaoqXGwTEYez9jfsAIU/88OGE8CGRB2JUWAbE8cjk4UlQJoIYQ4vQAu3wn60fRyeFzc/ckxR4OT45LASAEwAPwFYAOQCuAekLIgrsOfgKkfSQxFA0Y5AAAAABJRU5ErkJggg=='
    }
  },
  
  scenarioDecisionsFields: {
    commonFields: {
      base: 'Default',
      revise: 'Revised',
      storeName: 'Store'
    },
    decsionTypes:
    {
      Open: {
        Category: {
          StoreInf: 'Store Info',
          Cost: 'Cost',
          Sales: 'Sales Assumptions',
          Cannibalization: 'Cannibalization/ Sales Transfer',
          cannibalizationLabel: '*Sales Transfer Amount is the estimated amount of sales the pipeline store may draw from nearby Starbucks stores.'
        },
        StoreInf: {
          sType: 'Store Type',
          openDate: 'Open Date'
        },
        Cost: {
          storeInvst: 'New Store Investment',
          revStoreInvst: 'Revised Store Investment',
          rentTotal: { header: 'Total Rent (Annual $)', tooltip: 'Includes property rent, percent rent and non-cash rent; does not include CAM, taxes or insurances', type: 'default' },
          annualRent: 'Annual Rent',
          revAnnualRent: 'Revised Annual Rent',
          rentTotal_SW: 'Total Rent (Annual)'
        },
        Sales: {
          Y1S: 'Year 1 Sales',
          revY1S: 'Revised Year 1 Sales ($)',
          Y2SRamp: 'Year 2 Sales Ramp %',
          revY2SRamp: 'Revised Year 2 Sales Ramp %',
          Y3SRamp: 'Year 3 Sales Ramp %',
          revY3SRamp: 'Revised Year 3 Sales Ramp %',
          Y4SRamp: 'Year 4 Sales Ramp %',
          revY4SRamp: 'Revised Year 4 Sales Ramp %',
          Y5SRamp: 'Year 5 Sales Ramp %',
          revY5SRamp: 'Revised Year 5 Sales Ramp %',
        },
        Cannibalization: {
          canSt1: 'Cannibalized Store #1',
          canSt1AmtPrc: "Sales Transfer Amount (% of Store #1's Sales)",
          canSt2: "Cannibalized Store #2",
          canSt2AmtPrc: "Sales Transfer Amount (% of Store #2's Sales)",
          canSt3: "Cannibalized Store #3",
          canSt3AmtPrc: "Sales Transfer Amount (% of Store #3's Sales)",
          canSt4: "Cannibalized Store #4",
          canSt4AmtPrc: "Sales Transfer Amount (% of Store #4's Sales)",
          canSt5: "Cannibalized Store #5",
          canSt5AmtPrc: "Sales Transfer Amount (% of Store #5's Sales)",
          incrSalesPerc: "Incremental Sales %"
        }

      },
      Renew: {
        Category: {
          renoLease: 'Renewal Lease',
          renoDetail: 'Renovation Details',
          renoPostDetail: 'Post-Renovation Details',
          BaselineSales: 'Baseline Sales Growth'
        },
        renoLease: {
          renewDate: 'Renewal Date',
          revRenewDate: 'Revised Renewal Date',
          baseRent: { header: 'Next Option Base Rent (Annual $)', tooltip: 'Base rent (does not include percent rent, CAM, taxes or insurance)', type: 'default' },
          revBaseRent: 'Revised Next Option Base Rent(Annual $)',
          nextOptRent: 'Next Option Percent Rent (Annual $)',
          revNextOptRent: 'Revised Percent Rent(Annual $)',
          revBaseRent_SW: 'Revised Next Option Base Rent(Annual)',
          nextOptRent_SW: 'Next Option Percent Rent (Annual)',
          revNextOptRent_SW: 'Revised Percent Rent(Annual)',
          baseRent_SW: 'Next Option Base Rent (Annual)',
        },
        renoDetail: {
          renoPrjType: 'Reno Project Type',
          RevRenoPrjType: 'Revised Rennovation Type',
          renoInvst: 'Reno Investment',
          revRenoInvst: 'Revised Rennovation Investment(Annual $)',
          deptrTerm: 'Depreciation Term (Years)',
          renoCompDate: 'Reno Completion Date',
          revRenoCompDate: 'Revised Rennovation Completion Date',
          renoClousreDate: 'Reno Closure Days',
          revRenoClouserDate: 'Revised Rennovation Clouser Days',
          revDesTerm: 'Revised Depreciation Term (Years; Default = 5)'
        },
        renoPostDetail: {
          postRenoSaleLift: { header: 'Post-Reno Sales Lift %', tooltip: 'Expected sales lift after renovation, applied to baseline sales', type: 'default' },
          postStoreType: 'Post-Reno Store Type',
          postBrandValue: 'Post-Reno Brand Value '
        }
      },
      Clousre: {
        Category: {
          costs: 'Costs',
          accretion: 'Accretion',
          BaselineSales: 'Pre-Closure Sales Growth'
        },
        costs: {
          closeDate: 'Close Date',
          revcloseDate: 'Revised Close Date',
          deadRent: 'Go Dark Costs (Total $)',
          revdeadRent: 'Revised Dead Rent(Annual $)',
          decommissionCost: 'Other Closure Costs (Total $)',
          decommissionCost_SW: 'Other Closure Costs (Total)',
          terminationFee: 'ETR Costs (Total $)',
          terminationFee_SW: 'ETR Costs (Total)',
          deadRent_SW: 'Go Dark Costs (Total)',
          backfill: 'Risk of Competitor Backfill of Closed Storefront'
        },
        accretion: {
          accStr1: "Accretion Store #1",
          accStr1Perc: "Sales Transfer Amount (% of Closing Store's Sales)",
          accStr2: "Accretion Store #2",
          accStr2Perc: "Sales Transfer Amount (% of Closing Store's Sales)",
          accStr3: "Accretion Store #3",
          accStr3Perc: "Sales Transfer Amount (% of Closing Store's Sales)",
          accStr4: "Accretion Store #4",
          accStr4Perc: "Sales Transfer Amount (% of Closing Store's Sales)",
          accStr5: "Accretion Store #5",
          accStr5Perc: "Sales Transfer Amount (% of Closing Store's Sales)",
          accStrTotal: "Total Sales Transfer"
        }
      },
      ReloExapnd: {
        Category: {
          details: 'Details',
          cost: 'Costs',
          postSales: 'Sales',
          cannibalization: 'Cannibalization',
          BaselineSalesRelo: 'Pre-Relo Sales Growth',
          BaselineSalesExpand: 'Pre-Expansion Sales Growth'
        },
        CategoryExpand: {
          details: 'Expansion Details',
          postSales: 'Post-Expansion Sales'
        },
        CategoryRelocate: {
          details: 'Relocation Details',
          postSales: 'Post-Relo Sales'
        },
        details: {
          reopendDateExpand: 'Re-Open Date',
          reopendDateRelocate: { header: 'Re-Open Date', tooltip: 'Open date of new location', type: 'default' },
          revReopendDate: 'Revised Store Re-Open Date',
          closeDateExpand: 'Close Date',
          closeDateRelocate: { header: 'Close Date', tooltip: 'Closure date of current location', type: 'default' },
          revcloseDate: 'Revised Close Date ',
          storeType: 'Store Type',
          reloPostStoreType: 'Post-Relo Store Type',
          revReloPostStoreType: 'Revised Post-Relo/Expn.Store Type',
          exapndPostStoreType: 'Post-Expansion Store Type',
          reloBrandValue: 'Post-Relo/Expn. Brand Value (Default = Current Value)'

        },
        cost: {
          reloInvestment: 'Relo Investment',
          expandInvestment: 'Expansion Investment',
          revexpandInvestment: 'Revised Relo/ Expansion Investment',
          investment: 'Investment',
          baseRent: { header: 'Base Rent (Annual $)', tooltip: 'Base rent (does not include percent rent, CAM, taxes or insurance)', type: 'default' },
          revBaseRent: 'Revised  Base Rent(Annual $) ',
          percRent: 'Percent Rent (Annual $)',
          percRent_SW: 'Percent Rent (Annual)',
          revPercRent: 'Revised Percent Rent(Annual $)',
          //deadRent: 'Dead Rent (Total $)', //Only in relocate
          deadRent: 'Go Dark Costs (Total $)', //Only in relocate
          deadRent_SW: 'Go Dark Costs (Total)', //Only in relocate
          revdeadRent: 'Revised Dead Rent Total',//Only in relocate
          //decomissionedCost: 'Decommission Costs (Total $)', //Only in relocate
          //terminationFee: 'Termination Fees (Total $)'  //Only in relocate
          decomissionedCost: 'Other Closure Costs (Total $)', //Only in relocate
          decomissionedCost_SW: 'Other Closure Costs (Total)', //Only in relocate
          terminationFee: 'ETR Costs (Total $)',  //Only in relocate
          terminationFee_SW: 'ETR Costs (Total)',  //Only in relocate
          baseRent_SW: 'Base Rent (Annual)'
        },
        postSales: {
          yrSale1: 'Year 1 Sales',
          revyrSale1: 'Revised Year 1 Sales ($)',
          yrSale2Prc: 'Year 2 Sales Ramp %',
          revyrSale2Prc: 'Revised Year 2 Sales Ramp %',
          yrSale3Prc: 'Year 3 Sales Ramp %',
          revyrSale3Prc: 'Revised Year 3 Sales Ramp %',
          yrSale4Prc: ' Year 4 Sales Ramp %',
          revyrSale4Prc: 'Revised Year 4 Sales Ramp %',
          yrSale5Prc: 'Year 5 Sales Ramp %',
          revyrSale5Prc: 'Revised Year 5 Sales Ramp %',
        },
        cannibalization: {
          cannStr1: "Cannibalized Store #1",
          cannStr1Sales: "Sales Transfer Amount (% of Store #1's Sales)",
          cannStr2: "Cannibalized Store #2",
          cannStr2Sales: "Sales Transfer Amount (% of Store #2's Sales)",
          cannStr3: "Cannibalized Store #3",
          cannStr3Sales: "Sales Transfer Amount (% of Store #3's Sales)",
          cannStr4: "Cannibalized Store #4",
          cannStr4Sales: "Sales Transfer Amount (% of Store #4's Sales)",
          cannStr5: "Cannibalized Store #5",
          cannStr5Sales: "Sales Transfer Amount (% of Store #5's Sales)"
        }
      }
    }

  },
  labels: {
    appnavbar: {
      tooltips: {
        logged: "Logged in as"
      },
      label: {
        header: "Atlas Scenario Builder",
        invalidGeography: "Invalid Geography",
        sharedScenarios: "Shared Scenarios",
        noNotification: "No Notifications",
        profile: "Profile",
        createPipleline: "Dummy Pipeline Categories",
        createUser: "SAT User",
        logout: "Logout",
        help: "Help",
        notificationDetails: {
          sharedBy: "This scenario is shared by user,",
          fromGeo: "from geography,",
          cloneTo: ". Copy scenario to",
          new: "New",
          or: "or",
          existing: "Existing",
          geo: "geography ."
        }

      }
    },
    satUser: {
      header: "SAT Users",
      label: {
        createBtn: "Create",
        editBtn: "Edit"
      },
    },
    satUserAdd: {
      addRgns: "Add Region",
      editHeader: "Edit SAT User",
      newHeader: "New SAT User",
      userId: "User Id",
      firstName: "First Name",
      lastName: "Last Name",
      selectRegion: "Select Region",
      selectUserId: "Select UserId",
      cancel: "Cancel",
      save: "Save User",
    },
    pipelineMain: {
      header: "Dummy Pipeline Categories",
      label: {
        createBtn: "Create",
        editBtn: "Edit",
        cancelBtn: "Cancel"
      },
      message: {
        noData: "No Dummy Pipeline Categories available. Please create new category."
      }
    },
    pipeline: {

      editHeader: "Edit Dummy Pipeline Category",
      newHeader: "New Dummy Pipeline Category",
      catName: "Category Name",
      storeType: "Store Type",
      ownershipType: "Ownership Type",
      selectStore: "Select Store type",
      selectOwnership: "Select Ownership",
      selectCategory: "Select Category",
      storeInvst: "Store Investment:",
      annualRent: "Annual Rent:",
      sales: {
        yr1Sales: "Year 1 Sales:",
        yr2Sales: "Year 2 Sales Ramp %:",
        yr3Sales: "Year 3 Sales Ramp %:",
        yr4Sales: "Year 4 Sales Ramp %:",
        yr5Sales: "Year 5 Sales Ramp %:",
        incSales: "Incremental Sales %:"
      },
      sale: "Sales",
      storeCost: "Store Cost",
      cancel: "Cancel",
      save: "Save",
      delete: "Delete"
    },
    project: {
      label: {
        title: "Projects",
        createBtn: "Create",
        sortBtn: "Sort",
      },
      tooltips: {
        openScn: "Click to Open Scenarios",
        EditPrj: "Edit geography name or description",
        invalidGeo: "Invalid Geography"
      }
    },
    createProject: {
      region: {
        CO: "CO",
        LS: "LS",
        SD: "SD"
      },
      label: {
        titleCreate: "Create Geography",
        titleEdit: "Edit Geography",
        addScnBtn: "Add Scenario",
        selectGeo: "Geography Selection",
        rgn: "SD Region:",
        mkt: "Market:",
        miniMkt: "Mini Market:",
        coRgn: "CO Ops Region:",
        coArea: "CO Ops Area:",
        lsRgn: "LS Ops Region:",
        lsArea: "LS Ops Area:",
        geoName: "Geography Name",
        desc: "Description",
        saveBtn: "Save Geography",
        cancelBtn: "Cancel",
        archiveBtn: "Delete Geography",
        sd: "Store Development",
        co: "CO Operations",
        ls: "LS Operations",
        store: "Stores",
        tags: "Project Tags",
        comma: "(Separate Tags with Commas)",
        geoPrev: "Project Geography Preview"
      },
      tooltips: {
        addMkts: "Add Markets",
        addMiniMkts: "Add Mini Markets",
        addCoAreas: "Add CO Ops Areas",
        addLsAreas: "Add LS Ops Areas",
        addStores: "Add Stores"
      }
    },
    scenario: {
      label: {
        title: "Scenarios",
        subHeading: "Project",
        createBtn: "Create",
        compareBtn: "Compare",
        shareBtn: "Share",
        sortBtn: "Sort",
        sharedBy: "Shared by",
        openings: "Openings",
        close: "Closures",
        relo: "Relocations",
        modify: "Scenario Modified On:",
        updateB: "Data Updated On:",
        update: "Updated On:",
        renew:'Renewals',
        expansion:'Expansions'
      },
      tooltips: {
        edit: "Edit scenario name or description",
        summary: "Scenario Summary"
      },
      baseline : {
        baselinelbl : "This baseline was created to analyze what would happen if we closed a store in Midtown and opened a new one nearby the location of a closed store",
        baselineCount : "TOTAL STORES IN BASELINE"
      }
    },
    createScn: {
      label: {
        titleCreate: "Create Scenario",
        titleEdit: "Edit Scenario",
        geo: "Geography",
        copy: "Copy Scenario",
        scn: "Scenario",
        scnName: "Scenario Name",
        desc: "Description",
        saveBtn: "Save Scenario",
        cancelBtn: "Cancel",
        archiveBtn: "Delete Scenario",
        tags: "Scenario Tags (Separate Tags with Commas)"
      }
    },
    editScn: {
      label: {
        titleBaseline: "Baseline",
        addPipeline: "Add Dummy Pipeline Store",
        viewSummary: "View Summary"
      },
      tooltip: {
        addPipeline: "Add Dummy Pipeline Store",
        viewSummary: "Store Summary"
      }
    },
    storeInfo: {
      label: {
        sNum: "Store Number:",
        sType: " Store Type:"
      },
      tooltip: {
        viewSummary: "View Store Summary",
        editStore: "Click to Store Details"
      }
    },
    storeSummaryDashboardWindow: {
      label: {
        sTitle: 'Store Summary',
        noData : 'Summary Data does not exist for the store'
      },
      tooltip: {
        closeWindow: "Close Store Summary Window"
      }
    },
    storeWindow: {
      label: {
        sOverview: "SITE OVERVIEW",
        sDetails: "SCENARIO ASSUMPTIONS",
        sDecision: 'Scenario Decision',
        sBase: 'Baseline',
        edit: 'Edit',
        update: 'Update Atlas'
      },
      tooltip: {
        closeWindow: "Close Store Window",
        atlas: "Atlas Next",
        sReport: 'One Store Report',
        sSummary: 'View Store Summary',
        sEdit: 'Click to edit Store Details',
        sUpdateAtlas: 'Click to Update Atlas Store Portfolio Review decisions'
      },
      message: {
        updatedMsg: "Updated to Atlas"
      }
    },
    storeTable: {
      label: {
        editBtn: "Edit",
        selectRenewStr: "RENEWALS",
        selectCloseStr: "CLOSURES",
        selectRelocStr: "RELOCATIONS",
        selectExpStr: "EXPANSIONS",
        newStore:'NEW STORES',
        deselectStr: "DESELECT ALL",
        exportData: "Export Data",
      },
      tooltip: {
        clearFilter: "Clear Filter"
      }
    },
    dashboard: {
      label: {
        title: "Summary",
        scnResult: " Scenario Results: Change over next ",
        yr: "Year",
        sType: "Store Type",
        ownershipType : "Ownership Type",
        store :'Store',
        defaultBtn: "Reset ",
        load: "Loading...",
        saleChange: "Sales Change",
        compSaleChange: "Comp Sales Change",
        conChange: "Contribution Change",
        sCountChng: "Store Count Change",
        sale: "Sales",
        yoy: "YoY%",
        comp: "Comp (%)",
        sGrowth: "Store Growth",
        decisionClosuresSummary: "Store Closures",
        decisionReloExpanSummary: "Relocations & Expansions",
        tc: "Total Contribution ($)",
        tcPerc: "Total Contribution (%)",
        storetcPerc: "Total Contribution %",
        tcCashFlow: "CO Cashflow ($)",
        tcCashFlowPerc: "CO Cashflow (%)",
        storetcCashFlowPerc: "Cashflow %",
        expense: "CO Expenses:",
        plannedPrjTypes: "CO Renovation Types & Spend",
        newSCountSpend: "New Store Counts & Spend",
        viewDetails: "View Details"
      },
      tooltip: {
        filter: "Filter",
        viewDetails: "Store Details",
        clearFilter: "Clear Filter",
        resetFilter: "Reset All Filters"
      },
      message: {
        noGeoErr: "No Geographies selected. Previous active data is displayed."
      }
    },
    compare: {
      label: {
        title: "Compare Scenarios",
        rgn: "Region:",
        mkt: "Market:",
        miniMkt: "Mini Market: ",
        opsArea: "Ops Area: ",
        cmpFilters: "Compare Filters",
        filtersToScnChart: "Filters will be applied to scenario charts",
        exportBtn: "Export"
      },
      tooltip: {
        compare: "Compare Options Menu"
      },
      message: {
        noFilterData: "No data available for the selected filters"
      }
    },
    breadCrumb: "Geography",
    advSrch: {
      sName: "Store Name",
      sType: ""
    }
  },
  fyList: [],
  wk53FyList: [],
  breakLine: '\n',
  tooltipShared: {
    Me: 'Me',
    sharedTo: 'Shared with:',
  },
  scenario_table_popup: {
    header: "EDITED ROWS ARE HIGHLIGHTED",
    desc: "If a store/location in this scenario has been edited to be different from the BASELINE, it's row will be highlighted.",
    popup_msg: "DON'T SHOW ME THIS AGAIN"
  },
  geography_change_popup:
  {
    header: "PROJECT STORE COUNT UPDATE",
    desc: "Stores counts have been updated due to changes in the statuses of stores or changing of geographies. Below is a breakdown of what has changed for this baseline for your information.",
    circle:
    {
      desc1: "STORE CHANGE TOTAL",
      desc2: "OLD STORE COUNT",
      desc3: "MODIFIED STORE COUNT"
    },
    label:
    {
      label1: "STORE(S) OPEN DETAIL",
      label2: "STORE(S) CLOSED DETAIL",
      label3: "GEOGRAPHY CHANGE AND STORE DELETION DETAIL",
      change: "TOTAL CHANGE:"
    },
    italicText:{
      movedTo:"moved to",
      movedfrom:"moved from",
      deleted:"deleted"
    }
  },
  StoreList:[],
  scenarioList:[]
};