import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Logger } from './../Logger/logger.module';
import { MyPercentPipe } from './percent-formatter.pipe';

/**
 * Directive for formatting data with percentage.
 */
@Directive({ selector: '[myPercentFormatter]', providers: [NgModel] })
export class MyPercentFormatterDirective implements OnInit, OnDestroy {

    private el: HTMLInputElement;

    @Input() countAfterDecimal: number = 1;
    @Input() max: number = null;
    @Input() calculated = false;
    @Input() isNegative = false;

    @Output() onValueChange = new EventEmitter();
    @Input() value: number = null;

    private defaultValue: string;
    private isBlur: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private percentPipe: MyPercentPipe,
        public render: Renderer2,
        private logger: Logger
    ) {
        this.el = this.elementRef.nativeElement;
        this.defaultValue = "";
    }
    ngOnInit() {
        try {
            if ([null, undefined].indexOf(this.value) < 0) {
                let val = this.calculated ? this.value.toString() === "" ? this.value : (this.value * 100) : this.value;
                this.onBlur(val.toString());
            }
        } catch (err) {
            this.logger.error("Error @ MyPercentFormatterDirective: ngOnInit() ->"+ err);
        }
    }
    ngOnChanges(changes) {
        try {
            if(changes["value"]) {
                if(changes["value"].currentValue == null || changes["value"].currentValue == undefined || changes["value"].currentValue === ''){
                    this.value = changes["value"].currentValue || '' ;
                }else {
                    this.value = changes["value"].currentValue;
                }
                !changes["value"].firstChange && this.isBlur && this.ngOnInit();
            }            
        } catch (error) {
            this.logger.error("Error @ MyPercentFormatterDirective: ngOnChanges() ->"+ error);
        }        
    }

    ngOnDestroy() {
       try {
        this.onFocus = null;
        this.onBlur = null;
        this.onInput = null;
        this.el = null;
        this.countAfterDecimal = null;
        this.max = null;
        this.defaultValue = null;
       } catch (error) {
        this.logger.error("Error @ MyPercentFormatterDirective: ngOnDestroy() ->"+ error);
       }       
    }

    /**
     * Host listener to convert percentage string to number on focus.
     *      
     *  @param value     
     */
    @HostListener('focus', ['$event.target.value'])
    onFocus(value) {
        try {
            this.isBlur = false;
            if (value === null || value === undefined || value.trim() === "") {
                value = this.defaultValue;
            }
            let updatedValue = this.percentPipe.parse(value, this.countAfterDecimal); // opposite of transform
            this.render.setProperty(this.el, "value", updatedValue);

            // let emitValue = this.el.value && this.calculated ? parseFloat(this.el.value) / 100 : this.el.value;
            // this.onValueChange.emit(this.el.value);
        } catch (err) {
            this.logger.error("Error @ MyPercentFormatterDirective: onFocus() ->"+ err);
        }
    }

    /**
     * Hostlistener to convert number into percentage formatted string on blur.
     * 
     * @param value 
     */
    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        try {
            this.isBlur = true;
            let updatedValue:any;            
            if (value === null || value === undefined || value.trim() === "") {
                updatedValue = "";
            } else if (value == "0" || value == "0.0") { updatedValue = "0%" }
            else if (this.isNegative && ( value == "-0" || value == "-0.0")) { updatedValue = "0%" }
            else {
               // updatedValue = value.replace(/^0+/, '');
                updatedValue = this.percentPipe.transform(value, this.countAfterDecimal);
            }
            this.render.setProperty(this.el, "value", updatedValue);
            // let emitValue = value && this.calculated ? parseFloat(value) / 100 : value;
            // this.ngModelChange.emit(emitValue);
        } catch (err) {
            this.logger.error("Error @ MyPercentFormatterDirective: onBlur() ->"+ err);
        }
    }

    /**
     * Validation to validate that a string represents correct decimal value.
     * 
     * @param val 
     */
    @HostListener('input', ['$event.target.value'])
    @HostListener('change', ['$event.target.value'])
    onInput(val) {
        try {
            if (val !== null && val !== undefined && val.trim() !== "") {

                let value = val.replace(/[^0-9.]/gi, '');
                let results = this.isNegative && val.startsWith('-') ? '-' : '';
                
                for (let i = 0; i < value.length; i++) {
                    if (value.charAt(i) === '.' && results.indexOf('.') >= 0) {
                        results += '';
                    }
                    else {
                        results += value.charAt(i);
                    }
                }
                let temp = results;

                if (temp && this.max) {
                    if (parseFloat(temp) > this.max) {
                        let dotIndex = temp.indexOf('.');
                        temp = (dotIndex > -1) ? temp.substr(0, dotIndex - 1) : temp.substr(0, temp.length - 1);
                    }
                }
                if (temp && this.countAfterDecimal && (temp.charAt(temp.length - 1) != '.')) {
                    let tempNum = Number(temp);
                    let tempStr = temp;
                    if (!isNaN(tempNum)) {
                        let dotIndex = temp.indexOf('.');
                        tempStr = dotIndex != -1 ? temp.substr(0, (dotIndex + 1) + parseInt(this.countAfterDecimal + "")) : temp;
                    }
                    val = tempStr;
                }
                else {
                    val = temp;
                }
            }
            else {
                val = "";
            }
            this.render.setProperty(this.el, "value", val);

            let emitValue = this.calculated ? parseFloat(this.el.value) / 100 : this.el.value;
            this.onValueChange.emit(emitValue);
        } catch (err) {
            this.logger.error("Error @ MyPercentFormatterDirective: onInput() ->"+ err);
        }
    }

}
